<template>
  <v-dialog
    v-model="show_"
    content-class="role-form-dialog"
    max-width="500px"
    persistent
  >
    <v-card class="dialog-card" outlined>
      <v-toolbar>
        <span class="text-20">{{ dialogTitle }}</span>
      </v-toolbar>
      <v-card-text class="card-text pa-4">
        <role-form
          ref="roleForm"
          :role.sync="role_"
          @stored="handleStored"
          @updated="handleUpdated"
        />
      </v-card-text>
      <v-toolbar>
        <v-spacer />
        <div class="d-flex" style="gap: 0.5rem">
          <v-btn small depressed color="secondary" @click="show_ = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small depressed color="success" @click="save">
            <v-icon class="mr-1" small>mdi-floppy</v-icon>
            {{ $t("save") }}
          </v-btn>
        </div>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import RoleForm from "./RoleForm.vue";

export default {
  name: "RoleFormDialog",
  components: { RoleForm },
  props: {
    role: {
      type: Object,
      default: () => ({
        name: "",
        description: "",
        permissions: [],
      }),
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    role_: {
      get() {
        return this.role;
      },
      set(value) {
        this.$emit("update:role", value);
      },
    },
    dialogTitle() {
      return this.role.id ? this.$t("edit_role") : this.$t("add_role");
    },
    show_: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show(newValue) {
      !newValue && this.$refs.roleForm.reset();
    },
  },
  methods: {
    handleStored(data) {
      this.$emit("stored", data);
      this.show_ = false;
    },
    handleUpdated(data) {
      this.$emit("updated", data);
      this.show_ = false;
    },
    save() {
      this.$refs.roleForm.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .role-form-dialog {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .dialog-card {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
      .card-text {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
        padding-top: 20px;
      }
    }
  }
}
</style>
