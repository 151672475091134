<template>
  <base-data-table
    ref="queriesTable"
    :headers="queriesTable.headers"
    :items="queriesTable.items"
    :fixed-header="true"
    class="d-flex flex-column queries-table"
    style="max-height: 100%"
    :loading="queriesTable.loading"
    @loadData="handleLoadData"
  >
    <template v-slot:toolbar-left>
      <v-toolbar-title
        class="d-flex flex-nowrap align-items-center"
        style="gap: 0.5rem; min-width: min-content"
      >
        <v-icon>mdi-magnify-expand</v-icon>
        <span>{{ $t("person_queries") }}</span>
      </v-toolbar-title>
      <v-divider class="mx-3" vertical />
      <v-switch
        v-model="showOnlyWanted"
        :label="$t('show_only_wanted')"
        :disabled="queriesTable.loading"
        class="text-no-wrap"
        hide-details
        dense
        inset
      />
    </template>
    <template v-slot:toolbar-right>
      <v-btn
        :color="isDarkMode ? 'grey lighten-5' : 'info'"
        depressed
        outlined
        small
        :disabled="!$auth.check('sahis-sorgulayabilir')"
        @click="showFormDialog"
      >
        <v-icon small class="mr-1">mdi-magnify-expand</v-icon>
        {{ $t("person_query") }}
      </v-btn>
      <query-form-dialog
        v-if="$auth.check('sahis-sorgulayabilir')"
        :show.sync="formDialog"
        @submitted="handleSubmitted"
      />
      <history-detail-dialog
        v-if="$auth.check('sahislari-gorebilir')"
        :history="detailDialogHistory"
        :show.sync="detailDialog"
      />
    </template>

    <template v-slot:[`item.source`]="{ item }">
      <div class="d-flex align-center">
        <base-image
          :size="56"
          :src="item?.photo?.thumbnail ?? item?.photo?.photo"
          img-class="mx-auto rounded"
          max-height="100%"
          max-width="100%"
        />
      </div>
    </template>
    <template v-slot:[`item.match`]="{ item }">
      <div class="d-inline-flex align-center">
        <div>
          <base-image
            :size="56"
            rounded
            :src="item?.person?.photo?.thumbnail ?? item?.person?.photo?.photo"
            img-class="mx-auto rounded"
            max-height="100%"
            max-width="100%"
          />
        </div>
        <div class="ml-2">
          <div class="d-flex display: block">
            <p
              class="mb-0 text-title text-capitalize text-truncate"
              style="font-weight: 500"
            >
              {{ item?.photo?.similarity ? item?.person?.full_name : "-" }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:[`item.similarity`]="{ item }">
      <vue-ellipse-progress
        :progress="Math.round(item?.photo?.similarity)"
        color="#7579ff"
        empty-color="#324c7e"
        :size="46"
        :thickness="6"
        :noData="!item?.photo?.similarity"
        :emptyThickness="6"
        lineMode="normal 0"
        animation="default 1200 100"
        fontSize="0.9rem"
        font-color="gray"
        :dot="{ size: 3, width: '3px' }"
      />
    </template>

    <template v-slot:[`item.query_status`]="{ item }">
      <v-alert
        v-if="item.status === 'in_progress'"
        class="mb-0 pa-2 text-14"
        dense
        text
        :icon="false"
        style="max-width: 100%; width: fit-content"
        type="primary"
      >
        <template v-slot:prepend>
          <v-progress-circular
            indeterminate
            color="primary"
            size="18"
            width="3"
            class="align-self-start ml-1 mr-4"
            style="min-height: 24px; min-width: 24px"
          />
        </template>
        <span>{{ $t("query_in_progress") }}</span>
      </v-alert>
      <div v-else :class="{ 'error--text': item.status === 'failed' }">
        <v-badge :color="queryStatusColor(item)" dot inline left>
          {{ $t(`query_${item.status}`) }}
        </v-badge>
      </div>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <div
        v-if="item.status !== 'in_progress'"
        :class="{ 'error--text': item.status === 'failed' }"
      >
        {{ queryStatusText(item) }}
        <br />
        <a
          v-if="item.photo?.similarity !== null"
          :class="{ 'v-btn--disabled': !$auth.check('sahislari-gorebilir') }"
          href="javascript:void(0)"
          @click="showDetailDialog(item)"
        >
          ({{ $t("show_person_details") }})
        </a>
      </div>
    </template>
    <template v-slot:[`header.user.name`]>
      <span>{{ $t("query_time") }} /</span><br />
      <span>{{ $t("query_completion_time") }}</span>
    </template>
    <template v-slot:[`item.user`]="{ item }">
      <div
        class="d-flex flex-column"
        style="max-width: 100%; width: fit-content"
      >
        <v-alert
          class="alerts mb-0 px-0 py-1 text-12"
          dense
          icon="mdi-account"
          text
          style="border-radius: 4px 4px 0 0; max-width: 100%"
        >
          {{ item.user?.name }}
        </v-alert>
      </div>
    </template>
    <template v-slot:[`item.user.name`]="{ item }">
      <div
        class="d-flex flex-column"
        style="max-width: 100%; width: fit-content"
      >
        <v-alert
          class="alerts mb-0 px-0 py-1 text-12"
          dense
          icon="mdi-clock-plus-outline"
          text
          style="max-width: 100%"
        >
          {{ $d(new Date(item.created_at * 1000), "full2", "tr") }}
        </v-alert>
        <v-alert
          v-if="item.status === 'success' || item.status === 'failed'"
          class="alerts mb-0 px-0 py-1 text-12"
          dense
          icon="mdi-clock-check-outline"
          text
          style="max-width: 100%"
        >
          {{ $d(new Date(item.updated_at * 1000), "full2", "tr") }}
        </v-alert>
      </div>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ $d(new Date(item.created_at * 1000), "full2", "tr") }}
    </template>
    <template v-slot:[`item.updated_at`]="{ item }">
      <template v-if="item.status === 'success' || item.status === 'failed'">
        {{ $d(new Date(item.updated_at * 1000), "full2", "tr") }}
      </template>
    </template>
  </base-data-table>
</template>

<script>
import Vue from "vue";
import { index } from "@/api/queries.api";
import { mapGetters } from "vuex";

import BaseDataTable from "@/components/base/DataTable/DataTable.vue";
import HistoryDetailDialog from "@/views/pages/histories/HistoryDetailDialog.vue";
import QueryFormDialog from "./QueryFormDialog.vue";
import BaseImage from "@/components/base/Image";
import { VueEllipseProgress } from "vue-ellipse-progress";

const { i18n } = Vue;

export default {
  name: "QueriesPage",
  components: {
    BaseImage,
    BaseDataTable,
    HistoryDetailDialog,
    QueryFormDialog,
    VueEllipseProgress,
  },

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: i18n.t("person_queries"),
  },

  data: () => ({
    queriesTable: {
      headers: [
        {
          text: i18n.t("source"),
          value: "source",
          align: "center",
          sortable: false,
          filterable: false,
          width: "6%",
        },
        {
          text: i18n.t("similarity"),
          value: "similarity",
          align: "center",
          sortable: false,
          filterable: false,
          width: "7%",
        },
        {
          text: i18n.t("matching"),
          value: "match",
          sortable: false,
          filterable: false,
          width: "14%",
        },
        {
          text: i18n.t("query_status"),
          value: "query_status",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("query_status"),
          value: "status",
          sortable: false,
          filterable: false,
          align: "start",
        },
        {
          text: i18n.t("queried_by"),
          value: "user",
          sortable: false,
          filterable: false,
          align: "start",
        },
        {
          text: i18n.t("queried_by"),
          value: "user.name",
          sortable: false,
          filterable: false,
          align: "start",
        },
      ],
      items: [],
      loading: false,
    },
    detailDialog: false,
    detailDialogHistory: {},
    formDialog: false,
    inProgressTimeout: null,
    showOnlyWanted: false,
  }),

  computed: {
    ...mapGetters(["isDarkMode"]),
  },

  watch: {
    showOnlyWanted() {
      this.$refs.queriesTable.fireLoadData({
        ...this.$refs.queriesTable.custom.options,
        page: 1,
      });
    },
  },

  methods: {
    clearInProgressInterval() {
      if (this.inProgressTimeout) {
        clearInterval(this.inProgressTimeout);
        this.inProgressTimeout = null;
      }
    },

    handleLoadData(filter, dataLoaded, dataLoadFailed) {
      this.clearInProgressInterval();
      this.setExtraFilters(filter);
      this.queriesTable.loading = true;
      index(filter)
        .then(
          ({ data, meta }) => {
            if (data && meta) {
              this.queriesTable.items.splice(
                0,
                this.queriesTable.items.length,
                ...data
              );
              dataLoaded(meta);
              if (this.hasAnyQueryInProgress()) {
                this.inProgressTimeout = setTimeout(() => {
                  this.handleLoadData(filter, dataLoaded, dataLoadFailed);
                }, 5000);
              }
            }
          },
          () => dataLoadFailed()
        )
        .finally(() => {
          this.queriesTable.loading = false;
        });
    },

    handleSubmitted() {
      const { custom, fireLoadData } = this.$refs.queriesTable;
      if (custom.options.page === 1) {
        fireLoadData(custom.options);
      } else {
        custom.options.page = 1;
      }
    },

    hasAnyQueryInProgress() {
      return this.queriesTable.items.find(
        (query) => query.status === "in_progress"
      );
    },

    queryStatusColor(query) {
      if (query?.status === "in_progress") {
        return "primary";
      } else if (query?.status === "success") {
        return "success";
      } else if (query?.status === "failed") {
        return "danger";
      }
      return "info";
    },

    queryStatusText(query) {
      let statusText = "";
      if (query.status === "success") {
        if (query?.photo?.similarity === null) {
          statusText = `${i18n.t("no_match_found")}.`;
        } else if (query.history_count > 0) {
          statusText = `${i18n.t("person_has_x_transition_records", {
            x: query.history_count,
          })}`;
        } else {
          statusText = `${i18n.t("no_transition_record_this_person")}`;
        }
      } else if (query.status === "failed") {
        statusText = `${query?.error?.detail ?? query?.error?.message}`;
      }
      return statusText;
    },

    showDetailDialog(history) {
      this.detailDialog = true;
      this.$nextTick(() => {
        this.detailDialogHistory = Object.assign({}, history);
      });
    },

    setExtraFilters(options) {
      if (this.showOnlyWanted) {
        Object.assign(options, {
          ...options,
          is_wanted: this.showOnlyWanted,
        });
      }
    },

    showFormDialog() {
      this.formDialog = true;
    },
  },

  beforeDestroy() {
    this.clearInProgressInterval();
  },
};
</script>

<style lang="scss" scoped>
.queries-table ::v-deep {
  td {
    vertical-align: middle !important;
  }
  .alerts:before {
    background-color: transparent;
  }
  .v-badge--dot .v-badge__badge {
    border-radius: 6px;
    height: 12px;
    width: 12px;
  }
  .v-badge--inline .v-badge__wrapper {
    margin: 0 8px 0 0;
  }
}
</style>
