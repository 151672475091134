<template>
  <base-data-table
    ref="historiesTable"
    :headers="historiesTable.headers"
    :items="historiesTable.items"
    :fixed-header="true"
    class="d-flex flex-column histories-table"
    style="max-height: 100%"
    :loading="historiesTable.loading"
    @loadData="handleLoadData"
  >
    <template v-slot:toolbar-left>
      <v-toolbar-title
        class="d-flex flex-nowrap align-items-center"
        style="gap: 0.5rem; min-width: min-content"
      >
        <v-icon>mdi-target-account</v-icon>
        <span>{{ $t("histories") }}</span>
      </v-toolbar-title>
      <v-divider class="mx-4" vertical />
      <akn-select
        v-model="checkpoint"
        :items="checkpoints"
        :label="$t('checkpoint')"
        :disabled="checkpointLoading || deviceLoading || historiesTable.loading"
        item-text="name"
        item-value="id"
        style="max-width: 10rem"
        hide-details
      />
      <akn-select
        v-show="checkpoint"
        v-model="device"
        :items="devices"
        :label="$t('device')"
        :disabled="checkpointLoading || deviceLoading || historiesTable.loading"
        item-text="name"
        item-value="id"
        class="ml-4"
        style="max-width: 10rem"
        hide-details
      />
      <v-switch
        v-model="showOnlyWanted"
        :label="$t('show_only_wanted')"
        :disabled="checkpointLoading || deviceLoading || historiesTable.loading"
        class="ml-4 text-no-wrap"
        hide-details
        dense
        inset
      />
    </template>
    <template v-slot:toolbar-right>
      <history-detail-dialog
        v-if="$auth.check('sahislari-gorebilir')"
        :history="detailDialogHistory"
        :show.sync="detailDialog"
      />
    </template>
    <template v-slot:[`item.is_alarm`]="{ item }">
      <template v-if="item?.similarity !== null">
        <div v-if="item.is_alarm">
          <v-chip
            small
            label
            outlined
            color="warning"
            dense
            class="mb-0 px-3 py-2 text-12"
          >
            <v-tooltip top :disabled="!item.person?.cause_of_wanted">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small left v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <div>
                <strong>{{ $t("cause_of_wanted") }}:</strong>
                <br />
                <span class="text-capitalize">
                  {{ item.person?.cause_of_wanted }}
                </span>
              </div>
            </v-tooltip>
            {{ $t("wanted") }}
          </v-chip>
        </div>
        <div v-else>
          <v-chip
            small
            label
            outlined
            color="primary"
            dense
            class="mb-0 px-3 py-2 text-12"
          >
            {{ $t("not_wanted") }}
          </v-chip>
        </div>
      </template>
    </template>
    <template v-slot:[`item.checkpoint`]="{ item }">
      <v-treeview
        dense
        item-key="icon"
        :items="[
          {
            icon: 'mdi-shield-account',
            name: item.checkpoint?.name,
            children: [{ icon: 'mdi-cctv', name: item.device?.name }],
          },
        ]"
      >
        <template v-slot:prepend="{ item }">
          <v-icon v-text="item.icon" />
        </template>
      </v-treeview>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ $d(new Date(item.created_at * 1000), "full2", "tr") }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn-toggle active-class="no-active">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0 single"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              :disabled="!$auth.check('sahislari-gorebilir')"
              @click="showDetailDialog(item)"
            >
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("show_person_details") }}</span>
        </v-tooltip>
      </v-btn-toggle>
    </template>

    <template v-slot:[`item.source`]="{ item }">
      <base-image
        :size="56"
        :src="item?.photo?.thumbnail ?? item?.photo?.photo"
        img-class="mx-auto rounded"
        max-height="100%"
        max-width="100%"
      />
    </template>

    <template v-slot:[`item.match`]="{ item }">
      <div class="d-inline-flex align-center">
        <div>
          <base-image
            :size="56"
            rounded
            :src="
              item.similarity
                ? item?.person?.photo?.thumbnail ?? item?.person?.photo?.photo
                : null
            "
            img-class="mx-auto rounded"
            max-height="100%"
            max-width="100%"
          />
        </div>
        <div class="ml-2">
          <div class="d-flex display: block">
            <p
              class="mb-0 text-title text-capitalize text-truncate"
              style="font-weight: 500"
            >
              {{ item.similarity ? item?.person?.full_name : "-" }}
            </p>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:[`item.similarity`]="{ item }">
      <vue-ellipse-progress
        :progress="Math.round(item.similarity)"
        color="#7579ff"
        empty-color="#324c7e"
        :size="46"
        :thickness="6"
        :noData="!item.similarity"
        :emptyThickness="6"
        lineMode="normal 0"
        animation="default 1200 100"
        fontSize="0.9rem"
        font-color="gray"
        :dot="{ size: 3, width: '3px' }"
      />
    </template>
  </base-data-table>
</template>

<script>
import Vue from "vue";
import { index as checkpointsIndex } from "@/api/checkpoints.api";
import { index as devicesIndex } from "@/api/devices.api";
import { index as historiesIndex } from "@/api/histories.api";
import BaseImage from "@/components/base/Image";
import { VueEllipseProgress } from "vue-ellipse-progress";

const { i18n } = Vue;

export default {
  name: "HistoriesPage",
  components: {
    BaseImage,
    AknSelect: () => import("@/components/AknSelect.vue"),
    BaseDataTable: () => import("@/components/base/DataTable/DataTable.vue"),
    HistoryDetailDialog: () => import("./HistoryDetailDialog.vue"),
    VueEllipseProgress,
  },

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: i18n.t("histories"),
  },

  data: () => ({
    historiesTable: {
      headers: [
        {
          text: i18n.t("source"),
          value: "source",
          align: "center",
          sortable: false,
          filterable: false,
          width: "6%",
        },
        {
          text: i18n.t("similarity"),
          value: "similarity",
          align: "center",
          sortable: false,
          filterable: false,
          width: "7%",
        },
        {
          text: i18n.t("matching"),
          value: "match",
          align: "start",
          sortable: false,
          filterable: false,
          width: "6%",
        },
        {
          text: i18n.t("wanted_status"),
          value: "is_alarm",
          sortable: false,
          align: "center",
          filterable: false,
          width: "16%",
        },
        {
          text: i18n.t("where_was_seen"),
          value: "checkpoint",
          align: "center",
          sortable: false,
          filterable: false,
          width: "3%",
        },
        {
          text: i18n.t("seen_at"),
          value: "created_at",
          align: "center",
          sortable: false,
          filterable: false,
        },
        {
          text: "#",
          value: "actions",
          align: "center",
          sortable: false,
          filterable: false,
        },
      ],
      items: [],
      loading: false,
    },
    allOption: {
      id: "",
      name: i18n.t("all"),
    },
    checkpoint: "",
    checkpointLoading: false,
    checkpoints: [],
    device: "",
    deviceLoading: false,
    devices: [],
    detailDialog: false,
    detailDialogHistory: {},
    showOnlyWanted: false,
  }),

  watch: {
    checkpoint: {
      handler(newValue) {
        if (newValue) {
          this.deviceLoading = true;
          this.fetchDevices();
        } else {
          this.device = "";
        }
        const { custom, fireLoadData } = this.$refs.historiesTable;
        if (custom.options.page === 1) {
          fireLoadData(custom.options);
        } else {
          custom.options.page = 1;
        }
      },
      deep: true,
    },

    device: {
      handler() {
        const { custom, fireLoadData } = this.$refs.historiesTable;
        if (custom.options.page === 1) {
          fireLoadData(custom.options);
        } else {
          custom.options.page = 1;
        }
      },
      deep: true,
    },

    showOnlyWanted() {
      const { custom, fireLoadData } = this.$refs.historiesTable;
      if (custom.options.page === 1) {
        fireLoadData(custom.options);
      } else {
        custom.options.page = 1;
      }
    },

    "$route.query.show_only_wanted": function (newValue) {
      if (newValue) {
        this.showOnlyWanted = newValue;
      }
    },
  },

  methods: {
    fetchCheckpoints() {
      checkpointsIndex({ perPage: -1 })
        .then(
          ({ data }) => {
            const allOption = Object.assign({}, this.allOption);
            data = [allOption, ...data];
            this.checkpoints.splice(0, this.checkpoints.length, ...data);
            this.checkpoint = "";
          },
          () => {
            this.$toast.error(
              this.$t("error_while_retrieving_checkpoints_list")
            );
          }
        )
        .finally(() => {
          this.checkpointLoading = false;
        });
    },

    fetchDevices() {
      const checkpoint = this.checkpoint === "" ? undefined : this.checkpoint;
      devicesIndex({ checkpoint, perPage: -1 })
        .then(
          ({ data }) => {
            const allOption = Object.assign({}, this.allOption);
            data = [allOption, ...data];
            this.devices.splice(0, this.devices.length, ...data);
            this.device = "";
          },
          () => {
            this.$toast.error(this.$t("error_while_retrieving_devices_list"));
          }
        )
        .finally(() => {
          this.deviceLoading = false;
        });
    },

    handleLoadData(filter, dataLoaded, dataLoadFailed) {
      filter.checkpoint = this.checkpoint === "" ? undefined : this.checkpoint;
      filter.device = this.device === "" ? undefined : this.device;
      filter.is_alarm = this.showOnlyWanted || undefined;
      this.historiesTable.loading = true;
      historiesIndex(filter)
        .then(
          ({ data, meta }) => {
            if (data && meta) {
              this.historiesTable.items.splice(
                0,
                this.historiesTable.items.length,
                ...data
              );
              dataLoaded(meta);
            }
          },
          () => dataLoadFailed()
        )
        .finally(() => {
          this.historiesTable.loading = false;
        });
    },

    parseFromRoute() {
      this.showOnlyWanted = !!this.$route.query.show_only_wanted;
    },

    showDetailDialog(history) {
      this.detailDialog = true;
      this.$nextTick(() => {
        this.detailDialogHistory = Object.assign({}, history);
      });
    },
  },
  beforeMount() {
    this.parseFromRoute();
    this.fetchCheckpoints();
  },
};
</script>

<style scoped lang="scss">
.histories-table {
  td {
    vertical-align: middle !important;
  }
}
</style>
