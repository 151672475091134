<template>
  <v-form ref="form" v-model="form.valid" lazy-validation>
    <v-progress-linear
      :active="saving_"
      :indeterminate="saving_"
      absolute
      top
      color="primary"
    />
    <akn-text-field
      v-model="user_.name"
      :label="$t('userPage.name')"
      :rules="form.name.rules"
      :error-messages="form.name.errors"
    />
    <akn-text-field
      v-model="user_.username"
      :label="$t('userPage.username')"
      :rules="form.username.rules"
      :error-messages="form.username.errors"
      disabled
    />
    <akn-text-field
      v-model="user_.current_password"
      :append-icon="form.current_password.show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="form.current_password.show ? 'text' : 'password'"
      :label="$t('current_password')"
      :rules="currentPasswordRules"
      :error-messages="currentPasswordErrors"
      autocomplete="new-password"
      @click:append="form.current_password.show = !form.current_password.show"
    />
    <akn-text-field
      v-model="user_.password"
      :append-icon="form.password.show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="form.password.show ? 'text' : 'password'"
      :label="$t('password')"
      :rules="passwordRules"
      :error-messages="passwordErrors"
      autocomplete="new-password"
      @click:append="form.password.show = !form.password.show"
    />
    <akn-text-field
      v-model="user_.password_confirmation"
      :append-icon="form.password_confirmation.show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="form.password_confirmation.show ? 'text' : 'password'"
      :label="$t('password_confirmation')"
      :rules="passwordConfirmationRules"
      :error-messages="passwordConfirmationErrors"
      autocomplete="new-password"
      @click:append="
        form.password_confirmation.show = !form.password_confirmation.show
      "
    />
  </v-form>
</template>

<script>
import i18n from "@/lang";
import { me } from "@/api/users.api";
import { mapGetters, mapMutations } from "vuex";

import AknTextField from "@/components/AknTextField.vue";

export default {
  name: "UserProfileForm",
  components: { AknTextField },
  props: {
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 3) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("userPage.name"),
                    y: 3,
                  });
                } else if (v.length > 255) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("userPage.name"),
                    y: 255,
                  });
                }
                return true;
              }
              return i18n.t("x_is_required", { x: i18n.t("userPage.name") });
            },
          ],
        },
        username: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 5) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("userPage.username"),
                    y: 5,
                  });
                } else if (v.length > 100) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("userPage.username"),
                    y: 100,
                  });
                }
                return true;
              }
              return i18n.t("x_is_required", {
                x: i18n.t("userPage.username"),
              });
            },
          ],
        },
        current_password: {
          errors: [],
          show: false,
        },
        password: {
          errors: [],
          show: false,
        },
        password_confirmation: {
          show: false,
        },
        valid: false,
      },
      user_: {},
    };
  },
  computed: {
    ...mapGetters(["user"]),
    currentPasswordErrors() {
      if (this.form.current_password.errors.length) {
        return this.form.current_password.errors;
      }
      if (this.currentPasswordRequired) {
        return [i18n.t("x_is_required", { x: i18n.t("current_password") })];
      }
      return [];
    },
    currentPasswordRequired() {
      return (
        !this.user_.current_password &&
        (this.passwordValidated === true ||
          this.passwordConfirmationValidated === true)
      );
    },
    currentPasswordRules() {
      const rules = [];

      if (this.user_.current_password) {
        if (this.currentPasswordValidated !== true) {
          rules.push(() => this.currentPasswordValidated);
        }
      } else if (this.currentPasswordRequired) {
        rules.push(() =>
          i18n.t("x_is_required", { x: i18n.t("current_password") })
        );
      }

      return rules;
    },
    currentPasswordValidated() {
      return this.validatePassword(
        this.user_.current_password || "",
        i18n.t("current_password")
      );
    },
    passwordErrors() {
      if (this.form.password.errors.length) {
        return this.form.password.errors;
      }
      if (this.passwordRequired) {
        return [i18n.t("x_is_required", { x: i18n.t("password") })];
      }
      return [];
    },
    passwordRequired() {
      return (
        !this.user_.password &&
        (this.currentPasswordValidated === true ||
          this.passwordConfirmationValidated === true)
      );
    },
    passwordRules() {
      const rules = [];

      if (this.user_.password) {
        if (this.passwordValidated !== true) {
          rules.push(() => this.passwordValidated);
        } else if (
          this.passwordConfirmationValidated === true &&
          !this.passwordsMatched
        ) {
          rules.push(() =>
            i18n.t("does_not_match_x", {
              x: i18n.t("password_confirmation"),
            })
          );
        }
      } else if (this.passwordConfirmationValidated === true) {
        rules.push(() => i18n.t("x_is_required", { x: i18n.t("password") }));
      }

      return rules;
    },
    passwordValidated() {
      return this.validatePassword(
        this.user_.password || "",
        i18n.t("password")
      );
    },
    passwordConfirmationErrors() {
      if (this.passwordConfirmationRequired) {
        return [
          i18n.t("x_is_required", { x: i18n.t("password_confirmation") }),
        ];
      }
      return [];
    },
    passwordConfirmationRequired() {
      return (
        !this.user_.password_confirmation &&
        (this.currentPasswordValidated === true ||
          this.passwordValidated === true)
      );
    },
    passwordConfirmationRules() {
      const rules = [];

      if (this.user_.password_confirmation) {
        if (this.passwordConfirmationValidated !== true) {
          rules.push(() => this.passwordConfirmationValidated);
        } else if (this.passwordValidated === true && !this.passwordsMatched) {
          rules.push(() =>
            i18n.t("does_not_match_x", {
              x: i18n.t("password"),
            })
          );
        }
      } else if (this.passwordValidated === true) {
        rules.push(() =>
          i18n.t("x_is_required", { x: i18n.t("password_confirmation") })
        );
      }

      return rules;
    },
    passwordConfirmationValidated() {
      return this.validatePassword(
        this.user_.password_confirmation || "",
        i18n.t("password_confirmation")
      );
    },
    passwordsMatched() {
      return this.user_.password === this.user_.password_confirmation;
    },
    saving_: {
      get() {
        return this.saving;
      },
      set(value) {
        this.$emit("update:saving", value);
      },
    },
  },
  watch: {
    "user_.name": function () {
      this.form.name.errors = [];
    },
    "user_.username": function () {
      this.form.username.errors = [];
    },
    "user_.current_password": function () {
      this.form.current_password.errors = [];
    },
    "user_.password": function () {
      this.form.password.errors = [];
    },
  },
  methods: {
    ...mapMutations(["setUser"]),
    reset() {
      this.$refs.form?.resetValidation();
      const { name, username } = this.user;
      this.user_ = Object.assign({}, { name, username });
    },
    async submit() {
      await this.$refs.form.validate();
      if (this.form.valid) {
        this.saving_ = true;
        me(this.user_)
          .then(
            ({ data }) => {
              this.$emit("saved", data);
              this.$toast.success(this.$t("profile_successfully_updated"));
              if (this.user_.password) {
                this.$auth.logout();
              } else {
                this.reset();
                this.setUser(data);
              }
            },
            (error) => {
              const { data, status } = error.response ?? {};
              if (status === 401) {
                this.$toast.error(data?.detail);
              } else if (status === 422) {
                for (const [key, value] of Object.entries(data?.errors ?? [])) {
                  if (this.form[key]) {
                    this.form[key].errors = value;
                  }
                }
                if (data.message) {
                  this.$toast.error(data.message);
                }
              }
            }
          )
          .finally(() => {
            this.saving_ = false;
          });
      }
    },
    validatePassword(password, label) {
      if (password.length < 8) {
        return i18n.t("x_must_be_least_y_characters", {
          x: label,
          y: 8,
        });
      } else if (password.length > 100) {
        return i18n.t("x_must_be_maximum_y_characters", {
          x: label,
          y: 100,
        });
      }
      return true;
    },
  },
  beforeMount() {
    this.reset();
  },
};
</script>
