<template>
  <v-dialog
    v-model="show_"
    content-class="user-profile-form-dialog"
    max-width="500px"
    persistent
  >
    <v-card class="dialog-card" outlined>
      <v-toolbar>
        <span class="text-20">{{ $t("profile_information") }}</span>
      </v-toolbar>
      <v-card-text class="card-text pa-4">
        <user-profile-form
          ref="userProfileForm"
          :saving.sync="saving"
          @saved="handleSaved"
        />
      </v-card-text>
      <v-toolbar>
        <v-spacer />
        <div class="d-flex" style="gap: 0.5rem">
          <v-btn small depressed color="secondary" @click="show_ = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small depressed color="success" @click="save">
            <v-icon class="mr-1" small>mdi-floppy</v-icon>
            {{ $t("save") }}
          </v-btn>
        </div>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import UserProfileForm from "./UserProfileForm.vue";

export default {
  name: "UserProfileFormDialog",
  components: { UserProfileForm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saving: false,
    };
  },
  computed: {
    show_: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.$refs.userProfileForm?.reset();
      }
    },
  },
  methods: {
    handleSaved(data) {
      this.$emit("saved", data);
      this.show_ = false;
    },
    save() {
      this.$refs.userProfileForm.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .user-profile-form-dialog {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .dialog-card {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
      .card-text {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
        padding-top: 20px;
      }
    }
  }
}
</style>
