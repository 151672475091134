<template>
  <v-dialog v-model="show_" max-width="34rem" persistent>
    <v-card outlined>
      <v-card-text class="d-flex flex-column pa-4">
        <v-alert
          class="mb-0 text-14"
          dense
          icon="mdi-help-circle-outline"
          type="error"
        >
          <span
            v-html="
              $t('are_you_sure_you_want_to_delete_this_device_x', {
                x: `${device?.name} | ${device?.description}`,
              })
            "
          />
        </v-alert>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn small depressed color="secondary" @click="show_ = false">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn small depressed color="error" @click="deleteConfirm">
          <v-icon class="mr-1" small>mdi-delete</v-icon>
          {{ $t("yes") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { destroy } from "@/api/devices.api";

export default {
  name: "DeviceDeleteDialog",
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show_: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    deleteConfirm() {
      destroy(this.device.id).then(
        () => {
          this.$toast.success(this.$t("device_successfully_deleted"));
          this.$emit("deleted", this.device);
          this.show_ = false;
        },
        () => this.$toast.error(this.$t("error_while_deleting_device"))
      );
    },
  },
};
</script>
