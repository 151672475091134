import { axios } from "@/plugins/axios";

export async function index(query) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/web/checkpoints", { params: query })
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function store(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/web/checkpoints", payload)
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function update(id, payload) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/web/checkpoints/${id}`, payload)
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function destroy(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/web/checkpoints/${id}`)
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function showApiKey(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/web/checkpoints/${id}/api-key`)
      .then(
        ({ data: { data } }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function createApiKey(id) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/web/checkpoints/${id}/api-key`)
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}
