export default {
  state: {
    user: undefined,
  },
  getters: {
    user: (state) => state.user,
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
  },
  actions: {
    //
  },
};
