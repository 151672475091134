<template>
  <perfect-scrollbar @scroll.passive="handleScroll">
    <v-row class="scroll-content px-3 py-2" justify="center">
      <template v-if="histories.length > 0">
        <v-col
          sm="12"
          md="12"
          xl="6"
          lg="6"
          v-for="history in histories"
          :key="history.id"
        >
          <v-card class="card-shadow">
            <v-card-title class="pt-3 pl-3 d-flex">
              <small>
                {{
                  $d(new Date((history.created_at ?? 0) * 1000), "full2", "tr")
                }}</small
              >
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <router-link
                    class="v-btn v-btn--icon"
                    :class="{
                      'v-btn--disabled': !$auth.check('sahislari-gorebilir'),
                    }"
                    target="_blank"
                    :to="{
                      name: 'person-detail',
                      params: { id: history?.person?.id },
                    }"
                  >
                    <v-icon small v-bind="attrs" v-on="on">mdi-eye</v-icon>
                  </router-link>
                </template>
                <span>{{ $t("show_person_details") }}</span>
              </v-tooltip>
            </v-card-title>
            <hr class="horizontal rounded-hr mb-3" />
            <div>
              <person-photo-match
                :scanner="true"
                :height="80"
                :person-full-name="history?.person?.full_name"
                :similarity="history?.similarity"
                :source="history?.photo?.thumbnail ?? history?.photo?.photo"
                style="
                  height: fit-content;
                  max-height: calc(100% - 36px);
                  margin: auto;
                  width: fit-content;
                "
                :target="
                  history?.person?.photo?.thumbnail ??
                  history?.person?.photo?.photo
                "
              />
            </div>
            <hr class="horizontal rounded-hr mb-3" />
            <v-card-actions class="py-0">
              <v-chip outlined label small class="ma-2">
                <v-icon left small>mdi-cctv</v-icon>
                {{ history.device?.name }}
              </v-chip>

              <v-chip outlined label small class="ma-2">
                <v-icon left small>mdi-shield-account</v-icon>
                {{ history.checkpoint?.name }}
              </v-chip>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
      <v-col v-else-if="loading" cols="12">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col v-else>
        <v-alert
          border="left"
          colored-border
          max-width="340"
          class="mb-0 text-14"
          color="primary"
          dense
          icon="mdi-information-outline"
          type="info"
        >
          {{ $t("no_transition_record_this_person") }}
        </v-alert>
      </v-col>
    </v-row>
  </perfect-scrollbar>
</template>

<script>
import { index } from "@/api/histories.api";

import PersonPhotoMatch from "@/views/pages/people/PersonPhotoMatch.vue";

export default {
  name: "HistoryPersonTimeline",
  components: { PersonPhotoMatch },
  props: {
    personId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    histories: [],
    currentPage: 0,
    lastPage: 1,
    loading: false,
  }),
  watch: {
    personId() {
      this.currentPage = 0;
      this.lastPage = 1;
      this.histories = [];
      this.fetchPersonHistory();
    },
  },
  methods: {
    fetchPersonHistory() {
      if (!this.loading && this.personId && this.lastPage > this.currentPage) {
        this.loading = true;
        index({
          page: this.currentPage + 1,
          person: this.personId,
        })
          .then(
            ({ data, meta }) => {
              if (data && meta) {
                this.histories.push(...data);
                this.currentPage = meta?.current_page ?? 1;
                this.lastPage = meta?.last_page ?? 1;
              }
            },
            () => {
              //
            }
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleScroll(event) {
      setTimeout(() => {
        const { offsetHeight, scrollHeight, scrollTop } = event?.target;
        if (2 * offsetHeight >= scrollHeight - scrollTop) {
          this.fetchPersonHistory();
        }
      });
    },
  },
  beforeMount() {
    this.fetchPersonHistory();
  },
};
</script>

<style lang="scss" scoped>
.ps {
  max-height: 48em;
}
</style>
