import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context("./i18n", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

const dateTimeFormats = {
  tr: {
    day: {
      day: "weekday",
    },
    month: {
      year: "numeric",
      month: "long",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    full: {
      minute: "2-digit",
      hour: "2-digit",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour12: false,
    },
    full2: {
      second: "2-digit",
      minute: "2-digit",
      hour: "2-digit",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour12: false,
    },
  },
  en: {
    day: {
      day: "weekday",
    },
    month: {
      year: "numeric",
      month: "long",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    full: {
      minute: "2-digit",
      hour: "2-digit",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour12: false,
    },
    full2: {
      second: "2-digit",
      minute: "2-digit",
      hour: "2-digit",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour12: false,
    },
  },
};

const i18n = new VueI18n({
  locale: "tr",
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
  dateTimeFormats,
});

Vue.i18n = i18n;

export default i18n;
