import Vue from "vue";
import Vuex from "vuex";

import notification from "./modules/notification";
import theme from "./modules/theme";
import user from "./modules/user";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    notification,
    theme,
    user,
  },
});

Vue.store = store;

export default store;
