<template>
  <v-switch
    class="mt-0 v-input--dense v-text-field v-text-field--filled v-text-field--rounded"
    dense
    inset
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
  />
</template>

<script>
import { VSwitch } from "vuetify/lib/components";

export default {
  mixins: [VSwitch],
  name: "AknSwitch",
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--selection-controls__input {
    align-self: center;
  }
  .v-input__slot {
    cursor: pointer !important;
    padding: 0 16px !important;
  }
  .v-label {
    padding: 8px 0;
    top: 0 !important;
    white-space: normal;
  }
}
</style>
