<template>
  <v-dialog v-model="show_" max-width="500px" persistent>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
    <v-card outlined>
      <v-toolbar>
        <span class="text-20">{{ dialogTitle }}</span>
      </v-toolbar>
      <v-card-text class="d-flex flex-column pa-4">
        <device-form
          ref="deviceForm"
          :device.sync="device_"
          @stored="handleStored"
          @updated="handleUpdated"
        />
      </v-card-text>
      <v-toolbar>
        <v-spacer />
        <div class="d-flex" style="gap: 0.5rem">
          <v-btn small depressed color="secondary" @click="show_ = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small depressed color="success" @click="save">
            <v-icon class="mr-1" small>mdi-floppy</v-icon>
            {{ $t("save") }}
          </v-btn>
        </div>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import DeviceForm from "./DeviceForm.vue";

export default {
  name: "DeviceFormDialog",
  components: { DeviceForm },
  props: {
    device: {
      type: Object,
      default: () => ({
        name: "",
        description: "",
      }),
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    device_: {
      get() {
        return this.device;
      },
      set(value) {
        this.$emit("update:device", value);
      },
    },
    dialogTitle() {
      return this.device.id ? this.$t("edit_device") : this.$t("add_device");
    },
    show_: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show(newValue) {
      !newValue && this.$refs.deviceForm.reset();
    },
  },
  methods: {
    handleStored(data) {
      this.$emit("stored", data);
      this.show_ = false;
    },
    handleUpdated(data) {
      this.$emit("updated", data);
      this.show_ = false;
    },
    save() {
      this.$refs.deviceForm.submit();
    },
  },
};
</script>
