<template>
  <div class="file-wrapper" style="text-align: -webkit-center">
    <div class="file-container">
      <croppa
        @click="handleClick"
        :key="src"
        :width="size"
        :height="size"
        :initial-image="src"
        v-model="file"
        :accept="accept"
        :disabled="readonly"
        :placeholder="$t('add_photo')"
        :placeholder-color="$vuetify.theme.dark ? '#fff' : '#484848'"
        :placeholder-font-size="16"
        :prevent-white-space="true"
        canvas-color="transparent"
        :show-remove-button="false"
        @new-image-drawn="handleImageDrawn"
        @file-choose="handleFileChoose"
        @move="imageManipulated = true"
        @zoom="imageManipulated = true"
        :class="{ 'cursor-pointer': readonly }"
        :style="`height: ${size}px`"
      ></croppa>
      <div class="file-change" v-if="hasFile && !readonly">
        <v-btn x-small color="danger" fab dark @click="handleChange">
          <v-icon>mdi-cached</v-icon>
        </v-btn>
      </div>
    </div>
    <v-messages
      v-if="errorBucket.length > 0 || errorMessages.length > 0"
      :value="[...[submitted ? errorBucket : []], ...[errorMessages]]"
      role="alert"
      class="mt-4 error--text"
    />
  </div>
</template>

<script>
import VInput from "vuetify/lib/components/VInput/VInput.js";
import VMessages from "vuetify/lib/components/VMessages/VMessages.js";

export default {
  name: "PersonPhoto",
  extends: VInput,
  components: {
    VMessages,
  },
  props: {
    size: {
      type: Number,
      default: 200,
    },
    accept: {
      type: String,
      default: "image/jpg,image/jpeg,image/png",
    },
    errorMessages: {
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
  },
  data() {
    return {
      file: null,
      initial: {
        photo: null,
        data: null,
      },
      isValid: true,
      submitted: false,
      imageManipulated: false,
    };
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.initial = val;
      },
    },
  },
  computed: {
    hasFile() {
      return !!this.file?.img;
    },
  },
  methods: {
    handleChange() {
      this.file.chooseFile();
    },
    removeFile() {
      this.file.remove();
      this.initial.data = null;
      this.$emit("input", this.initial);
    },
    async handleFileChoose() {
      this.imageManipulated = true;
    },
    async handleImageDrawn() {
      if (!this.src) {
        await this.handleNewImage();
      } else {
        this.initial.photo = this.src;
        this.$emit("clearErrors");
        this.$emit("input", this.initial);
      }
    },
    async handleNewImage() {
      const blob = await this.file.promisedBlob("image/jpeg", 1);
      if (blob) {
        this.initial.data = new File(
          [blob],
          `person_photo_${+new Date()}.jpeg`,
          {
            type: "image/jpeg",
          }
        );
        this.$emit("clearErrors");
        this.$emit("input", this.initial);
      } else {
        this.removeFile();
      }
    },
    handleClick() {
      if (!this.readonly) return;
      this.$viewerApi({
        images: [this.src],
        options: {
          button: true,
          navbar: false,
          title: false,
          toolbar: false,
          tooltip: false,
          movable: true,
          zoomable: true,
          rotatable: false,
          scalable: false,
          transition: true,
          fullscreen: false,
          keyboard: true,
        },
      });
    },
  },
  created() {
    if (this.form) {
      const validate = this.form.validate;
      this.form.validate = async () => {
        const isValid = await validate();
        this.submitted = true;
        if (this.imageManipulated) {
          await this.handleNewImage();
        }
        return isValid;
      };
    }
  },
};
</script>

<style lang="scss">
.file-container {
  width: fit-content;
  height: 100%;
  position: relative;
  display: flex;
  place-content: center;

  .file-change {
    position: absolute;
    top: -12px;
    right: -12px;
  }
}

.theme--light {
  .croppa-container {
    background-color: #eeeeee;
  }

  .croppa-container:hover {
    background-color: #dedede;
  }
}

.theme--dark {
  .croppa-container {
    background-color: #41485e;
  }

  .croppa-container:hover {
    background-color: #161d31;
  }
}

.croppa-container {
  border-radius: 0.75rem !important;
  canvas {
    border-radius: 0.75rem !important;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
  }
}

.croppa-container:hover {
  opacity: 1;
}

.v-messages__message {
  margin-top: 4px;
}
</style>
