<template>
  <v-form ref="form" v-model="form.valid" lazy-validation>
    <v-progress-linear
      :active="saving"
      :indeterminate="saving"
      absolute
      top
      color="primary"
    />
    <akn-text-field
      v-model="category_.name"
      :label="$t('category_name')"
      :rules="form.name.rules"
      :error-messages="form.name.errors"
    />
  </v-form>
</template>

<script>
import Vue from "vue";
import { store, update } from "@/api/categories.api";

import AknTextField from "@/components/AknTextField.vue";

const { i18n } = Vue;

export default {
  name: "CategoryForm",
  components: { AknTextField },
  props: {
    category: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        name: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 3) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("category_name"),
                    y: 3,
                  });
                } else if (v.length > 100) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("category_name"),
                    y: 100,
                  });
                }
                return true;
              }
              return i18n.t("x_is_required", { x: i18n.t("category_name") });
            },
          ],
        },
        valid: false,
      },
      saving: false,
    };
  },
  computed: {
    category_: {
      get() {
        return this.category;
      },
      set(value) {
        this.$emit("update:category", value);
      },
    },
  },
  watch: {
    "category.name": function () {
      this.form.name.errors = [];
    },
  },
  methods: {
    reset() {
      this.category_ = {};
      this.$refs.form.resetValidation();
    },
    async submit() {
      await this.$refs.form.validate();
      if (this.form.valid) {
        this.saving = true;
        const { name } = this.category;
        (this.category.id
          ? update(this.category.id, { name })
          : store({ name })
        )
          .then(
            ({ data }) => {
              if (this.category.id) {
                this.$emit("updated", data);
                this.$toast.success(this.$t("category_successfully_updated"));
              } else {
                this.$emit("stored", data);
                this.$toast.success(this.$t("category_successfully_saved"));
              }
              this.reset();
            },
            (error) => {
              const { data, status } = error.response ?? {};
              if (status === 401) {
                this.$toast.error(data?.detail);
              } else if (status === 422) {
                for (const [key, value] of Object.entries(data?.errors ?? [])) {
                  if (this.form[key]) {
                    this.form[key].errors = value;
                  }
                }
                if (data.message) {
                  this.$toast.error(data.message);
                }
              }
            }
          )
          .finally(() => {
            this.saving = false;
          });
      }
    },
  },
};
</script>
