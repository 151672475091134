<template>
  <div class="person-column">
    <div style="max-width: 100px; min-width: 100px">
      <base-image
        img-class="ma-auto rounded"
        max-height="100px"
        max-width="100px"
        :src="person?.photo?.photo"
        style="width: fit-content"
        :thumbnail="person?.photo?.thumbnail"
      />
    </div>
    <v-simple-table style="flex-grow: 1; height: fit-content">
      <tbody>
        <tr>
          <td>
            <v-label>
              <span class="font-weight-bold">
                {{ $t("name") }}
              </span>
            </v-label>
          </td>
          <td>
            {{ person.name ?? "-" }}
          </td>
          <td>
            <v-label>
              <span class="font-weight-bold">
                {{ $t("surname") }}
              </span>
            </v-label>
          </td>
          <td>
            {{ person.surname ?? "-" }}
          </td>
        </tr>
        <tr>
          <td>
            <v-label>
              <span class="font-weight-bold">
                {{ $t("national_number") }}
              </span>
            </v-label>
          </td>
          <td>
            {{ person.national_number ?? "-" }}
          </td>
          <td>
            <v-label>
              <span class="font-weight-bold">
                {{ $t("passport_number") }}
              </span>
            </v-label>
          </td>
          <td>
            {{ person.passport_number ?? "-" }}
          </td>
        </tr>
        <tr>
          <td>
            <v-label>
              <span class="font-weight-bold">
                {{ $t("birthday") }}
              </span>
            </v-label>
          </td>
          <td>
            {{ person.birthday ?? "-" }}
          </td>
          <td>
            <v-label>
              <span class="font-weight-bold">
                {{ $t("gender") }}
              </span>
            </v-label>
          </td>
          <td>
            {{ $t(person.gender) }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import BaseImage from "@/components/base/Image.vue";

export default {
  name: "PersonColumn",
  components: { BaseImage },
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.person-column ::v-deep {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;

  span {
    font-size: 13px;
    line-height: 18px;
    white-space: nowrap;
  }

  table {
    table-layout: fixed !important;
  }

  td {
    border-bottom: unset !important;
    height: auto !important;
    padding: 1px !important;
    width: 25% !important;
  }
}

.v-data-table--mobile {
  .person-column ::v-deep {
    tr {
      display: flex;
      td {
        text-align: left;
      }
    }
  }
}
</style>
