<template>
  <v-navigation-drawer
    v-model="getTheme.leftNavigationDrawer"
    :color="isDarkMode ? 'dark' : 'white'"
    :dark="isDarkMode"
    app
    :disable-resize-watcher="false"
    :mobile-breakpoint="960"
    height="100%"
    class="border-right left-navigation-drawer shadow-sm"
    floating
    :right="false"
    v-bind="$attrs"
    width="300"
  >
    <div class="d-flex flex-column h-full">
      <v-list dense>
        <v-list-item class="mb-0">
          <v-list-item-avatar>
            <v-img :src="isDarkMode ? logoDark : logoLight" eager />
          </v-list-item-avatar>
          <v-list-item-title class="mr-2">
            <span class="font-weight-bold h6 mb-0 text-16 text-uppercase">
              {{ $t("app_name") }}
            </span>
          </v-list-item-title>
          <v-btn
            class="d-sm-block d-md-none"
            icon
            small
            @click="toggleLeftNavigationDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider />
      </v-list>
      <div class="flex-grow-1 overflow-x-hidden overflow-y-auto">
        <v-list dense rounded>
          <template v-for="(item, i) in computedItems">
            <div v-if="$auth.check(item.auth)" :key="i" class="py-1">
              <div v-if="item.subheader" :key="item.subheader">
                <v-subheader>
                  {{ item.subheader }}
                </v-subheader>
                <v-divider />
              </div>
              <base-item-group
                v-if="item.children"
                :key="`group-${i}`"
                :item="item"
              />
              <base-item
                v-else-if="!item.subheader && !item.children"
                :key="`item-${i}`"
                :item="item"
              />
            </div>
          </template>
        </v-list>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { items } from "@/navigation";

import BaseItem from "@/components/base/Item.vue";
import BaseItemGroup from "@/components/base/ItemGroup.vue";

export default {
  name: "LeftNavigationDrawer",
  components: { BaseItem, BaseItemGroup },
  data() {
    return {
      items,
      logoDark: require("@/assets/images/logo_dark_transparent.png"),
      logoLight: require("@/assets/images/logo_light_transparent.png"),
    };
  },
  computed: {
    ...mapGetters(["getTheme", "isDarkMode", "user"]),
    computedItems() {
      return this.items.map(this.mapItem);
    },
  },
  methods: {
    ...mapActions(["changeLeftNavigationDrawer"]),
    logoutUser() {
      this.$auth.logout();
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },
    toggleLeftNavigationDrawer() {
      this.changeLeftNavigationDrawer();
    },
  },
};
</script>

<style lang="scss" scoped>
.left-navigation-drawer ::v-deep {
  border-radius: 4px !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.03), 0 15px 12px rgba(0, 0, 0, 0.03) !important;
}
</style>
