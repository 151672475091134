const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
const theme = JSON.parse(localStorage.getItem("theme"));

const state = {
  theme: {
    dark: theme === null ? mediaQueryList.matches : theme.dark,
    isLoading: false,
    leftNavigationDrawer: undefined,
    rightNavigationDrawer: false,
  },
};

const getters = {
  getTheme: (state) => state.theme,
  isDarkMode: (state) => state.theme.dark,
};

const actions = {
  changeDarkMode({ commit }, payload) {
    commit("toggleDarkMode", payload);
  },
  changeLeftNavigationDrawer({ commit }) {
    commit("toggleLeftNavigationDrawer");
  },
  changeRightNavigationDrawer({ commit }) {
    commit("toggleRightNavigationDrawer");
  },
  changeThemeLoadingState({ commit }, payload) {
    commit("toggleThemeLoadingState", payload);
  },
};

const mutations = {
  toggleDarkMode(state, payload) {
    state.theme.dark = payload;
    localStorage.setItem(
      "theme",
      JSON.stringify({
        dark: state.theme.dark,
      })
    );
  },
  toggleLeftNavigationDrawer(state) {
    state.theme.leftNavigationDrawer = !state.theme.leftNavigationDrawer;
  },
  toggleRightNavigationDrawer(state) {
    state.theme.rightNavigationDrawer = !state.theme.rightNavigationDrawer;
  },
  toggleThemeLoadingState(state, payload) {
    state.theme.isLoading = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
