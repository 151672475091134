<template>
  <v-form ref="form" v-model="form.valid" lazy-validation>
    <v-progress-linear
      :active="submitting"
      :indeterminate="submitting"
      absolute
      top
      color="primary"
    />
    <person-photo
      v-model="photo"
      :accept="form.photo.accept"
      :error-messages="form.photo.errors"
      :rules="form.photo.rules"
      @clearErrors="form.photo.errors = []"
    />
  </v-form>
</template>

<script>
import Vue from "vue";
import { search } from "@/api/queries.api";

import PersonPhoto from "@/views/pages/people/PersonPhoto.vue";

const { i18n } = Vue;

export default {
  name: "QueryForm",
  components: { PersonPhoto },
  data() {
    return {
      form: {
        photo: {
          accept: "image/jpg,image/jpeg,image/png",
          errors: [],
          rules: [
            (o) => {
              if (o?.photo || o?.data) {
                return true;
              }
              return i18n.t("x_is_required", { x: i18n.t("photo") });
            },
          ],
        },
        valid: false,
      },
      photo: null,
      submitting: false,
    };
  },
  computed: {
    //
  },
  watch: {
    photo() {
      this.form.photo.errors = [];
    },
  },
  methods: {
    reset() {
      this.photo = null;
      this.$refs.form.resetValidation();
    },
    async submit() {
      await this.$refs.form.validate();
      if (this.form.valid) {
        this.submitting = true;
        search({ photo: this.photo.data })
          .then(
            ({ data }) => {
              this.$emit("submitted", data);
              this.reset();
            },
            (error) => {
              const { data, status } = error.response ?? {};
              if (status === 401) {
                this.$toast.error(data?.detail);
              } else if (status === 422) {
                for (const [key, value] of Object.entries(data?.errors ?? [])) {
                  if (this.form[key]) {
                    this.form[key].errors = value;
                  }
                }
                if (data.message) {
                  this.$toast.error(data.message);
                }
              }
            }
          )
          .finally(() => {
            this.submitting = false;
          });
      }
    },
  },
};
</script>
