<template>
  <v-row class="person-detail">
    <v-col
      cols="auto"
      xl="5"
      lg="5"
      md="12"
      sm="12"
      class="mt-3"
      style="margin: 0 auto"
    >
      <person-photo
        :size="180"
        class="w-full"
        readonly
        :src="person?.photo?.photo ?? ''"
      >
      </person-photo>
      <div class="mt-3 px-5 justify-center w-full d-flex">
        <person-info-alert
          :categories="person.categories"
          :cause-of-wanted="person.cause_of_wanted"
          :is-wanted="person.is_wanted"
        />
      </div>
    </v-col>
    <v-col cols="auto" xl="7" lg="7" md="12" sm="12" style="flex-grow: 1">
      <v-simple-table dense class="person-detail-table">
        <tbody>
          <tr>
            <td>{{ $t("name_surname") }}</td>
            <td>
              {{ person?.full_name?.trim()?.length ? person?.full_name : "-" }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("national_number") }}</td>
            <td>{{ person?.national_number ?? "-" }}</td>
          </tr>
          <tr>
            <td>{{ $t("passport_number") }}</td>
            <td>{{ person?.passport_number ?? "-" }}</td>
          </tr>
          <tr>
            <td>{{ $t("gender") }}</td>
            <td>{{ person?.gender ? $t(person.gender) : "-" }}</td>
          </tr>
          <tr>
            <td>{{ $t("name_of_father") }}</td>
            <td>{{ person?.name_of_father ?? "-" }}</td>
          </tr>
          <tr>
            <td>{{ $t("name_of_mother") }}</td>
            <td>{{ person?.name_of_mother ?? "-" }}</td>
          </tr>
          <tr>
            <td>{{ $t("birthday") }}</td>
            <td>
              {{
                person?.birthday
                  ? $d(Date.parse(person.birthday), "short", "tr")
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("age") }}</td>
            <td>{{ person?.age ?? "-" }}</td>
          </tr>
          <tr>
            <td>{{ $t("date_of_death") }}</td>
            <td>
              {{
                person?.date_of_death
                  ? $d(Date.parse(person.date_of_death), "short", "tr")
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("marital_status") }}</td>
            <td>
              {{ person?.marital_status ? $t(person.marital_status) : "-" }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("created_at") }}</td>
            <td>
              {{ $d(new Date((person?.created_at ?? 0) * 1000), "full", "tr") }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("updated_at") }}</td>
            <td>
              {{ $d(new Date((person?.updated_at ?? 0) * 1000), "full", "tr") }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import PersonInfoAlert from "@/views/pages/people/PersonInfoAlert";
export default {
  name: "PersonDetail",
  components: {
    PersonInfoAlert,
    PersonPhoto: () => import("./PersonPhoto.vue"),
  },
  props: {
    person: {
      type: Object,
      default: () => ({
        full_name: null,
        national_number: null,
        passport_number: null,
        gender: null,
        name_of_father: null,
        name_of_mother: null,
        birthday: null,
        date_of_death: null,
        marital_status: null,
        is_wanted: null,
        cause_of_wanted: null,
        photo: {},
        created_at: 0,
        updated_at: 0,
      }),
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.person-detail {
  margin: -12px !important;
  .row {
    margin: -12px !important;
  }
  .cause_of_wanted {
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    .v-alert__content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
  .cause_of_wanted,
  .wanted {
    line-height: 15px;
    position: absolute;
  }
  .wanted {
    left: 0;
    top: 0;
  }
}
</style>
