import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./lang";

import plugins from "./plugins";
import vuetify from "./plugins/vuetify";

Vue.use(plugins);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
