<template>
  <v-form ref="form" v-model="form.valid" lazy-validation>
    <v-progress-linear
      :active="saving"
      :indeterminate="saving"
      absolute
      top
      color="primary"
    />
    <akn-text-field
      v-model="role_.description"
      :label="$t('role_name')"
      :rules="form.description.rules"
      :error-messages="form.description.errors"
    />
    <v-data-table
      v-model="role_.permissions"
      :headers="permissionsTable.headers"
      :items="permissionsTable.items"
      :server-items-length="permissionsTable.items.length"
      fixed-header
      hide-default-footer
      show-select
    />
  </v-form>
</template>

<script>
import Vue from "vue";
import { index } from "@/api/permissions.api";
import { store, update } from "@/api/roles.api";

import AknTextField from "@/components/AknTextField.vue";

const { i18n } = Vue;

export default {
  name: "RoleForm",
  components: { AknTextField },
  props: {
    role: {
      type: Object,
      default: () => ({
        name: "",
        description: "",
        permissions: [],
      }),
    },
  },
  data() {
    return {
      emptyRole: {
        name: "",
        description: "",
        permissions: [],
      },
      form: {
        description: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 3) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("role_name"),
                    y: 3,
                  });
                } else if (v.length > 100) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("role_name"),
                    y: 100,
                  });
                }
                return true;
              }
              return i18n.t("x_is_required", { x: i18n.t("role_name") });
            },
          ],
        },
        valid: false,
      },
      permissionsTable: {
        headers: [
          {
            text: i18n.t("permission"),
            value: "description",
            sortable: false,
          },
        ],
        items: [],
      },
      saving: false,
    };
  },
  computed: {
    role_: {
      get() {
        return this.role;
      },
      set(value) {
        this.$emit("update:role", value);
      },
    },
  },
  watch: {
    "role.description": function () {
      this.form.description.errors = [];
    },
  },
  methods: {
    fetchPermissions() {
      index({ perPage: -1 }).then(
        ({ data }) => {
          this.permissionsTable.items.splice(
            0,
            this.permissionsTable.items.length,
            ...data
          );
        },
        () => {
          this.$toast.error(this.$t("error_while_retrieving_permission_list"));
        }
      );
    },
    reset() {
      this.role_ = Object.assign({}, this.emptyRole);
      this.$refs.form.resetValidation();
    },
    async submit() {
      await this.$refs.form.validate();
      if (this.form.valid) {
        this.saving = true;
        const { description } = this.role;
        const permissions = this.role.permissions.map(
          (permission) => permission.id
        );
        (this.role.id
          ? update(this.role.id, { description, permissions })
          : store({ description, permissions })
        )
          .then(
            ({ data }) => {
              if (this.role.id) {
                this.$emit("updated", data);
                this.$toast.success(this.$t("role_successfully_updated"));
              } else {
                this.$emit("stored", data);
                this.$toast.success(this.$t("role_successfully_saved"));
              }
              this.reset();
            },
            (error) => {
              const { data, status } = error.response ?? {};
              if (status === 401) {
                this.$toast.error(data?.detail);
              } else if (status === 422) {
                for (const [key, value] of Object.entries(data?.errors ?? [])) {
                  if (this.form[key]) {
                    this.form[key].errors = value;
                  }
                }
                if (data.message) {
                  this.$toast.error(data.message);
                }
              }
            }
          )
          .finally(() => {
            this.saving = false;
          });
      }
    },
  },
  beforeMount() {
    this.fetchPermissions();
  },
};
</script>
