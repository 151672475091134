import i18n from "@/lang";

export const items = [
  {
    icon: "mdi-access-point",
    title: i18n.t("histories_live"),
    to: "histories-live",
    auth: ["canli-gecisleri-gorebilir"],
  },
  {
    icon: "mdi-target-account",
    title: i18n.t("histories"),
    to: "histories",
    auth: ["gecis-kayitlarini-gorebilir"],
  },
  {
    icon: "mdi-magnify-expand",
    title: i18n.t("person_queries"),
    to: "queries",
    auth: ["sahis-sorgularini-gorebilir"],
  },
  {
    subheader: i18n.t("MANAGEMENT"),
    divider: false,
    auth: [
      "sahislari-gorebilir",
      "kontrol-noktalarini-gorebilir",
      "cihazlari-gorebilir",
      "kullanicilari-gorebilir",
      "rolleri-gorebilir",
      "ayarlari-gorebilir",
      "takip-listesi-kategorilerini-gorebilir",
    ],
  },
  {
    icon: "mdi-badge-account-horizontal-outline",
    title: i18n.t("watch_list_management"),
    auth: ["sahislari-gorebilir", "takip-listesi-kategorilerini-gorebilir"],
    children: [
      {
        icon: "mdi-badge-account-alert-outline",
        title: i18n.t("watch_list"),
        to: "people",
        auth: ["sahislari-gorebilir"],
      },
      {
        icon: "mdi-shape-plus",
        title: i18n.t("watch_categories"),
        to: "categories",
        auth: ["takip-listesi-kategorilerini-gorebilir"],
      },
    ],
  },
  {
    icon: "mdi-monitor-dashboard",
    title: i18n.t("checkpoints"),
    to: "checkpoints",
    auth: ["kontrol-noktalarini-gorebilir"],
  },
  {
    icon: "mdi-cctv",
    title: i18n.t("devices"),
    to: "devices",
    auth: ["cihazlari-gorebilir"],
  },
  {
    icon: "mdi-account-group",
    title: i18n.t("user_management"),
    auth: ["kullanicilari-gorebilir", "rolleri-gorebilir"],
    children: [
      {
        icon: "mdi-account-multiple",
        title: i18n.t("users"),
        to: "users",
        auth: ["kullanicilari-gorebilir"],
      },
      {
        icon: "mdi-account-tie",
        title: i18n.t("roles"),
        to: "roles",
        auth: ["rolleri-gorebilir"],
      },
    ],
  },
  {
    icon: "mdi-cog",
    title: i18n.t("settings"),
    to: "settings",
    auth: ["ayarlari-gorebilir"],
  },
];
