import { axios } from "@/plugins/axios";

export async function index(query) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/web/queries", { params: query })
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function search(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/web/queries/search", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}
