<template>
  <v-app-bar
    :color="isDarkMode ? 'dark' : 'white'"
    :dark="isDarkMode"
    app
    class="app-bar text-left"
    flat
    height="64"
  >
    <v-app-bar-nav-icon @click="toggleLeftNavigationDrawer" />
    <v-progress-linear
      :active="getTheme.isLoading"
      :indeterminate="getTheme.isLoading"
      absolute
      bottom
      color="primary"
    />
    <v-spacer />
    <v-btn icon small fab @click="toggleDarkMode">
      <v-icon>
        {{ isDarkMode ? "mdi-white-balance-sunny" : "mdi-weather-night" }}
      </v-icon>
    </v-btn>
    <v-badge
      bordered
      overlap
      :content="unreadNotificationCount"
      :value="unreadNotificationCount"
      color="warning"
      offset-x="22"
      offset-y="22"
    >
      <v-btn icon @click="toggleRightNavigationDrawer">
        <v-icon>mdi-bell</v-icon>
      </v-btn>
    </v-badge>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          pill
          class="transparent rounded-pill py-6"
          v-bind="attrs"
          v-on="on"
        >
          <span class="text-16 font-weight-regular">{{ user.name }}</span>
          <v-avatar class="ml-2" color="grey">
            <span class="white--text text-h5">{{ userShortName }}</span>
          </v-avatar>
        </v-chip>
      </template>
      <v-list>
        <v-list-item
          link
          style="gap: 0.5rem"
          @click="showUserProfileFormDialog = true"
        >
          <v-list-item-icon class="align-self-center ma-0">
            <v-icon>mdi-card-account-details-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("profile_information")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link style="gap: 0.5rem" @click="logoutUser">
          <v-list-item-icon class="align-self-center ma-0">
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <user-profile-form-dialog :show.sync="showUserProfileFormDialog" />
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import UserProfileFormDialog from "@/views/pages/users/UserProfileFormDialog.vue";

export default {
  name: "AppBar",
  components: { UserProfileFormDialog },
  data() {
    return {
      showUserProfileFormDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      "getTheme",
      "isDarkMode",
      "unreadNotificationCount",
      "user",
    ]),
    userShortName() {
      return this.user?.name
        ?.split(" ")
        .map((namePart) => {
          return namePart.charAt(0).toUpperCase();
        })
        .join("");
    },
  },
  methods: {
    ...mapActions([
      "changeDarkMode",
      "changeLeftNavigationDrawer",
      "changeRightNavigationDrawer",
    ]),
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.changeDarkMode(this.$vuetify.theme.dark);
    },
    toggleLeftNavigationDrawer() {
      this.changeLeftNavigationDrawer();
    },
    toggleRightNavigationDrawer() {
      this.changeRightNavigationDrawer();
    },
    logoutUser() {
      this.$auth.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar ::v-deep {
  margin-top: 24px !important;
  margin-inline: 32px !important;
  border-radius: 8px !important;
}
</style>
