import Toast from "vue-toastification";
import VueMeta from "vue-meta";
import VueViewer from "v-viewer";

import axios from "./axios";
import auth from "./auth";
import echo from "./echo";
import VueEllipseProgress from "vue-ellipse-progress";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";

const toastOptions = {
  closeOnClick: false,
  position: "top-center",
  transition: "Vue-Toastification__fade",
  newestOnTop: true,
};

export default {
  install(Vue) {
    Vue.use(Toast, toastOptions);
    Vue.use(VueMeta);
    Vue.use(VueViewer);
    Vue.use(axios);
    Vue.use(auth);
    Vue.use(echo);
    Vue.use(VueEllipseProgress);
    Vue.use(PerfectScrollbar);
    Vue.use(Croppa);
  },
};
