import auth from "@websanova/vue-auth/src/v2.js";
import driverAuthBearer from "@websanova/vue-auth/src/drivers/auth/bearer.js";
import driverHttpAxios from "@websanova/vue-auth/src/drivers/http/axios.1.x.js";
import driverRouterVueRouter from "@websanova/vue-auth/src/drivers/router/vue-router.2.x.js";

export default {
  install(Vue) {
    Vue.use(auth, {
      plugins: {
        http: Vue.axios, // Axios
        router: Vue.router,
      },
      drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios, // Axios
        router: driverRouterVueRouter,
      },
      options: {
        staySignedIn: true,
        rememberKey: "auth_remember",
        tokenDefaultKey: "auth_token_default",
        stores: ["storage", "cookie"],
        authRedirect: { name: "login" },
        notFoundRedirect: { name: "not-found" },
        forbiddenRedirect: { name: "forbidden" },
        loginData: {
          url: "/api/auth/login",
          method: "POST",
          redirect: false,
          fetchUser: false,
        },
        registerData: {
          url: "/api/auth/register",
          method: "POST",
          redirect: false,
          fetchUser: false,
        },
        logoutData: {
          url: "/api/auth/logout",
          method: "POST",
          redirect: { name: "login" },
          makeRequest: true,
        },
        fetchData: { url: "/api/auth/me", method: "GET", enabled: true },
        refreshData: {
          url: "/api/refresh",
          method: "GET",
          enabled: false,
          interval: 20,
        },
        // adds the edited permission data to user model
        parseUserData: (data) => {
          const user = data.user || data.data;

          user.rolesAndPermissions = [
            ...user.roles.map((role) => role.name),
            ...user.permissions,
          ];

          Vue.store.commit("setUser", user);

          return user;
        },
        rolesKey: "rolesAndPermissions",
      },
    });
  },
};
