import { render, staticRenderFns } from "./UserFormDialog.vue?vue&type=template&id=282b8442&scoped=true&"
import script from "./UserFormDialog.vue?vue&type=script&lang=js&"
export * from "./UserFormDialog.vue?vue&type=script&lang=js&"
import style0 from "./UserFormDialog.vue?vue&type=style&index=0&id=282b8442&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "282b8442",
  null
  
)

export default component.exports