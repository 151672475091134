var render = function render(){var _vm=this,_c=_vm._self._c;return _c('perfect-scrollbar',{on:{"&scroll":function($event){return _vm.handleScroll.apply(null, arguments)}}},[_c('v-row',{staticClass:"scroll-content px-3 py-2",attrs:{"justify":"center"}},[(_vm.histories.length > 0)?_vm._l((_vm.histories),function(history){return _c('v-col',{key:history.id,attrs:{"sm":"12","md":"12","xl":"6","lg":"6"}},[_c('v-card',{staticClass:"card-shadow"},[_c('v-card-title',{staticClass:"pt-3 pl-3 d-flex"},[_c('small',[_vm._v(" "+_vm._s(_vm.$d(new Date((history.created_at ?? 0) * 1000), "full2", "tr")))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('router-link',{staticClass:"v-btn v-btn--icon",class:{
                    'v-btn--disabled': !_vm.$auth.check('sahislari-gorebilir'),
                  },attrs:{"target":"_blank","to":{
                    name: 'person-detail',
                    params: { id: history?.person?.id },
                  }}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("show_person_details")))])])],1),_c('hr',{staticClass:"horizontal rounded-hr mb-3"}),_c('div',[_c('person-photo-match',{staticStyle:{"height":"fit-content","max-height":"calc(100% - 36px)","margin":"auto","width":"fit-content"},attrs:{"scanner":true,"height":80,"person-full-name":history?.person?.full_name,"similarity":history?.similarity,"source":history?.photo?.thumbnail ?? history?.photo?.photo,"target":history?.person?.photo?.thumbnail ??
                history?.person?.photo?.photo}})],1),_c('hr',{staticClass:"horizontal rounded-hr mb-3"}),_c('v-card-actions',{staticClass:"py-0"},[_c('v-chip',{staticClass:"ma-2",attrs:{"outlined":"","label":"","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-cctv")]),_vm._v(" "+_vm._s(history.device?.name)+" ")],1),_c('v-chip',{staticClass:"ma-2",attrs:{"outlined":"","label":"","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-shield-account")]),_vm._v(" "+_vm._s(history.checkpoint?.name)+" ")],1)],1)],1)],1)}):(_vm.loading)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_c('v-col',[_c('v-alert',{staticClass:"mb-0 text-14",attrs:{"border":"left","colored-border":"","max-width":"340","color":"primary","dense":"","icon":"mdi-information-outline","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("no_transition_record_this_person"))+" ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }