<template>
  <v-dialog
    v-model="show_"
    class="checkpoint-api-key-dialog"
    max-width="fit-content"
    persistent
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
    <transition-group name="fade" mode="out-in">
      <v-card
        v-show="states.hasApiKey.show"
        key="states.hasApiKey.show"
        outlined
        style="max-width: 28rem"
      >
        <v-card-text class="d-flex flex-column pt-5" style="gap: 0.5rem">
          <v-alert
            class="mb-0 text-14"
            dense
            icon="mdi-alert-circle-outline"
            type="warning"
          >
            {{ $t("checkpoint_has_api_key_warning") }}
          </v-alert>
          <span
            v-html="
              $t('checkpoint_x_has_api_key_generated_on_y_question', {
                x: `${checkpoint?.name} | ${checkpoint?.description}`,
                y: $d(
                  new Date(hasApiKeyDetail.created_at * 1000),
                  'full',
                  'tr'
                ),
              })
            "
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small depressed color="secondary" @click="show_ = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small depressed color="warning" @click="newApiKeyConfirm">
            <v-icon class="mr-1" small>mdi-key-variant</v-icon>
            {{ $t("create_new_api_key") }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <v-card
        v-show="states.noApiKey.show"
        key="states.noApiKey.show"
        outlined
        style="max-width: 28rem"
      >
        <v-card-text class="d-flex flex-column pt-5" style="gap: 0.5rem">
          <v-alert
            class="mb-0 text-14"
            color="primary"
            dense
            icon="mdi-help-circle-outline"
            type="info"
          >
            <span
              v-html="
                $t('checkpoint_x_has_not_api_key_question', {
                  x: `${checkpoint?.name} | ${checkpoint?.description}`,
                })
              "
            />
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small depressed color="secondary" @click="show_ = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small depressed color="success" @click="newApiKeyConfirm">
            <v-icon class="mr-1" small>mdi-key-variant</v-icon>
            {{ $t("create_new_api_key") }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <v-card
        key="states.loading.show"
        outlined
        class="flex-nowrap pa-4"
        :style="{
          display: states.loading.show ? 'inline-flex' : 'none',
          gap: '1rem',
        }"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          style="min-height: 32px; min-width: 32px"
        />
        <span style="margin-top: 0.3rem">{{ states.loading.text }}</span>
      </v-card>
      <v-card
        v-show="states.newApiKeyCreated.show"
        key="states.newApiKeyCreated.show"
        outlined
        style="max-width: 36rem"
      >
        <v-card-text class="d-flex flex-column pt-5" style="gap: 0.5rem">
          <v-alert
            class="mb-0 text-14"
            dense
            icon="mdi-key-variant"
            type="success"
            style="overflow-wrap: anywhere; word-break: break-all"
          >
            {{ apiKey }}
          </v-alert>
          <v-alert
            class="mb-0 text-14"
            dense
            icon="mdi-alert-circle-outline"
            type="warning"
          >
            {{ $t("new_checkpoint_api_key_created_warning") }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small depressed color="success" @click="show_ = false">
            <v-icon class="mr-1" small>mdi-check-circle</v-icon>
            {{ $t("ok") }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <v-alert
        v-show="states.error.show"
        key="states.error.show"
        class="mb-0 text-14"
        dense
        icon="mdi-alert-circle-outline"
        type="error"
      >
        <div class="d-flex flex-column justify-end" style="gap: 0.5rem">
          <span>{{ states.error.text }}</span>
          <v-btn
            color="secondary"
            depressed
            style="align-self: end; width: min-content"
            x-small
            @click="callPendingActions"
          >
            <v-icon left>mdi-refresh</v-icon>
            <span>{{ $t("try_again") }}</span>
          </v-btn>
        </div>
      </v-alert>
    </transition-group>
  </v-dialog>
</template>

<script>
import { createApiKey, showApiKey } from "@/api/checkpoints.api";

export default {
  name: "CheckpointApiKeyDialog",
  props: {
    checkpoint: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiKey: "",
      hasApiKeyDetail: {
        created_at: 0,
        expires_at: 0,
        last_used_at: 0,
        name: "",
      },
      pending: {
        actions: [],
      },
      states: {
        error: {
          text: "",
          show: false,
        },
        hasApiKey: {
          text: "",
          show: false,
        },
        loading: {
          text: "",
          show: false,
        },
        newApiKeyCreated: {
          text: "",
          show: false,
        },
        noApiKey: {
          text: "",
          show: false,
        },
      },
    };
  },
  computed: {
    show_: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    checkpoint: {
      handler(newValue) {
        newValue && this.checkApiKey();
      },
      deep: true,
    },
    show(newValue) {
      !newValue && this.reset();
    },
  },
  methods: {
    callPendingActions() {
      for (
        let action = this.pending.actions.pop();
        action;
        action = this.pending.actions.pop()
      ) {
        action.callback(action.param);
      }
    },
    changeState(state, text = "") {
      for (const [key, value] of Object.entries(this.states)) {
        if (key === state) {
          this.states[key] = {
            ...value,
            text,
            show: true,
          };
        } else {
          this.states[key] = {
            ...value,
            text: "",
            show: false,
          };
        }
      }
    },
    checkApiKey() {
      this.changeState("loading", this.$t("checkpoint_api_key_info_fetching"));
      showApiKey(this.checkpoint.id).then(
        (data) => {
          this.hasApiKeyDetail = data;
          this.changeState("hasApiKey");
        },
        (error) => {
          if (error.response?.status === 404) {
            this.changeState("noApiKey");
          } else {
            this.changeState(
              "error",
              this.$t("checkpoint_api_key_info_fetch_error")
            );
            this.pending.actions.push({
              callback: this.checkApiKey,
              params: undefined,
            });
          }
        }
      );
    },
    newApiKeyConfirm() {
      this.changeState("loading", this.$t("new_checkpoint_api_key_creating"));
      createApiKey(this.checkpoint.id).then(
        ({ token, type }) => {
          this.apiKey = `${type} ${token}`;
          this.changeState("newApiKeyCreated");
        },
        () => {
          this.changeState(
            "error",
            this.$t("new_checkpoint_api_key_create_error")
          );
          this.pending.actions.push({
            callback: this.newApiKeyConfirm,
            params: undefined,
          });
        }
      );
    },
    reset() {
      this.changeState("");
      this.pending.actions.splice(0, this.pending.actions.length);
      this.hasApiKeyDetail = {
        created_at: 0,
        expires_at: 0,
        last_used_at: 0,
        name: "",
      };
      this.apiKey = "";
    },
  },
};
</script>
