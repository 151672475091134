<template>
  <div>
    <div>
      <v-toolbar class="elevation-0 w-full" rounded>
        <div class="d-flex align-center" style="gap: 12px">
          <v-toolbar-title
            class="d-flex flex-nowrap align-items-center"
            style="gap: 0.5rem; width: min-content"
          >
            <v-icon>mdi-access-point</v-icon>
            <span>{{ $t("histories_live") }}</span>
          </v-toolbar-title>
          <v-divider vertical />
          <akn-select
            v-model="checkpoint"
            :items="checkpoints"
            :label="$t('checkpoint')"
            :disabled="checkpointsLoading || devicesLoading || historiesLoading"
            item-text="name"
            item-value="id"
            style="max-width: 10rem"
            hide-details
          />
          <akn-select
            v-show="checkpoint"
            v-model="device"
            :items="devices"
            :label="$t('device')"
            :disabled="checkpointsLoading || devicesLoading || historiesLoading"
            item-text="name"
            item-value="id"
            style="max-width: 10rem"
            hide-details
          />
          <akn-select
            v-model="perPage"
            :items="perPageItems"
            :label="$t('show')"
            :disabled="checkpointsLoading || devicesLoading || historiesLoading"
            style="max-width: 84px"
            item-text="name"
            item-value="id"
            hide-details
          />
          <v-switch
            v-model="showOnlyWanted"
            :label="$t('show_only_wanted')"
            :disabled="checkpointsLoading || devicesLoading || historiesLoading"
            class="text-no-wrap"
            hide-details
            dense
            inset
          />
        </div>
      </v-toolbar>
      <v-container style="padding: 0 !important">
        <v-row>
          <v-col
            sm="12"
            md="12"
            xl="3"
            lg="6"
            v-for="history in histories_"
            :key="history.id"
          >
            <v-card
              :color="history.is_alarm ? 'alert-warning' : undefined"
              class="card-shadow"
            >
              <v-card-title class="pt-3 pl-3 d-flex">
                <small>
                  {{
                    $d(
                      new Date((history.created_at ?? 0) * 1000),
                      "full2",
                      "tr"
                    )
                  }}</small
                >
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <router-link
                      class="v-btn v-btn--icon"
                      :class="{
                        'v-btn--disabled': !$auth.check('sahislari-gorebilir'),
                      }"
                      target="_blank"
                      :to="{
                        name: 'person-detail',
                        params: { id: history?.person?.id },
                      }"
                    >
                      <v-icon small v-bind="attrs" v-on="on">mdi-eye</v-icon>
                    </router-link>
                  </template>
                  <span>{{ $t("show_person_details") }}</span>
                </v-tooltip>
              </v-card-title>
              <hr class="horizontal rounded-hr mb-3" />
              <div>
                <person-photo-match
                  :scanner="true"
                  :height="80"
                  :person-full-name="history?.person?.full_name"
                  :similarity="history?.similarity"
                  :source="history?.photo?.thumbnail ?? history?.photo?.photo"
                  style="
                    height: fit-content;
                    max-height: calc(100% - 36px);
                    margin: auto;
                    width: fit-content;
                  "
                  :target="
                    history?.person?.photo?.thumbnail ??
                    history?.person?.photo?.photo
                  "
                />
              </div>
              <hr class="horizontal rounded-hr mb-3" />
              <v-card-actions class="py-0">
                <v-chip outlined label small class="ma-2">
                  <v-icon left small>mdi-cctv</v-icon>
                  {{ history.device?.name }}
                </v-chip>

                <v-chip outlined label small class="ma-2">
                  <v-icon left small>mdi-shield-account</v-icon>
                  {{ history.checkpoint?.name }}
                </v-chip>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { index as checkpointsIndex } from "@/api/checkpoints.api";
import { index as devicesIndex } from "@/api/devices.api";
import { index as historiesIndex } from "@/api/histories.api";

const { i18n } = Vue;

export default {
  name: "HistoriesLivePage",
  components: {
    AknSelect: () => import("@/components/AknSelect.vue"),
    PersonPhotoMatch: () => import("@/views/pages/people/PersonPhotoMatch.vue"),
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: i18n.t("histories_live"),
  },
  data: () => ({
    checkpoint: null,
    checkpoints: [],
    checkpointsLoading: false,
    device: null,
    devices: [],
    devicesLoading: false,
    histories: [],
    historiesLoading: false,
    perPage: 25,
    perPageItems: [25, 50, 100, 200],
    showOnlyWanted: false,
  }),
  computed: {
    histories_() {
      if (this.showOnlyWanted) {
        return this.histories.filter(
          (history) => history.is_alarm === this.showOnlyWanted
        );
      }
      return this.histories;
    },
  },
  watch: {
    checkpoint(newValue, oldValue) {
      if (oldValue) {
        this.leaveChannels(oldValue, this.device);
      }
      if (newValue) {
        this.fetchDevices();
      } else {
        this.device = null;
      }
      this.fetchHistories(newValue, this.device);
    },
    device(newValue, oldValue) {
      if (oldValue) {
        this.leaveChannels(this.checkpoint, oldValue);
      }
      this.fetchHistories(this.checkpoint, newValue);
    },
    perPage() {
      this.fetchHistories(this.checkpoint, this.device);
    },
    showOnlyWanted() {
      this.fetchHistories(this.checkpoint, this.device);
    },
  },
  methods: {
    fetchCheckpoints() {
      this.checkpointsLoading = true;
      checkpointsIndex({ perPage: -1 }).then(
        ({ data, meta }) => {
          if (data && meta) {
            this.checkpoints.splice(
              0,
              this.checkpoints.length,
              ...[{ id: null, name: i18n.t("all") }, ...data]
            );
            this.checkpointsLoading = false;
          }
        },
        () => {
          this.$toast.error(i18n.t("error_while_retrieving_checkpoints_list"));
          setTimeout(() => {
            this.fetchCheckpoints();
          }, 15000);
        }
      );
    },
    fetchDevices() {
      this.device = null;
      this.devicesLoading = true;
      devicesIndex({
        checkpoint: this.checkpoint ?? undefined,
        perPage: -1,
      }).then(
        ({ data, meta }) => {
          if (data && meta) {
            this.devices.splice(
              0,
              this.devices.length,
              ...[{ id: null, name: i18n.t("all") }, ...data]
            );
            this.devicesLoading = false;
          }
        },
        () => {
          this.$toast.error(i18n.t("error_while_retrieving_devices_list"));
          setTimeout(() => {
            this.fetchDevices();
          }, 15000);
        }
      );
    },
    fetchHistories(checkpoint = null, device = null) {
      this.historiesLoading = true;
      this.leaveChannels(checkpoint, device);
      historiesIndex({
        checkpoint: checkpoint ?? undefined,
        device: device ?? undefined,
        is_alarm: this.showOnlyWanted || undefined,
        page: 1,
        perPage: this.perPage,
      }).then(
        ({ data, meta }) => {
          if (data && meta) {
            this.histories.splice(0, this.histories.length, ...data);
            this.listenChannel(checkpoint, device);
            this.historiesLoading = false;
          }
        },
        () => {
          this.$toast.error(i18n.t("error_while_connecting_histories_live"));
          setTimeout(() => {
            this.fetchHistories(checkpoint, device);
          }, 15000);
        }
      );
    },
    leaveChannels(checkpoint = null, device = null) {
      this.$notificationConnection.leave(`live-checkpoint-${checkpoint}`);
      this.$notificationConnection.leave(`live-device-${device}`);
      this.$notificationConnection.leave("live");
    },
    listenChannel(checkpoint = null, device = null) {
      let channel = "";
      if (device) {
        channel = `live-device-${device}`;
      } else if (checkpoint) {
        channel = `live-checkpoint-${checkpoint}`;
      } else {
        channel = "live";
      }
      this.$notificationConnection
        .private(channel)
        .listen(".history.created", (e) => {
          if (!this.histories_.find((history) => history.id === e.history.id)) {
            if (this.histories_.length >= this.perPage) {
              this.histories.pop();
            }
            this.histories.unshift(e.history);
          }
        });
    },
  },
  beforeMount() {
    this.fetchCheckpoints();
    this.fetchHistories();
  },
  beforeDestroy() {
    this.leaveChannels(this.checkpoint, this.device);
  },
};
</script>
<style scoped></style>
