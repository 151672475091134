<template>
  <div v-viewer="options" :class="imgClass" :style="style">
    <div :style="`position: relative;width:${size}px;height:${size}px`">
      <v-avatar class="border-radius-lg shadow" color="primary" :size="size">
        <img
          v-if="src"
          :alt="alt"
          :class="imgClass"
          :data-src="src"
          :src="thumbnail_"
          :style="style"
          style="cursor: pointer"
        />
        <v-icon v-else dark> mdi-account-cancel </v-icon>
      </v-avatar>
      <div v-if="scanner" class="box"></div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseImage",
  props: {
    size: {
      type: Number,
      default: 96,
    },
    scanner: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
    },
    backdrop: {
      type: Boolean,
      default: true,
    },
    button: {
      type: Boolean,
      default: true,
    },
    fullscreen: {
      type: Boolean,
      default: true,
    },
    imgClass: {},
    keyboard: {
      type: Boolean,
      default: true,
    },
    movable: {
      type: Boolean,
      default: true,
    },
    navbar: {
      type: Boolean,
      default: false,
    },
    rotatable: {
      type: Boolean,
      default: true,
    },
    scalable: {
      type: Boolean,
      default: true,
    },
    src: {
      type: String,
    },
    title: {
      type: Boolean,
      default: false,
    },
    thumbnail: {
      type: String,
    },
    toolbar: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
    transition: {
      type: Boolean,
      default: true,
    },
    zoomable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      default: {
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: true,
          prev: false,
          play: false,
          next: false,
          rotateLeft: true,
          rotateRight: true,
          flipHorizontal: true,
          flipVertical: true,
        },
      },
    };
  },
  computed: {
    options() {
      return {
        backdrop: this.backdrop,
        button: this.button,
        fullscreen: this.fullscreen,
        keyboard: this.keyboard,
        movable: this.movable,
        navbar: this.navbar,
        rotatable: this.rotatable,
        scalable: this.scalable,
        title: this.title,
        toolbar: this.toolbar ? this.default.toolbar : false,
        tooltip: this.tooltip,
        transition: this.transition,
        url: "data-src",
        zoomable: this.zoomable,
      };
    },
    style() {
      return {
        maxHeight: this.size,
        maxWidth: this.size,
        textAlign: "-webkit-center",
        width: "100%",
        height: "100%",
      };
    },
    thumbnail_() {
      return this.thumbnail ?? this.src;
    },
  },
};
</script>

<style scoped>
.box {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto !important;
  position: absolute;
  background: linear-gradient(#03a9f4, #03a9f4),
    linear-gradient(90deg, #ffffff33 1px, transparent 0, transparent 19px),
    linear-gradient(#ffffff33 1px, transparent 0, transparent 19px),
    linear-gradient(transparent, #2196f3);
  background-size: 100% 1.5%, 10% 100%, 100% 10%, 100% 100%;
  background-repeat: no-repeat, repeat, repeat, repeat;
  background-position: 0 0, 0 0, 0 0, 0 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 1.5%, 0% 1.5%);
  animation: move 2s linear;
  border-radius: 4px;
  opacity: 0;
}

@keyframes move {
  from {
    opacity: 1;
  }
  to {
    background-position: 0 100%, 0 0, 0 0, 0 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    opacity: 0;
  }
}
</style>
