import Echo from "laravel-echo";

class NotificationConnection {
  constructor(toast, i18n) {
    this.key = process.env.VUE_APP_PUSHER_APP_KEY;
    this.wsHost = process.env.VUE_APP_PUSHER_HOST;
    this.wsPort = process.env.VUE_APP_PUSHER_PORT;
    this.wssPort = process.env.VUE_APP_PUSHER_PORT;
    this.authEndpoint = `${process.env.VUE_APP_API_URL}/api/broadcasting/auth`;
    this.state = "";
    this.stateHistory = [];
    this.handleStateHistory = false;
    this.toast = toast;
    this.i18n = i18n;
  }

  connect(token) {
    this.disconnect();

    if (!this.pusher) {
      this.pusher = window.Pusher = require("pusher-js");
    }

    if (!this.echo) {
      const { key, wsHost, wsPort, wssPort, authEndpoint } = this;

      this.echo = window.Echo = new Echo({
        broadcaster: "pusher",
        key,
        wsHost,
        wsPort,
        wssPort,
        forceTLS: false,
        encrypted: true,
        disableStats: true,
        withoutInterceptors: true,
        enabledTransports: ["ws", "wss"],
        authEndpoint,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    }

    this.bindConnectionEvents();
  }

  channel(channel) {
    return this.echo?.channel(channel);
  }

  private(channel) {
    return this.echo?.private(channel);
  }

  leave(channel) {
    this.echo?.leave(channel);
  }

  disconnect() {
    this.handleStateHistory = false;

    if (this.echo) {
      this.echo?.connector?.pusher.disconnect();
      this.echo.disconnect();
      delete this.echo;
      delete window.Echo;
    }
    if (this.pusher) {
      delete this.pusher;
      delete window.Pusher;
    }

    this.stateHistory.splice(0, this.stateHistory.length);
  }

  bindConnectionEvents() {
    this.handleStateHistory = true;
    this.setState(this.echo?.connector?.pusher?.connection?.state);

    this.echo?.connector?.pusher?.connection?.bind("connecting", () =>
      this.setState("connecting")
    );
    this.echo?.connector?.pusher?.connection?.bind("connected", () =>
      this.setState("connected")
    );
    this.echo?.connector?.pusher?.connection?.bind("unavailable", () =>
      this.setState("unavailable")
    );
    this.echo?.connector?.pusher?.connection?.bind("failed", () =>
      this.setState("failed")
    );
    this.echo?.connector?.pusher?.connection?.bind("disconnected", () =>
      this.setState("disconnected")
    );
  }

  /**
   * initialized:  Başlangıç hali. Bu durumda hiçbir olay yayınlanmaz.
   *
   * connecting:   Tüm bağımlılıklar yüklendi ve Kanallar bağlanmaya çalışıyor.
   *               Bağlantı, bir bağlantı hatasından sonra yeniden bağlanmaya
   *               çalışırken de bu duruma girecektir.
   *
   * connected:    Kanallar bağlantısı açık ve uygulamanızla kimlik doğrulaması yapıldı.
   *
   * unavailable:  Bağlantı geçici olarak kullanılamıyor. Çoğu durumda bu,
   *               internet bağlantısı olmadığı anlamına gelir. Ayrıca,
   *               kanalların kapalı olduğu veya bazı aracıların bağlantıyı
   *               engellediği anlamına da gelebilir. Bu durumda, pusher-js her
   *               15 saniyede bir bağlantıyı otomatik olarak yeniden deneyecektir.
   *
   * failed:       Kanallar tarayıcı tarafından desteklenmiyor. Bu, WebSockets'in
   *               yerel olarak mevcut olmadığı ve HTTP tabanlı bir aktarımın
   *               bulunamadığı anlamına gelir.
   *
   * disconnected: Kanallar bağlantısı önceden bağlandı ve şimdi kasıtlı olarak kapatıldı.
   *
   * @param state
   */
  setState(state) {
    this.state = state;
    if (this.handleStateHistory) {
      if (this.stateHistory.length >= 3) {
        this.stateHistory.splice(0, this.stateHistory.length - 2);
      }
      this.stateHistory.push(state);
      this.checkStateHistory();
    }
  }

  checkStateHistory() {
    const normalStateHistory = ["initialized", "connecting", "connected"];
    const temporaryFailureHistory = ["connecting", "connected", "connecting"];
    const connectionLoseHistory = ["unavailable"];
    const reconnectedHistory = ["unavailable", "connected"];
    const reconnectedHistory2 = ["unavailable", "connecting", "connected"];
    const connectionNotSupportedHistory = ["failed"];

    // Herşey olması gerektiği gibi
    if (this.isStateHistoryEqualTo(normalStateHistory)) {
      // Herhangi bir şey yapmaya gerek yok.
    }
    // Kanallar bağlantısının geçici olarak başarısız olması.
    else if (this.isStateHistoryEqualTo(temporaryFailureHistory)) {
      this.toast.error(this.i18n.t("notification_service_disconnected"));
    }
    // İnternet bağlantısı koparsa (son durumdan ~30 saniye sonra)
    else if (this.isStateHistoryEqualTo(connectionLoseHistory)) {
      this.toast.error(this.i18n.t("notification_service_unavailable"));
    }
    // İnternet bağlantısı tekrar kullanılabilir olduğunda
    else if (
      this.isStateHistoryEqualTo(reconnectedHistory) ||
      this.isStateHistoryEqualTo(reconnectedHistory2)
    ) {
      this.toast.success(this.i18n.t("notification_service_connected"));
    }
    // Kanalların desteklenmemesi durumu
    else if (this.isStateHistoryEqualTo(connectionNotSupportedHistory)) {
      this.toast.error(this.i18n.t("notification_service_failed"));
    }
  }

  isStateHistoryEqualTo(that) {
    const sliced = this.stateHistory.slice(-that.length);
    if (sliced.length < that.length) {
      return false;
    }
    return sliced.every((value, index) => value === that[index]);
  }
}

export default {
  install(Vue) {
    Vue.prototype.$notificationConnection = new NotificationConnection(
      Vue.$toast,
      Vue.i18n
    );
  },
};
