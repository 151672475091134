<template>
  <div>
    <v-subheader v-if="item.divider" class="main-menu-title">
      {{ item.divider }}
    </v-subheader>
    <v-list-group
      :sub-group="subGroup"
      no-action
      v-model="listModel"
      flat
      color="no-color"
      :active-class="isDarkMode ? 'grey--text text--lighten-5' : undefined"
      class="base-item-group"
      v-ripple="{ class: isDarkMode ? 'grey--text' : undefined }"
    >
      <template v-if="item.icon" v-slot:prependIcon>
        <v-icon
          :style="{ color: isDarkMode ? undefined : 'rgba(0, 0, 0, 0.54)' }"
        >
          {{ item.icon }}
        </v-icon>
      </template>
      <template v-slot:activator>
        <v-list-item-icon
          v-if="text"
          class="v-list-item__icon--text"
          v-text="computedText"
        />
        <v-list-item-content>
          <v-list-item-title
            class="text-14 vertical-sidebar-text"
            v-text="item.title"
          />
        </v-list-item-content>
      </template>
      <template v-for="(child, i) in children">
        <base-item-sub-group
          v-if="child.children"
          :key="`sub-group-${i}`"
          :item="child"
          class="pl-0"
        />
        <base-item
          v-else-if="$auth.check(child.auth)"
          :key="`item-${i}`"
          :item="child"
          :text="false"
        />
      </template>
    </v-list-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseItem from "./Item.vue";
import BaseItemSubGroup from "./ItemSubGroup.vue";

export default {
  name: "BaseItemGroup",
  components: { BaseItem, BaseItemSubGroup },
  inheritAttrs: false,
  data() {
    return {
      listModel: 0,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        divider: undefined,
        children: [],
        group: undefined,
        subHeader: undefined,
        title: undefined,
      }),
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getTheme", "isDarkMode"]),
    children() {
      return this.item.children;
    },
    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application--is-ltr {
  .base-item-group ::v-deep {
    .v-list-group__header__prepend-icon {
      margin-right: 1rem !important;
    }
  }
}

.base-item-group ::v-deep {
  .v-list-item__icon {
    min-width: 0;
  }
}

.main-menu-title {
  text-transform: uppercase;
  font-size: 12px;
  padding: 16px 0 16px 16px;
  display: block;
  font-weight: 500;
  letter-spacing: 0.5px;
}
</style>
