import { axios } from "@/plugins/axios";

export async function index(query) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/web/roles", { params: query })
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function store(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/web/roles", payload)
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function update(id, payload) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/web/roles/${id}`, payload)
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function destroy(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/web/roles/${id}`)
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}
