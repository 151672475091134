<template>
  <base-data-table
    ref="checkpointsTable"
    :headers="checkpointsTable.headers"
    :items="checkpointsTable.items"
    :options="checkpointsTable.options"
    :fixed-header="true"
    class="d-flex flex-column"
    style="max-height: 100%"
    :loading="checkpointsTable.loading"
    @loadData="handleLoadData"
  >
    <template v-slot:toolbar-left>
      <v-toolbar-title
        class="d-flex flex-nowrap align-items-center"
        style="gap: 0.5rem"
      >
        <v-icon>mdi-monitor-dashboard</v-icon>
        <span>{{ $t("checkpoints") }}</span>
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-right>
      <v-btn
        :color="isDarkMode ? 'grey lighten-5' : 'info'"
        depressed
        outlined
        small
        @click="showFormDialog(emptyCheckpoint)"
        :disabled="!$auth.check('kontrol-noktalarini-duzenleyebilir')"
      >
        <v-icon small class="mr-1">mdi-monitor-dashboard</v-icon>
        {{ $t("create_checkpoint_button") }}
      </v-btn>
      <checkpoint-api-key-dialog
        v-if="$auth.check('api-key-olusturabilir')"
        :checkpoint="apiKeyDialogCheckpoint"
        :show.sync="apiKeyDialog"
      />
      <checkpoint-form-dialog
        v-if="$auth.check('kontrol-noktalarini-duzenleyebilir')"
        :checkpoint.sync="formDialogCheckpoint"
        :show.sync="formDialog"
        @stored="handleSaved"
        @updated="handleSaved"
      />
      <checkpoint-delete-dialog
        v-if="$auth.check('kontrol-noktalarini-duzenleyebilir')"
        :checkpoint="deleteDialogCheckpoint"
        :show.sync="deleteDialog"
        @deleted="handleDeleted"
      />
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ $d(new Date(item.created_at * 1000), "full", "tr") }}
    </template>
    <template v-slot:[`item.updated_at`]="{ item }">
      {{ $d(new Date(item.updated_at * 1000), "full", "tr") }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn-toggle active-class="no-active">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="showApiKeyDialog(item)"
              :disabled="!$auth.check('api-key-olusturabilir')"
            >
              <v-icon small>mdi-key-variant</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("generate_api_key") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="showFormDialog(item)"
              :disabled="!$auth.check('kontrol-noktalarini-duzenleyebilir')"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="showDeleteDialog(item)"
              :disabled="!$auth.check('kontrol-noktalarini-duzenleyebilir')"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("remove_checkpoint") }}</span>
        </v-tooltip>
      </v-btn-toggle>
    </template>
  </base-data-table>
</template>

<script>
import Vue from "vue";
import { index } from "@/api/checkpoints.api";
import { mapGetters } from "vuex";

import BaseDataTable from "@/components/base/DataTable/DataTable.vue";
import CheckpointApiKeyDialog from "./CheckpointApiKeyDialog.vue";
import CheckpointDeleteDialog from "./CheckpointDeleteDialog.vue";
import CheckpointFormDialog from "./CheckpointFormDialog.vue";

const { i18n } = Vue;

export default {
  name: "CheckpointsPage",
  components: {
    BaseDataTable,
    CheckpointApiKeyDialog,
    CheckpointDeleteDialog,
    CheckpointFormDialog,
  },

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: i18n.t("checkpoints"),
  },

  data: () => ({
    checkpointsTable: {
      headers: [
        {
          text: i18n.t("checkpoint_name"),
          value: "name",
          align: "start",
          sortable: true,
          filterable: true,
        },
        {
          text: i18n.t("description"),
          value: "description",
          sortable: true,
          filterable: true,
        },
        {
          text: i18n.t("created_at"),
          value: "created_at",
          sortable: true,
          filterable: false,
        },
        {
          text: i18n.t("updated_at"),
          value: "updated_at",
          sortable: true,
          filterable: false,
        },
        {
          text: "#",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ],
      items: [],
      loading: false,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
    },
    apiKeyDialog: false,
    deleteDialog: false,
    formDialog: false,
    emptyCheckpoint: {
      name: "",
      description: "",
    },
    apiKeyDialogCheckpoint: {
      name: "",
      description: "",
    },
    deleteDialogCheckpoint: {
      name: "",
      description: "",
    },
    formDialogCheckpoint: {
      name: "",
      description: "",
    },
  }),

  computed: {
    ...mapGetters(["isDarkMode"]),
  },

  methods: {
    handleDeleted() {
      this.$refs.checkpointsTable.refresh();
    },

    handleLoadData(filter, dataLoaded, dataLoadFailed) {
      this.checkpointsTable.loading = true;
      index(filter)
        .then(
          ({ data, meta }) => {
            if (data && meta) {
              this.checkpointsTable.items.splice(
                0,
                this.checkpointsTable.items.length,
                ...data
              );
              dataLoaded(meta);
            }
          },
          () => {
            this.$toast.error(
              this.$t("error_while_retrieving_checkpoints_list")
            );
            dataLoadFailed();
          }
        )
        .finally(() => {
          this.checkpointsTable.loading = false;
        });
    },

    handleSaved() {
      this.$refs.checkpointsTable.refresh();
    },

    showApiKeyDialog(checkpoint) {
      this.apiKeyDialog = true;
      this.$nextTick(() => {
        this.apiKeyDialogCheckpoint = Object.assign({}, checkpoint);
      });
    },

    showDeleteDialog(checkpoint) {
      this.deleteDialog = true;
      this.$nextTick(() => {
        this.deleteDialogCheckpoint = Object.assign({}, checkpoint);
      });
    },

    showFormDialog(checkpoint) {
      this.formDialog = true;
      this.$nextTick(() => {
        this.formDialogCheckpoint = Object.assign({}, checkpoint);
      });
    },
  },
};
</script>
