<template>
  <div class="page-wrap">
    <div class="ma-auto">
      <div class="d-flex align-center justify-center mb-4">
        <v-avatar tile size="120" class="mr-4">
          <img src="@/assets/images/warning.svg" alt="" />
        </v-avatar>
        <div class="error-title">
          <h1 class="font-weight-bold mb-0">403</h1>
          <div class="text-18 font-weight-bold">
            {{ $t("page_forbidden") }}!
          </div>
        </div>
      </div>
      <div class="d-flex justify-center flex-wrap" v-show="$auth.ready()">
        <v-btn small class="ma-2" outlined color="primary" to="/">{{
          $t("return_to_home_page")
        }}</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageForbidden",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Error",
  },
};
</script>
<style lang="scss" scoped>
.page-wrap ::v-deep {
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;

  .error-title {
    h1 {
      font-size: 5rem;
    }
  }
}
</style>
