<template>
  <div class="settings-page">
    <v-toolbar class="elevation-0 w-full" rounded>
      <div class="d-flex align-center w-full" style="gap: 12px">
        <v-toolbar-title
          class="d-flex flex-nowrap align-items-center"
          style="gap: 0.5rem; width: min-content"
        >
          <v-icon>mdi-cog</v-icon>
          <span>{{ $t("settings") }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          color="success"
          depressed
          :disabled="
            loading || saving || !$auth.check('ayarlari-duzenleyebilir')
          "
          :loading="loading || saving"
          small
          @click="submit"
        >
          <v-icon class="mr-1" small>mdi-floppy</v-icon>
          {{ $t("save") }}
        </v-btn>
      </div>
    </v-toolbar>
    <v-form ref="form" v-model="form.valid" class="form" lazy-validation>
      <v-card
        class="d-flex flex-column elevation-0 pa-4"
        style="max-width: 100%; width: 32rem"
      >
        <div class="d-flex flex-nowrap align-center mb-6 w-full">
          <v-label>{{ $t("ai_settings") }}</v-label>
          <v-divider class="ml-3" />
        </div>
        <akn-switch
          v-model="settings.face_search_use_only_wanted_people_collection"
          :label="$t('face_search_use_only_wanted_people_collection')"
        />
        <akn-text-field
          v-model="settings.distance_factor"
          :label="$t('distance_factor')"
          :rules="form.distance_factor.rules"
          :error-messages="form.distance_factor.errors"
          :disabled="loading || saving"
          @input="fixNumber('distance_factor')"
          type="number"
          step="0.1"
        />
        <akn-text-field
          v-model="settings.distance_threshold"
          :label="$t('distance_threshold')"
          :rules="form.distance_threshold.rules"
          :error-messages="form.distance_threshold.errors"
          :disabled="loading || saving"
          @input="fixNumber('distance_threshold')"
          type="number"
          step="0.1"
        />
        <akn-text-field
          v-model="settings.similar_people_distance_threshold"
          :label="$t('similar_people_distance_threshold')"
          :rules="form.similar_people_distance_threshold.rules"
          :error-messages="form.similar_people_distance_threshold.errors"
          :disabled="loading || saving"
          @input="fixNumber('similar_people_distance_threshold')"
          type="number"
          step="0.01"
        />
        <akn-text-field
          v-model="settings.face_search_topk"
          :label="$t('face_search_topk')"
          :rules="form.face_search_topk.rules"
          :error-messages="form.face_search_topk.errors"
          :disabled="loading || saving"
          @input="fixNumber('face_search_topk')"
          type="number"
          step="1"
        />
        <akn-text-field
          v-model="settings.encoding_threshold"
          :label="$t('encoding_threshold')"
          :rules="form.encoding_threshold.rules"
          :error-messages="form.encoding_threshold.errors"
          :disabled="loading || saving"
          @input="fixNumber('encoding_threshold')"
          type="number"
          step="0.01"
        />
        <akn-text-field
          v-model="settings.encoding_score"
          :label="$t('encoding_score')"
          :rules="form.encoding_score.rules"
          :error-messages="form.encoding_score.errors"
          :disabled="loading || saving"
          @input="fixNumber('encoding_score')"
          type="number"
          step="0.01"
        />
        <div class="d-flex flex-wrap" style="gap: 0 1rem">
          <akn-text-field
            v-model="settings.head_pose_yaw_min"
            :label="$t('head_pose_yaw_min')"
            :rules="form.head_pose_yaw_min.rules"
            :error-messages="form.head_pose_yaw_min.errors"
            :disabled="loading || saving"
            @input="fixNumber('head_pose_yaw_min')"
            type="number"
            step="0.01"
            style="max-width: 100%; width: 14rem"
          />
          <akn-text-field
            v-model="settings.head_pose_yaw_max"
            :label="$t('head_pose_yaw_max')"
            :rules="form.head_pose_yaw_max.rules"
            :error-messages="form.head_pose_yaw_max.errors"
            :disabled="loading || saving"
            @input="fixNumber('head_pose_yaw_max')"
            type="number"
            step="0.01"
            style="max-width: 100%; width: 14rem"
          />
        </div>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import Vue from "vue";
import { index, store } from "@/api/settings.api";

import AknSwitch from "@/components/AknSwitch.vue";
import AknTextField from "@/components/AknTextField.vue";

const { i18n } = Vue;

const isInt = function (value) {
  return !Number.isNaN(value) && Number(value) % 1 === 0;
};
const isNumeric = function (value) {
  return !Number.isNaN(value) && Number(value) % 1 !== 0;
};

export default {
  name: "SettingsPage",
  components: { AknSwitch, AknTextField },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: i18n.t("settings"),
  },
  data: () => ({
    form: {
      distance_factor: {
        errors: [],
        rules: [
          (v) => {
            if (isInt(v) || isNumeric(v)) return true;
            return i18n.t("x_must_be_numeric", {
              x: i18n.t("distance_factor"),
            });
          },
        ],
      },
      distance_threshold: {
        errors: [],
        rules: [
          (v) => {
            if (isInt(v) || isNumeric(v)) return true;
            return i18n.t("x_must_be_numeric", {
              x: i18n.t("distance_threshold"),
            });
          },
        ],
      },
      similar_people_distance_threshold: {
        errors: [],
        rules: [
          (v) => {
            if (isInt(v) || isNumeric(v)) return true;
            return i18n.t("x_must_be_numeric", {
              x: i18n.t("similar_people_distance_threshold"),
            });
          },
        ],
      },
      face_search_topk: {
        errors: [],
        rules: [
          (v) => {
            if (isInt(v)) return true;
            return i18n.t("x_must_be_integer", {
              x: i18n.t("face_search_topk"),
            });
          },
        ],
      },
      encoding_threshold: {
        errors: [],
        rules: [
          (v) => {
            if (isInt(v) || isNumeric(v)) return true;
            return i18n.t("x_must_be_numeric", {
              x: i18n.t("encoding_threshold"),
            });
          },
        ],
      },
      encoding_score: {
        errors: [],
        rules: [
          (v) => {
            if (isInt(v) || isNumeric(v)) return true;
            return i18n.t("x_must_be_numeric", {
              x: i18n.t("encoding_score"),
            });
          },
        ],
      },
      head_pose_yaw_min: {
        errors: [],
        rules: [
          (v) => {
            if (isInt(v) || isNumeric(v)) return true;
            return i18n.t("x_must_be_numeric", {
              x: i18n.t("head_pose_yaw_min"),
            });
          },
        ],
      },
      head_pose_yaw_max: {
        errors: [],
        rules: [
          (v) => {
            if (isInt(v) || isNumeric(v)) return true;
            return i18n.t("x_must_be_numeric", {
              x: i18n.t("head_pose_yaw_max"),
            });
          },
        ],
      },
      valid: false,
    },
    loading: false,
    saving: false,
    settings: {},
  }),
  methods: {
    fetchSettings() {
      this.loading = true;
      index().then(
        ({ data }) => {
          this.settings = data;
          this.loading = false;
        },
        () => {
          this.$toast.error(i18n.t("error_while_retrieving_settings"));
          setTimeout(() => {
            this.fetchSettings();
          }, 15000);
        }
      );
    },
    fixNumber(field) {
      this.$nextTick(() => {
        this.settings[field] = Number(this.settings[field]);
      });
    },
    async submit() {
      if (this.$auth.check("ayarlari-duzenleyebilir")) {
        await this.$refs.form.validate();
        if (this.form.valid) {
          this.saving = true;
          store(this.settings)
            .then(
              ({ data }) => {
                this.settings = data;
                this.$toast.success(this.$t("settings_successfully_saved"));
              },
              (error) => {
                const { data, status } = error.response ?? {};
                if (status === 401) {
                  this.$toast.error(data?.detail);
                } else if (status === 422) {
                  for (const [key, value] of Object.entries(
                    data?.errors ?? []
                  )) {
                    if (this.form[key]) {
                      this.form[key].errors = value;
                    }
                  }
                  if (data.message) {
                    this.$toast.error(data.message);
                  }
                }
              }
            )
            .finally(() => {
              this.saving = false;
            });
        }
      }
    },
  },
  beforeMount() {
    this.fetchSettings();
  },
};
</script>

<style lang="scss" scoped>
.settings-page ::v-deep {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 100%;
  overflow: hidden;

  .form {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 12px;
    height: 100%;
    overflow: auto;
    width: 100%;
  }
}
</style>
