<template>
  <div>
    <v-card-text class="py-0">
      <v-row align="center" justify="center" class="fill-height">
        <v-col :cols="similarity !== null ? 4 : 12" class="text-center">
          <div :style="`height: ${height}px`">
            <base-image
              scanner
              :size="height"
              :src="source"
              img-class="mx-auto rounded"
              max-height="100%"
              max-width="100%"
            />
          </div>

          <v-tooltip max-width="256px" top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                outlined
                label
                x-small
                class="my-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ similarity !== null ? $t("source") : $t("no_match_found") }}
              </v-chip>
            </template>
            <span class="text-capitalize">
              {{ similarity !== null ? $t("source") : $t("no_match_found") }}
            </span>
          </v-tooltip>
        </v-col>

        <v-col cols="4" class="progress-col" v-if="similarity !== null">
          <div
            class="text-center"
            :style="`height: ${height}px;padding-top:8px;`"
          >
            <vue-ellipse-progress
              :progress="Math.round(similarity)"
              color="#7579ff"
              empty-color="#324c7e"
              :size="height * 0.8"
              :thickness="thickness"
              :emptyThickness="emptyThickness"
              :lineMode="lineMode"
              animation="default 1200 100"
              fontSize="1rem"
              font-color="white"
              :dot="dot"
            />
          </div>
          <v-tooltip max-width="256px" top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                outlined
                label
                x-small
                class="my-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ similarityText }}</v-chip
              >
            </template>
            <span class="text-capitalize">
              {{ similarityText }}
            </span>
          </v-tooltip>
        </v-col>

        <v-col cols="4" v-if="similarity !== null" class="text-center">
          <div :style="`height: ${height}px`">
            <base-image
              scanner
              :size="height"
              :src="target"
              img-class="mx-auto rounded"
              max-height="100%"
              max-width="100%"
            />
          </div>
          <v-tooltip max-width="256px" top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                outlined
                label
                x-small
                class="my-2 text-capitalize"
                v-bind="attrs"
                v-on="on"
              >
                {{ personFullName ?? $t("matching") }}</v-chip
              >
            </template>
            <span class="text-capitalize">
              {{ personFullName ?? $t("matching") }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { VueEllipseProgress } from "vue-ellipse-progress";

export default {
  name: "PersonPhotoMatch",
  components: {
    BaseImage: () => import("@/components/base/Image.vue"),
    VueEllipseProgress,
  },
  data() {
    return {
      lineModes: [
        "normal",
        "in",
        "in-over",
        "out",
        "out-over",
        "top",
        "bottom",
      ],
      thickness: 8,
      emptyThickness: 8,
      dot: { size: 5, width: "5px" },
      lineMode: "normal 0",
    };
  },
  props: {
    height: {
      type: Number,
      default: 48,
    },
    personFullName: {
      type: String,
      default: null,
    },
    similarity: {
      type: Number,
      default: null,
    },
    source: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
  },
  computed: {
    similarityText() {
      if (this.similarity < 70) {
        return this.$t("similarity_out");
      } else if (this.similarity < 80) {
        return this.$t("similarity_low");
      } else if (this.similarity < 88) {
        return this.$t("similarity_middle");
      } else if (this.similarity < 91) {
        return this.$t("similarity_high");
      } else if (this.similarity <= 100) {
        return this.$t("similarity_so_high");
      }
      return this.$t("similarity_out");
    },
  },
};
</script>
<style scoped>
.progress-col {
  align-items: center;
  place-content: center;
}
/deep/.ep-legend--value__counter {
  color: #7579ff;
}
</style>
