<template>
  <div class="login-page">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
          :active="loggingIn"
          :indeterminate="loggingIn"
          absolute
          top
          color="primary"
        />
        <v-card-text class="text-center">
          <div
            class="d-flex flex-nowrap align-center justify-center mb-6"
            style="gap: 0.5rem"
          >
            <v-avatar>
              <v-img :src="isDarkMode ? logoDark : logoLight" eager />
            </v-avatar>
            <h4 class="font-weight-bold mb-0 text-uppercase">
              {{ $t("app_name") }}
            </h4>
          </div>
          <v-form ref="form" v-model="form.valid" lazy-validation>
            <akn-text-field
              v-model="form.username.value"
              :label="$t('username')"
              :rules="form.username.rules"
              :error-messages="form.username.errors"
              persistent-placeholder
            />
            <akn-text-field
              v-model="form.password.value"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              :label="$t('password')"
              :rules="form.password.rules"
              :error-messages="form.password.errors"
              persistent-placeholder
              @click:append="show = !show"
            />
            <v-switch
              v-model="rememberMe"
              :label="$t('remember_me')"
              class="mt-0"
              dense
              inset
            />
            <v-btn
              block
              color="primary"
              depressed
              :disabled="loggingIn || !form.valid"
              :loading="loggingIn"
              @click="formSubmit"
            >
              <v-icon left>mdi-login-variant</v-icon>
              {{ $t("LOGIN") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/lang";

import AknTextField from "@/components/AknTextField.vue";
import BaseCard from "@/components/base/Card.vue";

export default {
  name: "LoginPage",
  components: { AknTextField, BaseCard },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: i18n.t("login"),
  },
  data() {
    return {
      show: false,
      rememberMe: true,
      form: {
        username: {
          value: "",
          errors: [],
          rules: [
            (v) => !!v || i18n.t("x_is_required", { x: i18n.t("username") }),
            (v) =>
              v.length > 2 ||
              i18n.t("x_must_be_least_y_characters", {
                x: i18n.t("username"),
                y: 3,
              }),
            (v) =>
              v.length < 101 ||
              i18n.t("x_must_be_maximum_y_characters", {
                x: i18n.t("username"),
                y: 100,
              }),
          ],
        },
        password: {
          value: "",
          errors: [],
          rules: [
            (v) => !!v || i18n.t("x_is_required", { x: i18n.t("password") }),
            (v) =>
              v.length > 7 ||
              i18n.t("x_must_be_least_y_characters", {
                x: i18n.t("password"),
                y: 8,
              }),
            (v) =>
              v.length < 101 ||
              i18n.t("x_must_be_maximum_y_characters", {
                x: i18n.t("password"),
                y: 100,
              }),
          ],
        },
        valid: false,
      },
      loggingIn: false,
      logoDark: require("@/assets/images/logo_dark_transparent.png"),
      logoLight: require("@/assets/images/logo_light_transparent.png"),
    };
  },
  computed: {
    ...mapGetters(["user", "isDarkMode"]),
  },
  watch: {
    "form.username.value": function () {
      this.form.username.errors = [];
    },
    "form.password.value": function () {
      this.form.password.errors = [];
    },
  },
  methods: {
    async formSubmit() {
      await this.$refs.form.validate();
      if (this.form.valid) {
        this.loggingIn = true;
        this.$auth
          .login({
            data: {
              username: this.form.username.value,
              password: this.form.password.value,
            },
            staySignedIn: this.rememberMe,
          })
          .then(
            () => {
              this.$toast.success(i18n.t("welcome_x", { x: this.user.name }));
              this.$router.push("/");
            },
            (error) => {
              const { data, status } = error.response || {};
              if (status === 401) {
                this.$toast.error(data?.detail);
              } else if (status === 422) {
                for (const [key, value] of Object.entries(data?.errors || [])) {
                  if (this.form[key]) {
                    this.form[key].errors = value;
                  }
                }
                if (data.message) {
                  this.$toast.error(data.message);
                }
              }
            }
          )
          .finally(() => {
            this.loggingIn = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light {
  .login-page ::v-deep {
    background-color: #f2f3f8;
  }
}

.login-page ::v-deep {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 100%;
  min-height: 100vh;

  .session-form-hold {
    width: 100%;
    max-width: 400px;
  }
}
</style>
