<template>
  <div>
    <v-container class="d-flex p-0 pt-0">
      <v-row>
        <v-col xl="6" lg="6" sm="12" md="12" class="pl-0">
          <v-card class="card-shadow">
            <v-card-title class="py-3">
              <div class="d-flex justify-space-between w-full">
                <div>
                  {{ $t("person_info") }}
                </div>
                <div>
                  <v-btn
                    color="success"
                    depressed
                    small
                    outlined
                    @click="
                      $router.push({
                        name: 'person-edit',
                        params: { id: personId },
                      })
                    "
                    :disabled="!$auth.check('sahislari-duzenleyebilir')"
                  >
                    <v-icon class="mr-1" small>mdi-pencil</v-icon>
                    {{ $t("edit") }}
                  </v-btn>
                </div>
              </div>
            </v-card-title>
            <hr class="horizontal rounded-hr" />
            <v-card-text>
              <person-detail :person="person" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col xl="6" lg="6" sm="12" md="12" class="pl-0 pr-0">
          <v-card class="card-shadow">
            <v-card-title class="py-3">
              <div>
                {{ $t("histories") }}
              </div>
            </v-card-title>
            <hr class="horizontal rounded-hr" />
            <div>
              <history-person-timeline :person-id="personId" />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import { validate as uuidValidate } from "uuid";
import { show } from "@/api/people.api";

const { i18n } = Vue;

export default {
  name: "PersonDetailPage",
  components: {
    HistoryPersonTimeline: () =>
      import("@/views/pages/histories/HistoryPersonTimeline.vue"),
    PersonDetail: () => import("./PersonDetail.vue"),
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: i18n.t("person_detail"),
  },
  data: () => ({
    person: {
      full_name: null,
      national_number: null,
      passport_number: null,
      gender: null,
      name_of_father: null,
      name_of_mother: null,
      birthday: null,
      date_of_death: null,
      marital_status: null,
      is_wanted: null,
      cause_of_wanted: null,
      photo: {},
      created_at: 0,
      updated_at: 0,
    },
    tab: null,
  }),
  computed: {
    personId() {
      return this.$route.params.id;
    },
  },
  methods: {
    goToPeoplePage() {
      this.$router.push({ name: "people" });
    },
  },
  async beforeMount() {
    if (!uuidValidate(this.personId)) {
      this.$toast.error(this.$t("person_not_found"));
      return this.$router.replace({ name: "people" });
    }
    await show(this.personId).then(
      ({ data }) => {
        this.person = data;
        return data;
      },
      (error) => {
        if (error?.response?.status === 404) {
          this.$toast.error(this.$t("person_not_found"));
        } else {
          this.$toast.error(this.$t("error_while_retrieving_person_info"));
        }
        return this.$router.replace({ name: "people" });
      }
    );
  },
};
</script>

<style lang="scss"></style>
