<template>
  <base-data-table
    ref="devicesTable"
    :headers="devicesTable.headers"
    :items="devicesTable.items"
    :fixed-header="true"
    class="d-flex flex-column"
    style="max-height: 100%"
    :loading="devicesTable.loading"
    @loadData="handleLoadData"
  >
    <template v-slot:toolbar-left>
      <v-toolbar-title
        class="d-flex flex-nowrap align-items-center"
        style="gap: 0.5rem"
      >
        <v-icon>mdi-cctv</v-icon>
        <span>{{ $t("devices") }}</span>
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-right>
      <v-btn
        :color="isDarkMode ? 'grey lighten-5' : 'info'"
        outlined
        depressed
        small
        @click="showFormDialog(emptyDevice)"
        :disabled="!$auth.check('cihazlari-duzenleyebilir')"
      >
        <v-icon small class="mr-1">mdi-cctv</v-icon>
        {{ $t("create_device_button") }}
      </v-btn>
      <device-form-dialog
        v-if="$auth.check('cihazlari-duzenleyebilir')"
        :device.sync="formDialogDevice"
        :show.sync="formDialog"
        @stored="handleSaved"
        @updated="handleSaved"
      />
      <device-delete-dialog
        v-if="$auth.check('cihazlari-duzenleyebilir')"
        :device="deleteDialogDevice"
        :show.sync="deleteDialog"
        @deleted="handleDeleted"
      />
    </template>
    <template v-slot:[`item.checkpoints`]="{ item }">
      <span v-for="checkpoint in item.checkpoints" :key="checkpoint.id">
        {{ checkpoint.name }}
      </span>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ $d(new Date(item.created_at * 1000), "full", "tr") }}
    </template>
    <template v-slot:[`item.updated_at`]="{ item }">
      {{ $d(new Date(item.updated_at * 1000), "full", "tr") }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn-toggle active-class="no-active">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="showFormDialog(item)"
              :disabled="!$auth.check('cihazlari-duzenleyebilir')"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="showDeleteDialog(item)"
              :disabled="!$auth.check('cihazlari-duzenleyebilir')"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("remove_device") }}</span>
        </v-tooltip>
      </v-btn-toggle>
    </template>
  </base-data-table>
</template>

<script>
import Vue from "vue";
import { index } from "@/api/devices.api";
import { mapGetters } from "vuex";

import BaseDataTable from "@/components/base/DataTable/DataTable.vue";
import DeviceDeleteDialog from "./DeviceDeleteDialog.vue";
import DeviceFormDialog from "./DeviceFormDialog.vue";

const { i18n } = Vue;

export default {
  name: "DevicesPage",
  components: { BaseDataTable, DeviceDeleteDialog, DeviceFormDialog },

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: i18n.t("devices"),
  },

  data: () => ({
    devicesTable: {
      headers: [
        {
          text: i18n.t("device_name"),
          value: "name",
          align: "start",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("device_sn"),
          value: "device_sn",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("device_type"),
          value: "type",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("description"),
          value: "description",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("checkpoint"),
          value: "checkpoints",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("created_at"),
          value: "created_at",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("updated_at"),
          value: "updated_at",
          sortable: false,
          filterable: false,
        },
        {
          text: "#",
          value: "actions",
          sortable: false,
          filterable: false,
          cellClass: "pl-0",
        },
      ],
      items: [],
      loading: false,
    },
    deleteDialog: false,
    formDialog: false,
    emptyDevice: {
      name: "",
      description: "",
      device_sn: "",
      type: "bullet",
      checkpoint_id: "",
    },
    deleteDialogDevice: {
      name: "",
      description: "",
      device_sn: "",
      type: "bullet",
      checkpoint_id: "",
    },
    formDialogDevice: {
      name: "",
      description: "",
      device_sn: "",
      type: "bullet",
      checkpoint_id: "",
    },
  }),

  computed: {
    ...mapGetters(["isDarkMode"]),
  },

  methods: {
    handleDeleted() {
      this.$refs.devicesTable.refresh();
    },

    handleLoadData(filter, dataLoaded, dataLoadFailed) {
      this.devicesTable.loading = true;
      index(filter)
        .then(
          ({ data, meta }) => {
            if (data && meta) {
              this.devicesTable.items.splice(
                0,
                this.devicesTable.items.length,
                ...data
              );
              dataLoaded(meta);
            }
          },
          () => dataLoadFailed()
        )
        .finally(() => {
          this.devicesTable.loading = false;
        });
    },

    handleSaved() {
      this.$refs.devicesTable.refresh();
    },

    showDeleteDialog(device) {
      this.deleteDialog = true;
      this.$nextTick(() => {
        this.deleteDialogDevice = Object.assign({}, device);
      });
    },

    showFormDialog(device) {
      this.formDialog = true;
      this.$nextTick(() => {
        this.formDialogDevice = Object.assign(
          {},
          {
            ...device,
            checkpoint_id: device.checkpoints?.length
              ? device.checkpoints[0].id
              : undefined,
          }
        );
      });
    },
  },
};
</script>
