<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("app_name"),
      // all titles will be injected into this template
      titleTemplate: `%s | ${this.$t("app_name")}`,
    };
  },
  data() {
    return {
      mediaQueryList: undefined,
    };
  },
  methods: {
    ...mapActions(["changeDarkMode"]),
  },
  created() {
    this.mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
    this.mediaQueryList.onchange = (e) => {
      this.$vuetify.theme.dark = e.matches;
      this.changeDarkMode(this.$vuetify.theme.dark);
    };
  },
  beforeDestroy() {
    this.mediaQueryList.onchange = undefined;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>
