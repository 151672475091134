<template>
  <v-dialog v-model="show_" max-width="100%" persistent width="306px">
    <v-card class="d-flex flex-column flex-grow overflow-hidden" outlined>
      <v-toolbar>
        <span class="text-20">{{ $t("person_query") }}</span>
      </v-toolbar>
      <v-card-text class="d-flex flex-column flex-grow overflow-auto pa-4">
        <query-form ref="queryForm" @submitted="handleSubmitted" />
      </v-card-text>
      <v-toolbar>
        <v-spacer />
        <div class="d-flex" style="gap: 0.5rem">
          <v-btn small depressed color="secondary" @click="show_ = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small depressed color="success" @click="query">
            <v-icon class="mr-1" small>mdi-magnify-expand</v-icon>
            {{ $t("query") }}
          </v-btn>
        </div>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import QueryForm from "./QueryForm.vue";

export default {
  name: "QueryFormDialog",
  components: { QueryForm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show_: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
        if (!value) {
          this.$refs.queryForm.reset();
        }
      },
    },
  },
  watch: {
    show(newValue) {
      !newValue && this.$refs.queryForm.reset();
    },
  },
  methods: {
    handleSubmitted(data) {
      this.$emit("submitted", data);
      this.show_ = false;
    },
    query() {
      this.$refs.queryForm.submit();
    },
  },
};
</script>
