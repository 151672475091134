<template>
  <base-data-table
    ref="rolesTable"
    :headers="rolesTable.headers"
    :items="rolesTable.items"
    :fixed-header="true"
    class="d-flex flex-column roles-table"
    style="max-height: 100%"
    :loading="rolesTable.loading"
    @loadData="handleLoadData"
  >
    <template v-slot:toolbar-left>
      <v-toolbar-title
        class="d-flex flex-nowrap align-items-center"
        style="gap: 0.5rem"
      >
        <v-icon>mdi-account-tie</v-icon>
        <span>{{ $t("roles") }}</span>
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-right>
      <v-btn
        :color="isDarkMode ? 'grey lighten-5' : 'info'"
        depressed
        outlined
        small
        @click="showFormDialog(emptyRole)"
        :disabled="!$auth.check('rolleri-duzenleyebilir')"
      >
        <v-icon small class="mr-1">mdi-account-tie</v-icon>
        {{ $t("add_role") }}
      </v-btn>
      <role-form-dialog
        v-if="$auth.check('rolleri-duzenleyebilir')"
        :role.sync="formDialogRole"
        :show.sync="formDialog"
        @stored="handleSaved"
        @updated="handleSaved"
      />
      <role-delete-dialog
        v-if="$auth.check('rolleri-duzenleyebilir')"
        :role="deleteDialogRole"
        :show.sync="deleteDialog"
        @deleted="handleDeleted"
      />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn-toggle v-if="item.name !== 'admin'" active-class="no-active">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="showFormDialog(item)"
              :disabled="!$auth.check('rolleri-duzenleyebilir')"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="showDeleteDialog(item)"
              :disabled="!$auth.check('rolleri-duzenleyebilir')"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("remove_role") }}</span>
        </v-tooltip>
      </v-btn-toggle>
    </template>
  </base-data-table>
</template>

<script>
import Vue from "vue";
import { index } from "@/api/roles.api";
import { mapGetters } from "vuex";

import BaseDataTable from "@/components/base/DataTable/DataTable.vue";
import RoleDeleteDialog from "./RoleDeleteDialog.vue";
import RoleFormDialog from "./RoleFormDialog.vue";

const { i18n } = Vue;

export default {
  name: "RolesPage",
  components: { BaseDataTable, RoleDeleteDialog, RoleFormDialog },

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: i18n.t("roles"),
  },

  data: () => ({
    rolesTable: {
      headers: [
        {
          text: i18n.t("role_name"),
          value: "description",
          sortable: false,
          filterable: false,
        },
        {
          text: "#",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ],
      items: [],
      loading: false,
    },
    deleteDialog: false,
    formDialog: false,
    emptyRole: {
      name: "",
      description: "",
      permissions: [],
    },
    deleteDialogRole: {
      name: "",
      description: "",
      permissions: [],
    },
    formDialogRole: {
      name: "",
      description: "",
      permissions: [],
    },
  }),

  computed: {
    ...mapGetters(["isDarkMode"]),
  },

  methods: {
    handleDeleted() {
      this.$refs.rolesTable.refresh();
    },

    handleLoadData(filter, dataLoaded, dataLoadFailed) {
      this.rolesTable.loading = true;
      index(filter)
        .then(
          ({ data, meta }) => {
            if (data && meta) {
              this.rolesTable.items.splice(
                0,
                this.rolesTable.items.length,
                ...data
              );
              dataLoaded(meta);
            }
          },
          () => dataLoadFailed()
        )
        .finally(() => {
          this.rolesTable.loading = false;
        });
    },

    handleSaved() {
      this.$refs.rolesTable.refresh();
    },

    showDeleteDialog(role) {
      this.deleteDialog = true;
      this.$nextTick(() => {
        this.deleteDialogRole = Object.assign({}, { ...role });
      });
    },

    showFormDialog(role) {
      this.formDialog = true;
      this.$nextTick(() => {
        this.formDialogRole = Object.assign({}, { ...role });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.roles-table ::v-deep {
  td:last-child,
  th:last-child {
    width: 128px;
  }
}
</style>
