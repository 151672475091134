<template>
  <base-data-table
    ref="peopleTable"
    :headers="peopleTable.headers"
    :items="peopleTable.items"
    :fixed-header="true"
    class="d-flex flex-column people-page"
    style="max-height: 100%"
    :loading="peopleTable.loading"
    :options.sync="peopleTable.options"
    @loadData="handleLoadData"
  >
    <template v-slot:toolbar-left>
      <v-toolbar-title
        class="d-flex flex-nowrap align-items-center"
        style="gap: 0.5rem"
      >
        <v-icon>mdi-badge-account-alert-outline</v-icon>
        <span>{{ $t("watch_list") }}</span>
      </v-toolbar-title>
      <v-divider class="mx-4" vertical />
      <akn-select
        v-model="wanted"
        :items="wantedStatuses"
        :label="$t('wanted_status')"
        :disabled="peopleTable.loading"
        style="max-width: 264px"
        hide-details
      />
      <v-switch
        v-model="hideAutoGenerated"
        :label="$t('hide_auto_generated')"
        :disabled="peopleTable.loading"
        class="ml-4 text-no-wrap"
        hide-details
        dense
        inset
      />
    </template>
    <template v-slot:toolbar-right>
      <v-btn
        :color="isDarkMode ? 'grey lighten-5' : 'info'"
        depressed
        outlined
        small
        @click="goToPersonFormPage(null)"
        :disabled="!$auth.check('sahislari-duzenleyebilir')"
      >
        <v-icon small class="mr-1">mdi-account</v-icon>
        {{ $t("add_person") }}
      </v-btn>
      <person-delete-dialog
        v-if="$auth.check('sahislari-duzenleyebilir')"
        :person="deleteDialogPerson"
        :show.sync="deleteDialog"
        @deleted="handleDeleted"
      />
    </template>
    <template v-slot:[`header.photo`]="{ header }">
      <span>{{ header.text }}</span>
      <person-column-filter :options.sync="peopleTable.options" />
    </template>
    <template v-slot:[`item.photo`]="{ item }">
      <person-column :person="item" style="width: 512px" />
    </template>
    <template v-slot:[`item.is_wanted`]="{ item }">
      <div
        v-if="item.is_wanted"
        class="d-flex flex-column"
        style="gap: 0.5rem; max-width: 12rem; width: fit-content"
      >
        <v-alert
          dense
          icon="mdi-alert-circle-outline"
          type="warning"
          class="mb-0 px-2 py-1 text-12"
          style="max-width: 100%"
        >
          {{ $t("wanted") }}
        </v-alert>
        <v-alert
          v-if="item.cause_of_wanted"
          dense
          icon="mdi-alert-circle-outline"
          type="warning"
          class="mb-0 px-2 py-1 text-12"
          style="max-width: 100%"
        >
          <strong>{{ $t("cause_of_wanted") }}:</strong>
          {{ item.cause_of_wanted }}
        </v-alert>
      </div>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ $d(new Date(item.created_at * 1000), "full", "tr") }}
    </template>
    <template v-slot:[`item.updated_at`]="{ item }">
      {{ $d(new Date(item.updated_at * 1000), "full", "tr") }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn-toggle active-class="no-active">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="goToPersonDetailPage(item.id)"
            >
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("show_person_details") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="goToPersonFormPage(item.id)"
              :disabled="!$auth.check('sahislari-duzenleyebilir')"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              :disabled="!$auth.check('sahislari-duzenleyebilir')"
              @click="showDeleteDialog(item)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("remove_person") }}</span>
        </v-tooltip>
      </v-btn-toggle>
    </template>
  </base-data-table>
</template>

<script>
import Vue from "vue";
import { index } from "@/api/people.api";
import { mapGetters } from "vuex";

import AknSelect from "@/components/AknSelect.vue";
import BaseDataTable from "@/components/base/DataTable/DataTable.vue";
import PersonColumn from "./PersonColumn.vue";
import PersonColumnFilter from "./PersonColumnFilter.vue";
import PersonDeleteDialog from "./PersonDeleteDialog.vue";

const { i18n } = Vue;

export default {
  name: "PeoplePage",
  components: {
    AknSelect,
    BaseDataTable,
    PersonColumn,
    PersonColumnFilter,
    PersonDeleteDialog,
  },

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: i18n.t("people"),
  },

  data: () => ({
    peopleTable: {
      headers: [
        {
          text: i18n.t("person"),
          value: "photo",
          align: "start",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("wanted_status"),
          value: "is_wanted",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("created_at"),
          value: "created_at",
          sortable: true,
          filterable: false,
        },
        {
          text: i18n.t("updated_at"),
          value: "updated_at",
          sortable: true,
          filterable: false,
        },
        {
          text: "#",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ],
      items: [],
      loading: false,
      options: {},
    },
    deleteDialog: false,
    deleteDialogPerson: {
      name: "",
      middle_name: "",
      surname: "",
      gender: "",
      marital_status: "",
      birthday: "",
      date_of_death: "",
      is_wanted: false,
      cause_of_wanted: "",
      name_of_father: "",
      name_of_mother: "",
      national_number: "",
      passport_number: "",
      photo: "",
      created_at: 0,
      updated_at: 0,
    },
    hideAutoGenerated: true,
    wanted: null,
    wantedStatuses: [
      {
        text: i18n.t("all"),
        value: null,
      },
      {
        text: i18n.t("show_only_wanted"),
        value: "yes",
      },
      {
        text: i18n.t("show_only_not_wanted"),
        value: "no",
      },
    ],
  }),

  computed: {
    ...mapGetters(["isDarkMode"]),
  },

  watch: {
    hideAutoGenerated() {
      this.$refs.peopleTable.fireLoadData({
        ...this.$refs.peopleTable.custom.options,
        page: 1,
      });
    },
    wanted() {
      this.$refs.peopleTable.fireLoadData({
        ...this.$refs.peopleTable.custom.options,
        page: 1,
      });
    },
  },

  methods: {
    handleDeleted() {
      this.$refs.peopleTable.refresh();
    },

    handleLoadData(filter, dataLoaded, dataLoadFailed) {
      this.peopleTable.loading = true;
      this.setExtraFilters(filter);
      index(filter)
        .then(
          ({ data, meta }) => {
            if (data && meta) {
              this.peopleTable.items.splice(
                0,
                this.peopleTable.items.length,
                ...data
              );
              dataLoaded(meta);
            }
          },
          () => dataLoadFailed()
        )
        .finally(() => {
          this.peopleTable.loading = false;
        });
    },

    setExtraFilters(options) {
      if (this.hideAutoGenerated) {
        Object.assign(options, {
          ...options,
          filters: [
            ...(options.filters ?? []),
            {
              field: "created_by",
              value: "user",
              type: {
                value: "text-matching",
                options: {
                  caseSensitive: false,
                },
              },
            },
          ],
        });
      }
      if (this.wanted !== null) {
        Object.assign(options, {
          ...options,
          filters: [
            ...(options.filters ?? []),
            {
              field: "is_wanted",
              value: this.wanted,
              type: {
                value: "text-matching",
                options: {
                  caseSensitive: false,
                },
              },
            },
          ],
        });
      }
    },

    showDeleteDialog(person) {
      this.deleteDialog = true;
      this.$nextTick(() => {
        this.deleteDialogPerson = Object.assign({}, person);
      });
    },

    goToPersonDetailPage(personId) {
      this.$router.push({ name: "person-detail", params: { id: personId } });
    },

    goToPersonFormPage(personId) {
      if (personId) {
        this.$router.push({ name: "person-edit", params: { id: personId } });
      } else {
        this.$router.push({ name: "person-create" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.people-page ::v-deep {
  @media screen and (min-width: 600px) {
    td {
      width: 20%;
    }
  }
}
</style>
