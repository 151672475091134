<template>
  <v-form ref="form" v-model="form.valid" lazy-validation>
    <v-progress-linear
      :active="saving"
      :indeterminate="saving"
      absolute
      top
      color="primary"
    />
    <akn-text-field
      v-model="device_.name"
      :label="$t('device_name')"
      :rules="form.name.rules"
      :error-messages="form.name.errors"
    />
    <akn-textarea
      v-model="device_.description"
      :label="$t('description')"
      :rules="form.description.rules"
      :error-messages="form.description.errors"
    />
    <akn-text-field
      v-model="device_.device_sn"
      :label="$t('device_sn')"
      :rules="form.device_sn.rules"
      :error-messages="form.device_sn.errors"
    />
    <akn-select
      v-model="device_.type"
      :label="$t('device_type')"
      :rules="form.type.rules"
      :error-messages="form.type.errors"
      :items="[$t('bullet'), $t('ptz')]"
    />
    <akn-select
      v-model="device_.checkpoint_id"
      :label="$t('checkpoint')"
      :rules="form.checkpoint_id.rules"
      :error-messages="form.checkpoint_id.errors"
      :items="checkpoints"
      item-text="name"
      item-value="id"
    />
  </v-form>
</template>

<script>
import i18n from "@/lang";
import { index } from "@/api/checkpoints.api";
import { store, update } from "@/api/devices.api";

import AknSelect from "@/components/AknSelect.vue";
import AknTextarea from "@/components/AknTextarea.vue";
import AknTextField from "@/components/AknTextField.vue";

export default {
  name: "DeviceForm",
  components: { AknSelect, AknTextarea, AknTextField },
  props: {
    device: {
      type: Object,
      default: () => ({
        name: "",
        description: "",
        device_sn: "",
        type: "bullet",
        checkpoint_id: "",
      }),
    },
  },
  data() {
    return {
      checkpoints: [],
      emptyDevice: {
        name: "",
        description: "",
        device_sn: "",
        type: "bullet",
        checkpoint_id: "",
      },
      form: {
        name: {
          errors: [],
          rules: [
            (v) => !!v || i18n.t("x_is_required", { x: i18n.t("device_name") }),
            (v) =>
              v.length > 2 ||
              i18n.t("x_must_be_least_y_characters", {
                x: i18n.t("device_name"),
                y: 3,
              }),
            (v) =>
              v.length < 256 ||
              i18n.t("x_must_be_maximum_y_characters", {
                x: i18n.t("device_name"),
                y: 255,
              }),
          ],
        },
        description: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 5) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("description"),
                    y: 5,
                  });
                } else if (v.length > 500) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("description"),
                    y: 500,
                  });
                }
              }
              return true;
            },
          ],
        },
        device_sn: {
          errors: [],
          rules: [
            (v) => !!v || i18n.t("x_is_required", { x: i18n.t("device_sn") }),
            (v) =>
              v.length > 2 ||
              i18n.t("x_must_be_least_y_characters", {
                x: i18n.t("device_sn"),
                y: 3,
              }),
            (v) =>
              v.length < 256 ||
              i18n.t("x_must_be_maximum_y_characters", {
                x: i18n.t("device_sn"),
                y: 255,
              }),
          ],
        },
        type: {
          errors: [],
          rules: [
            (v) => !!v || i18n.t("x_is_required", { x: i18n.t("device_type") }),
          ],
        },
        checkpoint_id: {
          errors: [],
          rules: [() => true],
        },
        valid: false,
      },
      pending: {
        actions: [],
      },
      saving: false,
    };
  },
  computed: {
    device_: {
      get() {
        return this.device;
      },
      set(value) {
        this.$emit("update:device", value);
      },
    },
  },
  watch: {
    "device.name": function () {
      this.form.name.errors = [];
    },
    "device.description": function () {
      this.form.description.errors = [];
    },
    "device.device_sn": function () {
      this.form.device_sn.errors = [];
    },
    "device.type": function () {
      this.form.type.errors = [];
    },
    "device.checkpoint_id": function () {
      this.form.checkpoint_id.errors = [];
    },
  },
  methods: {
    callPendingActions() {
      for (
        let action = this.pending.actions.pop();
        action;
        action = this.pending.actions.pop()
      ) {
        action.callback(action.param);
      }
    },
    fetchCheckpoints() {
      index({ perPage: -1 }).then(
        ({ data }) => {
          this.checkpoints.splice(0, this.checkpoints.length, ...data);
        },
        () => {
          this.$toast.error(this.$t("error_while_retrieving_checkpoints_list"));
          this.pending.actions.push({
            callback: this.fetchCheckpoints,
            param: undefined,
          });
        }
      );
    },
    reset() {
      this.device_ = Object.assign({}, this.emptyDevice);
      this.$refs.form.resetValidation();
    },
    async submit() {
      await this.$refs.form.validate();
      if (this.form.valid) {
        this.saving = true;
        (this.device.id
          ? update(this.device.id, this.device)
          : store(this.device)
        )
          .then(
            ({ data }) => {
              if (this.device.id) {
                this.$emit("updated", data);
                this.$toast.success(this.$t("device_successfully_updated"));
              } else {
                this.$emit("stored", data);
                this.$toast.success(this.$t("device_successfully_saved"));
              }
              this.reset();
            },
            (error) => {
              const { data, status } = error.response ?? {};
              if (status === 401) {
                this.$toast.error(data?.detail);
              } else if (status === 422) {
                for (const [key, value] of Object.entries(data?.errors ?? [])) {
                  if (this.form[key]) {
                    this.form[key].errors = value;
                  }
                }
                if (data.message) {
                  this.$toast.error(data.message);
                }
              }
            }
          )
          .finally(() => {
            this.saving = false;
          });
      }
    },
  },
  beforeMount() {
    this.fetchCheckpoints();
  },
};
</script>
