<template>
  <div>
    <v-form ref="form" v-model="form.valid" class="person-form" lazy-validation>
      <v-progress-linear
        :active="saving_"
        :indeterminate="saving_"
        absolute
        top
        color="primary"
      />
      <v-row class="flex-grow-1 overflow-x-hidden overflow-y-auto">
        <v-col class="pa-0" cols="12" md="4" lg="3">
          <v-row>
            <v-col cols="12">
              <person-photo
                v-model="person_.photo"
                :accept="form.photo.accept"
                :error-messages="form.photo.errors"
                :rules="form.photo.rules"
                :src="src"
                @clearErrors="form.photo.errors = []"
              >
              </person-photo>
              <div class="mt-3 px-4 justify-center w-full d-flex">
                <person-info-alert
                  :show="false"
                  :cause-of-wanted="person.cause_of_wanted"
                  :is-wanted="person.is_wanted"
                ></person-info-alert>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pa-0" cols="12" md="8" lg="9">
          <v-row>
            <v-col class="pb-0 pt-0 pt-md-3" cols="12" md="6">
              <akn-text-field
                v-model="person_.name"
                :label="`${$t('name')}*`"
                :rules="form.name.rules"
                :error-messages="form.name.errors"
              />
            </v-col>
            <v-col class="pb-0 pt-0 pt-md-3" cols="12" md="6">
              <akn-text-field
                v-model="person_.middle_name"
                :label="$t('middle_name')"
                :rules="form.middle_name.rules"
                :error-messages="form.middle_name.errors"
              />
            </v-col>
            <v-col class="py-0" cols="12" md="6">
              <akn-text-field
                v-model="person_.surname"
                :label="`${$t('surname')}*`"
                :rules="form.surname.rules"
                :error-messages="form.surname.errors"
              />
            </v-col>
            <v-col class="py-0" cols="12" md="6">
              <akn-select
                v-model="person_.gender"
                :label="`${$t('gender')}*`"
                :rules="form.gender.rules"
                :error-messages="form.gender.errors"
                :items="form.gender.items"
              />
            </v-col>
            <v-col class="py-0" cols="12" md="6">
              <akn-text-field
                v-model="person_.name_of_father"
                :label="$t('name_of_father')"
                :rules="form.name_of_father.rules"
                :error-messages="form.name_of_father.errors"
              />
            </v-col>
            <v-col class="py-0" cols="12" md="6">
              <akn-text-field
                v-model="person_.name_of_mother"
                :label="$t('name_of_mother')"
                :rules="form.name_of_mother.rules"
                :error-messages="form.name_of_mother.errors"
              />
            </v-col>
            <v-col class="py-0" cols="12" md="6">
              <akn-text-field
                v-model="person_.national_number"
                :label="$t('national_number')"
                :rules="form.national_number.rules"
                :error-messages="form.national_number.errors"
              />
            </v-col>
            <v-col class="py-0" cols="12" md="6">
              <akn-text-field
                v-model="person_.passport_number"
                :label="$t('passport_number')"
                :rules="form.passport_number.rules"
                :error-messages="form.passport_number.errors"
              />
            </v-col>
            <v-col class="py-0" cols="12" md="6">
              <base-date-picker
                v-model="person_.birthday"
                :label="$t('birthday')"
                :rules="form.birthday.rules"
                :error-messages="form.birthday.errors"
              />
            </v-col>
            <v-col class="py-0" cols="12" md="6">
              <base-date-picker
                v-model="person_.date_of_death"
                :label="$t('date_of_death')"
                :rules="form.date_of_death.rules"
                :error-messages="form.date_of_death.errors"
              />
            </v-col>
            <v-col class="py-0" cols="12" md="6">
              <akn-select
                v-model="person_.marital_status"
                :label="`${$t('marital_status')}*`"
                :rules="form.marital_status.rules"
                :error-messages="form.marital_status.errors"
                :items="form.marital_status.items"
              />
            </v-col>
            <v-col class="py-0" cols="12" md="6">
              <akn-switch
                v-model="person_.is_wanted"
                :label="$t('wanted_status')"
              />
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              md="6"
              :style="{ display: person_.is_wanted ? 'block' : 'none' }"
            >
              <akn-text-field
                v-model="person_.cause_of_wanted"
                :label="$t('cause_of_wanted')"
                :rules="form.cause_of_wanted.rules"
                :error-messages="form.cause_of_wanted.errors"
              />
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              md="6"
              :style="{ display: person_.is_wanted ? 'block' : 'none' }"
            >
              <akn-select
                v-model="person_.categories"
                :label="`${$t('watch_categories')}`"
                :rules="form.categories.rules"
                :error-messages="form.categories.errors"
                :items="form.categories.items"
                item-text="name"
                item-value="id"
                multiple
                chips
                deletable-chips
                small-chips
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <person-edit-prompt-dialog
      :message="dialogMessage"
      :person-id="dialogPersonId"
      :show.sync="showDialog"
      @accepted="handleAccepted"
    />
  </div>
</template>

<script>
import i18n from "@/lang";
import { store, update } from "@/api/people.api";
import { index } from "@/api/categories.api";
import PersonInfoAlert from "@/views/pages/people/PersonInfoAlert";

export default {
  name: "PersonForm",
  components: {
    PersonInfoAlert,
    AknSelect: () => import("@/components/AknSelect.vue"),
    AknSwitch: () => import("@/components/AknSwitch.vue"),
    AknTextField: () => import("@/components/AknTextField.vue"),
    BaseDatePicker: () => import("@/components/base/DatePicker.vue"),
    PersonPhoto: () => import("./PersonPhoto.vue"),
    PersonEditPromptDialog: () => import("./PersonEditPromptDialog.vue"),
  },
  props: {
    person: {
      type: Object,
      default: () => ({
        name: "",
        middle_name: "",
        surname: "",
        gender: "",
        marital_status: "",
        birthday: "",
        date_of_death: "",
        is_wanted: false,
        cause_of_wanted: "",
        name_of_father: "",
        name_of_mother: "",
        national_number: "",
        passport_number: "",
        photo: null,
        categories: [],
      }),
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptyPerson: {
        name: "",
        middle_name: "",
        surname: "",
        gender: "",
        marital_status: "",
        birthday: "",
        date_of_death: "",
        is_wanted: false,
        cause_of_wanted: "",
        name_of_father: "",
        name_of_mother: "",
        national_number: "",
        passport_number: "",
        photo: null,
        categories: [],
      },
      form: {
        name: {
          errors: [],
          rules: [
            (v) => !!v || i18n.t("x_is_required", { x: i18n.t("name") }),
            (v) =>
              v.length > 1 ||
              i18n.t("x_must_be_least_y_characters", {
                x: i18n.t("name"),
                y: 2,
              }),
            (v) =>
              v.length < 256 ||
              i18n.t("x_must_be_maximum_y_characters", {
                x: i18n.t("name"),
                y: 255,
              }),
          ],
        },
        middle_name: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 2) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("middle_name"),
                    y: 2,
                  });
                } else if (v.length > 255) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("middle_name"),
                    y: 255,
                  });
                }
              }
              return true;
            },
          ],
        },
        surname: {
          errors: [],
          rules: [
            (v) => !!v || i18n.t("x_is_required", { x: i18n.t("surname") }),
            (v) =>
              v.length > 1 ||
              i18n.t("x_must_be_least_y_characters", {
                x: i18n.t("surname"),
                y: 2,
              }),
            (v) =>
              v.length < 256 ||
              i18n.t("x_must_be_maximum_y_characters", {
                x: i18n.t("surname"),
                y: 255,
              }),
          ],
        },
        gender: {
          errors: [],
          items: [
            { text: i18n.t("unknown"), value: "unknown" },
            { text: i18n.t("male"), value: "male" },
            { text: i18n.t("female"), value: "female" },
          ],
          rules: [
            (v) => !!v || i18n.t("x_is_required", { x: i18n.t("gender") }),
          ],
        },
        marital_status: {
          errors: [],
          items: [
            { text: i18n.t("unknown"), value: "unknown" },
            { text: i18n.t("single"), value: "single" },
            { text: i18n.t("married"), value: "married" },
          ],
          rules: [
            (v) =>
              !!v || i18n.t("x_is_required", { x: i18n.t("marital_status") }),
          ],
        },
        birthday: {
          errors: [],
          rules: [],
        },
        date_of_death: {
          errors: [],
          rules: [],
          menu: false,
        },
        is_wanted: {
          errors: [],
          rules: [
            // (v) => !!v || i18n.t("x_is_required", { x: i18n.t("wanted_status") }),
          ],
        },
        cause_of_wanted: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 2) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("cause_of_wanted"),
                    y: 2,
                  });
                } else if (v.length > 500) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("cause_of_wanted"),
                    y: 500,
                  });
                }
              }
              return true;
            },
          ],
        },
        name_of_father: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 2) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("name_of_father"),
                    y: 2,
                  });
                } else if (v.length > 255) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("name_of_father"),
                    y: 255,
                  });
                }
              }
              return true;
            },
          ],
        },
        name_of_mother: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 2) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("name_of_mother"),
                    y: 2,
                  });
                } else if (v.length > 255) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("name_of_mother"),
                    y: 255,
                  });
                }
              }
              return true;
            },
          ],
        },
        national_number: {
          errors: [],
          rules: [],
        },
        passport_number: {
          errors: [],
          rules: [],
        },
        photo: {
          accept: "image/jpg,image/jpeg,image/png",
          errors: [],
          rules: [
            (o) => {
              if (o?.photo || o?.data) {
                return true;
              }
              return i18n.t("x_is_required", { x: i18n.t("photo") });
            },
          ],
        },
        categories: {
          errors: [],
          items: [],
          rules: [],
        },
        valid: false,
      },
      pending: {
        actions: [],
      },
      showDialog: false,
      dialogMessage: "",
      dialogPersonId: "",
    };
  },
  computed: {
    person_: {
      get() {
        return this.person;
      },
      set(value) {
        this.$emit("update:person", value);
      },
    },
    saving_: {
      get() {
        return this.saving;
      },
      set(value) {
        this.$emit("update:saving", value);
      },
    },
    src() {
      return this.person_?.photo?.photo;
    },
  },
  watch: {
    "person.name": function () {
      this.form.name.errors = [];
    },
    "person.middle_name": function () {
      this.form.middle_name.errors = [];
    },
    "person.surname": function () {
      this.form.surname.errors = [];
    },
    "person.national_number": function () {
      this.form.national_number.errors = [];
    },
    "person.passport_number": function () {
      this.form.passport_number.errors = [];
    },
    "person.gender": function () {
      this.form.gender.errors = [];
    },
    "person.name_of_father": function () {
      this.form.name_of_father.errors = [];
    },
    "person.name_of_mother": function () {
      this.form.name_of_mother.errors = [];
    },
    "person.birthday": function () {
      this.form.birthday.errors = [];
    },
    "person.date_of_death": function () {
      this.form.date_of_death.errors = [];
    },
    "person.marital_status": function () {
      this.form.marital_status.errors = [];
    },
    "person.is_wanted": function () {
      this.form.is_wanted.errors = [];
    },
    "person.cause_of_wanted": function () {
      this.form.cause_of_wanted.errors = [];
    },
    "person.categories": function () {
      this.form.categories.errors = [];
    },
  },
  methods: {
    fetchCategories() {
      index({ perPage: -1 }).then(
        ({ data }) => {
          this.form.categories.items.splice(
            0,
            this.form.categories.items.length,
            ...data
          );
        },
        () => {
          this.$toast.error(this.$t("error_while_retrieving_category_list"));
        }
      );
    },
    handleAccepted(personId = "") {
      this.$router.push({ name: "person-edit", params: { id: personId } });
      this.showDialog = false;
    },
    reset() {
      this.person_ = Object.assign({}, this.emptyPerson);
      this.$refs.form.resetValidation();
    },
    async submit() {
      await this.$refs.form.validate();
      if (this.form.valid) {
        this.saving_ = true;
        const person = await Object.assign({}, this.person_);
        if (this.person_.photo?.data) {
          person.photo = this.person_.photo.data;
        } else {
          delete person.photo;
        }
        person.categories = JSON.stringify(
          (person.categories ?? []).map((category) => category.id ?? category)
        );
        (person.id ? update(person.id, person) : store(person))
          .then(
            ({ data }) => {
              if (person.id) {
                this.$emit("updated", data);
                this.$toast.success(this.$t("person_successfully_updated"));
              } else {
                this.$emit("stored", data);
                this.$toast.success(this.$t("person_successfully_saved"));
              }
              this.reset();
            },
            (error) => {
              const { data, status } = error.response ?? {};
              if (status === 401) {
                this.$toast.error(data?.detail);
              } else if (status === 422) {
                for (const [key, value] of Object.entries(data?.errors ?? [])) {
                  if (this.form[key]) {
                    this.form[key].errors = value;
                  }
                }
                if (data?.errors["person_id"]) {
                  this.dialogMessage = data.message;
                  this.dialogPersonId = data?.errors["person_id"];
                  this.showDialog = true;
                }
                if (data.message) {
                  this.$toast.error(data.message);
                }
              }
            }
          )
          .finally(() => {
            this.saving_ = false;
          });
      }
    },
  },
  beforeMount() {
    this.fetchCategories();
  },
};
</script>

<style lang="scss">
.person-form {
  .base-checkbox {
    .prepend-inner {
      label {
        min-width: fit-content;
      }
    }
  }
  .row {
    margin: 0 !important;
  }
  .cause_of_wanted {
    bottom: 0;
    left: 0;
    max-width: 100%;
    position: absolute;
    right: 0;
    .v-alert__content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
  .cause_of_wanted,
  .wanted {
    line-height: 15px;
    position: absolute;
  }
  .wanted {
    left: 0;
    max-width: 100%;
    top: 0;
    width: fit-content;
  }
}
</style>
