import { axios } from "@/plugins/axios";

export async function index(query) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/web/users", { params: query })
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function store(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/web/users", removeKeyWhenEmpty(payload))
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function update(id, payload) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/web/users/${id}`, removeKeyWhenEmpty(payload))
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function destroy(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/web/users/${id}`)
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function me(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/web/users/me", removeKeyWhenEmpty(payload))
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

function removeKeyWhenEmpty(payload) {
  return Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(payload).filter(([_, v]) => v != null)
  );
}
