<template>
  <v-navigation-drawer
    v-model="getTheme.rightNavigationDrawer"
    :color="isDarkMode ? 'dark' : 'white'"
    :dark="isDarkMode"
    app
    :disable-resize-watcher="false"
    height="100%"
    class="border-right right-navigation-drawer shadow-sm"
    floating
    right
    absolute
    temporary
    v-bind="$attrs"
    width="300"
  >
    <div class="d-flex flex-column h-full">
      <v-list dense>
        <v-list-item class="mb-0">
          <v-list-item-avatar class="mr-0">
            <v-icon>mdi-bell</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            <label class="font-weight-regular text-18 v-label">
              {{ $t("notifications") }}
            </label>
          </v-list-item-title>
          <v-list-item-avatar class="mr-0">
            <v-btn icon small @click="toggleRightNavigationDrawer">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-avatar>
        </v-list-item>
        <v-divider />
      </v-list>
      <div class="flex-grow-1 overflow-x-hidden overflow-y-auto">
        <v-list three-line>
          <v-list-item
            v-for="notification in notifications"
            :key="notification.id"
          >
            <v-list-item-avatar>
              <v-icon size="48">mdi-target-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="notification.title" />
              <v-list-item-subtitle v-html="notification.content" />
            </v-list-item-content>
            <v-list-item-action style="position: absolute; right: 1rem">
              <v-list-item-action-text class="text-10">
                {{ $d(new Date(notification.created_at * 1000), "full", "tr") }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            v-if="!loading && !notifications.length"
            style="min-height: min-content"
            dense
          >
            <v-alert class="mb-0 text-14 w-full" type="success" dense>
              {{ $t("no_unread_notifications") }}
            </v-alert>
          </v-list-item>
        </v-list>
        <v-overlay :value="loading" absolute>
          <v-progress-circular indeterminate size="64" />
        </v-overlay>
      </div>
      <template v-if="!loading && notifications.length">
        <v-divider />
        <v-list dense>
          <v-list-item class="mb-0">
            <v-btn color="primary" block @click="goToHistoriesPage">
              {{ $t("view_all") }}
            </v-btn>
          </v-list-item>
        </v-list>
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { unreadCount, unreadIndex } from "@/api/notifications.api";

export default {
  name: "RightNavigationDrawer",
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(["getTheme", "isDarkMode", "notifications"]),
  },
  watch: {
    "getTheme.rightNavigationDrawer": function (newValue) {
      if (newValue) {
        this.fetchUnreadNotifications();
      }
    },
  },
  methods: {
    ...mapActions(["changeRightNavigationDrawer"]),
    ...mapMutations(["setNotifications", "setUnreadNotificationCount"]),
    fetchUnreadNotifications() {
      if (!this.loading) {
        this.loading = true;
        unreadIndex()
          .then(
            ({ data }) => this.setNotifications(data),
            () => {
              this.$toast.error(
                this.$t("error_while_retrieving_notifications_list")
              );
            }
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
    fetchUnreadNotificationCount() {
      unreadCount().then(
        ({ count }) => {
          this.setUnreadNotificationCount(count);
        },
        () => {
          this.$toast.error(
            this.$t("error_while_retrieving_notifications_count")
          );
        }
      );
    },
    goToHistoriesPage() {
      this.$router.push({
        name: "histories",
        query: { show_only_wanted: true },
      });
    },
    toggleRightNavigationDrawer() {
      this.changeRightNavigationDrawer();
    },
  },
  beforeMount() {
    this.fetchUnreadNotificationCount();
  },
};
</script>

<style lang="scss" scoped>
.right-navigation-drawer ::v-deep {
  border-radius: 4px !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.03), 0 15px 12px rgba(0, 0, 0, 0.03) !important;
}
</style>
