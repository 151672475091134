<template>
  <v-data-table
    v-bind="$props"
    :options.sync="custom.options"
    :server-items-length="custom.serverItemsLength"
    :items-per-page="custom.itemsPerPage"
    class="base-data-table elevation-0"
  >
    <template
      v-if="$slots['toolbar-left'] || $slots['toolbar-right']"
      v-slot:top
    >
      <v-toolbar flat>
        <div class="d-flex align-center">
          <slot name="toolbar-left" />
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <v-text-field
            v-if="tableFilterable"
            v-model="tableFilter"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            :color="isDarkMode ? 'grey lighten-5' : 'primary'"
            hide-details
            outlined
            dense
            class="align-center d-none d-sm-flex"
          />
          <slot name="toolbar-right" />
          <v-menu
            v-if="tableFilterable"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                bottom
                class="d-sm-none"
                color="green"
                dot
                offset-x="22"
                offset-y="22"
                :value="tableFilter"
                bordered
                overlap
              >
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-filter-outline</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-list>
              <v-list-item>
                <v-text-field
                  v-model="tableFilter"
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                  :color="isDarkMode ? 'grey lighten-5' : 'primary'"
                  hide-details
                  outlined
                  dense
                  class="align-center"
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-toolbar>
    </template>
    <template
      v-for="column in filterableColumns"
      v-slot:[`header.${column.value}`]="{ header }"
    >
      <span :key="column.id">{{ header.text }}</span>
      <base-column-filter
        :key="column.id"
        :column-name="column.value"
        :options.sync="custom.options"
      />
    </template>
    <template v-slot:footer="{ props }">
      <base-data-table-footer
        v-bind="props"
        show-first-last-page
        @update:options="handleOptionsUpdate"
      />
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </v-data-table>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

import VDataTable from "vuetify/lib/components/VDataTable/VDataTable";

import BaseColumnFilter from "./ColumnFilter.vue";
import BaseDataTableFooter from "./DataTableFooter";

export default {
  mixins: [VDataTable],
  name: "BaseDataTable",
  components: { BaseColumnFilter, BaseDataTableFooter },
  props: {
    headerProps: {
      type: Object,
      default: () => ({ "sort-icon": "mdi-arrow-up-thin" }),
    },
    hideDefaultFooter: {
      type: Boolean,
      default: true,
    },
    itemsPerPage: {
      type: Number,
      default: 25,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    serverItemsLength: {
      type: Number,
      default: 0,
    },
    tableFilterable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      custom: {
        itemsPerPage: this.itemsPerPage,
        options: this.options,
        serverItemsLength: this.serverItemsLength,
      },
      tableFilter: "",
    };
  },
  computed: {
    ...mapGetters(["isDarkMode"]),
    filterableColumns() {
      return this.headers.filter((header) => header.filterable);
    },
  },
  watch: {
    "custom.options": {
      handler(newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          this.fireLoadData(newValue);
          this.$emit("update:options", newValue);
        }
      },
      deep: true,
    },
    options(newValue) {
      this.custom.options = newValue;
    },
  },
  methods: {
    dataLoaded(params) {
      this.custom.serverItemsLength = params.total;
    },
    dataLoadFailed() {
      //
    },
    fireLoadData(options) {
      const { filters = [], itemsPerPage, page, sortBy, sortDesc } = options;

      let sorts = [];
      for (let i = 0; i < sortBy.length; i++) {
        sorts.push({
          field: sortBy[i],
          direction: sortDesc[i] ? "desc" : "asc",
        });
      }

      const params = {
        page,
        perPage: itemsPerPage,
      };
      if (filters.length) {
        params.filters = filters;
      }
      if (sorts.length) {
        params.sorts = sorts;
      }

      const query = JSON.stringify(params);
      if (this.$route.query.query !== query) {
        this.$router.replace({
          name: this.$route.name,
          query: { query },
        });
      }

      this.$emit("loadData", params, this.dataLoaded, this.dataLoadFailed);
    },
    handleOptionsUpdate(options) {
      this.custom.options = options;
    },
    parseQueryFromRoute() {
      let {
        query: { query },
      } = this.$route;
      if (query) {
        try {
          query = JSON.parse(query);
          const { filters = [], page, perPage, sorts = [] } = query;

          this.custom.options.filters = this.custom.options.filters ?? [];
          filters.forEach((filter) => {
            let find = this.headers.find(
              (header) => header.value === filter.field
            );
            if (find) {
              find = this.custom.options.filters.find(
                (filter2) => filter2.field === filter.field
              );
              if (find) {
                Object.assign(find, filter);
              } else {
                this.custom.options.filters.push(filter);
              }
            }
          });

          this.custom.options.page = page ?? this.custom.options.page;
          this.custom.itemsPerPage = perPage ?? this.custom.itemsPerPage;

          this.custom.options.sortBy = this.custom.options.sortBy ?? [];
          this.custom.options.sortDesc = this.custom.options.sortDesc ?? [];
          sorts.forEach((sort) => {
            const find = this.headers.find(
              (header) => header.value === sort.field
            );
            if (find) {
              const index = this.custom.options.sortBy.findIndex(
                (field) => field === sort.field
              );
              if (index > -1) {
                this.custom.options.sortDesc[index] = sort.direction === "desc";
              } else {
                this.custom.options.sortBy.push(sort.field);
                this.custom.options.sortDesc.push(sort.direction === "desc");
              }
            }
          });
          this.custom.options.sortDesc.push(false);
        } catch (error) {
          //
        }
      }
    },
    refresh() {
      this.fireLoadData(this.custom.options);
    },
  },
  beforeMount() {
    this.parseQueryFromRoute();
  },
};
</script>

<style lang="scss" scoped>
.base-data-table ::v-deep {
  td {
    vertical-align: top;
  }

  th {
    white-space: nowrap;
  }

  td,
  th {
    padding: 0.5rem 1rem !important;
  }

  .v-data-footer {
    flex-flow: nowrap;
    gap: 0.5rem;
    justify-content: end;
    margin-right: 0 !important;
    min-height: 74px;
    overflow: hidden;
    padding: 0;

    .v-data-footer__wrapper {
      align-items: center;
      display: flex;
      gap: 0.5rem;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 1rem;
      width: min-content;

      * {
        margin-left: 0;
        margin-right: 0;
      }

      .v-data-footer__pagination {
        white-space: nowrap;
      }

      .v-data-footer__icons-before {
        white-space: nowrap;

        .v-btn {
          margin-right: 0;
        }
      }

      .v-data-footer__icons-after {
        white-space: nowrap;

        .v-btn {
          margin-left: 0;
        }
      }
    }
  }

  header {
    border-radius: 4px 4px 0 0 !important;
    flex: unset;

    &.theme--dark {
      border-bottom: thin solid rgba(255, 255, 255, 0.12);
    }
    &.theme--light {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
