<template>
  <v-alert
    v-if="show"
    class="text-12"
    dense
    border="top"
    colored-border
    :text="$vuetify.theme.dark"
    style="max-width: 100%; width: fit-content"
    :type="isWanted ? 'warning' : 'info'"
    :icon="false"
  >
    <p class="mb-0 text-title font-weight-bold">
      {{ isWanted ? $t("wanted") : $t("not_wanted") }}
    </p>
    <div v-if="causeOfWanted">
      <hr class="horizontal rounded-hr" style="margin-block: 2px" />
      <div>
        <span class="text-xs text-subtitle"
          ><span class="text-title"> {{ $t("cause_of_wanted") }} </span>:
          {{ causeOfWanted }}</span
        >
      </div>
    </div>
    <template v-if="this.categories?.length">
      <hr class="horizontal rounded-hr" style="margin-block: 2px" />
      <span class="text-xs text-subtitle">
        <span class="text-title">{{ $t("watch_categories") }}</span> :
        {{ categories_ }}
      </span>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: "PersonInfoAlert",
  props: {
    show: {
      default: true,
    },
    isWanted: {
      default: false,
    },
    causeOfWanted: {
      default: null,
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    categories_() {
      return this.categories.map((category) => category.name).join(", ");
    },
  },
};
</script>

<style scoped></style>
