<template>
  <v-textarea
    dense
    filled
    rounded
    :color="isDarkMode ? 'grey lighten-5' : 'primary'"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
  />
</template>

<script>
import { VTextarea } from "vuetify/lib/components";
import { mapGetters } from "vuex";

export default {
  mixins: [VTextarea],
  name: "AknTextarea",
  computed: {
    ...mapGetters(["isDarkMode"]),
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input__slot {
    padding: 0 16px !important;
  }
}
</style>
