export default {
  state: {
    notifications: [],
    unreadNotificationCount: 0,
  },
  getters: {
    notifications: (state) => state.notifications,
    unreadNotificationCount: (state) => state.unreadNotificationCount,
  },
  mutations: {
    pushNotification(state, data) {
      const notification = state.notifications.find(
        (notification) => notification.id === data.id
      );
      if (notification) {
        Object.assign(notification, data);
      } else {
        state.notifications.splice(0, 0, data);
        state.unreadNotificationCount++;
      }
    },
    setNotifications(state, data) {
      state.notifications.splice(0, state.notifications.length, ...data);
      state.unreadNotificationCount = state.notifications.filter(
        (notification) => !notification.read_at
      ).length;
    },
    setUnreadNotificationCount(state, data) {
      state.unreadNotificationCount = data;
    },
  },
  actions: {
    //
  },
};
