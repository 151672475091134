<template>
  <base-data-table
    ref="categoriesTable"
    :headers="categoriesTable.headers"
    :items="categoriesTable.items"
    :fixed-header="true"
    class="d-flex flex-column categories-table"
    style="max-height: 100%"
    :loading="categoriesTable.loading"
    @loadData="handleLoadData"
  >
    <template v-slot:toolbar-left>
      <v-toolbar-title
        class="d-flex flex-nowrap align-items-center"
        style="gap: 0.5rem"
      >
        <v-icon>mdi-shape-plus</v-icon>
        <span>{{ $t("watch_categories") }}</span>
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-right>
      <v-btn
        :color="isDarkMode ? 'grey lighten-5' : 'info'"
        depressed
        outlined
        small
        @click="showFormDialog({})"
        :disabled="!$auth.check('takip-listesi-kategorilerini-duzenleyebilir')"
      >
        <v-icon small class="mr-1">mdi-shape-plus</v-icon>
        {{ $t("add_category") }}
      </v-btn>
      <category-form-dialog
        v-if="$auth.check('takip-listesi-kategorilerini-duzenleyebilir')"
        :category.sync="formDialogCategory"
        :show.sync="formDialog"
        @stored="handleSaved"
        @updated="handleSaved"
      />
      <category-delete-dialog
        v-if="$auth.check('takip-listesi-kategorilerini-duzenleyebilir')"
        :category="deleteDialogCategory"
        :show.sync="deleteDialog"
        @deleted="handleDeleted"
      />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn-toggle v-if="item.name !== 'admin'" active-class="no-active">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="showFormDialog(item)"
              :disabled="
                !$auth.check('takip-listesi-kategorilerini-duzenleyebilir')
              "
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="showDeleteDialog(item)"
              :disabled="
                !$auth.check('takip-listesi-kategorilerini-duzenleyebilir')
              "
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("remove_category") }}</span>
        </v-tooltip>
      </v-btn-toggle>
    </template>
  </base-data-table>
</template>

<script>
import Vue from "vue";
import { index } from "@/api/categories.api";
import { mapGetters } from "vuex";

import BaseDataTable from "@/components/base/DataTable/DataTable.vue";
import CategoryDeleteDialog from "./CategoryDeleteDialog.vue";
import CategoryFormDialog from "./CategoryFormDialog.vue";

const { i18n } = Vue;

export default {
  name: "CategoriesPage",
  components: { BaseDataTable, CategoryDeleteDialog, CategoryFormDialog },

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: i18n.t("watch_categories"),
  },

  data: () => ({
    categoriesTable: {
      headers: [
        {
          text: i18n.t("category_name"),
          value: "name",
          sortable: false,
          filterable: false,
        },
        {
          text: "#",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ],
      items: [],
      loading: false,
    },
    deleteDialog: false,
    formDialog: false,
    deleteDialogCategory: {},
    formDialogCategory: {},
  }),

  computed: {
    ...mapGetters(["isDarkMode"]),
  },

  methods: {
    handleDeleted() {
      this.$refs.categoriesTable.refresh();
    },

    handleLoadData(filter, dataLoaded, dataLoadFailed) {
      this.categoriesTable.loading = true;
      index(filter)
        .then(
          ({ data, meta }) => {
            if (data && meta) {
              this.categoriesTable.items.splice(
                0,
                this.categoriesTable.items.length,
                ...data
              );
              dataLoaded(meta);
            }
          },
          () => {
            dataLoadFailed();
            this.$toast.error(this.$t("error_while_retrieving_category_list"));
          }
        )
        .finally(() => {
          this.categoriesTable.loading = false;
        });
    },

    handleSaved() {
      this.$refs.categoriesTable.refresh();
    },

    showDeleteDialog(category) {
      if (this.$auth.check("takip-listesi-kategorilerini-duzenleyebilir")) {
        this.deleteDialog = true;
        this.$nextTick(() => {
          this.deleteDialogCategory = Object.assign({}, { ...category });
        });
      }
    },

    showFormDialog(category) {
      if (this.$auth.check("takip-listesi-kategorilerini-duzenleyebilir")) {
        this.formDialog = true;
        this.$nextTick(() => {
          this.formDialogCategory = Object.assign({}, { ...category });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.categories-table ::v-deep {
  td:last-child,
  th:last-child {
    width: 128px;
  }
}
</style>
