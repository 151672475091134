import "vuetify/src/components/VDataIterator/VDataFooter.sass";

import VSelect from "vuetify/lib/components/VSelect/VSelect";
import VIcon from "vuetify/lib/components/VIcon";
import VBtn from "vuetify/lib/components/VBtn";

import Vue from "vue";
import { getSlot } from "vuetify/lib/util/helpers";

export default Vue.extend({
  name: "BaseDataTableFooter",
  props: {
    options: {
      type: Object,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [25, 50, 100, -1],
    },
    prevIcon: {
      type: String,
      default: "$prev",
    },
    nextIcon: {
      type: String,
      default: "$next",
    },
    firstIcon: {
      type: String,
      default: "$first",
    },
    lastIcon: {
      type: String,
      default: "$last",
    },
    itemsPerPageText: {
      type: String,
      default: "$vuetify.dataFooter.itemsPerPageText",
    },
    itemsPerPageAllText: {
      type: String,
      default: "$vuetify.dataFooter.itemsPerPageAll",
    },
    showFirstLastPage: Boolean,
    showCurrentPage: Boolean,
    disablePagination: Boolean,
    disableItemsPerPage: Boolean,
    pageText: {
      type: String,
      default: "$vuetify.dataFooter.pageText",
    },
  },
  computed: {
    disableNextPageIcon() {
      return (
        this.options.itemsPerPage <= 0 ||
        this.options.page * this.options.itemsPerPage >=
          this.pagination.itemsLength ||
        this.pagination.pageStop < 0
      );
    },

    computedDataItemsPerPageOptions() {
      return this.itemsPerPageOptions.map((option) => {
        if (typeof option === "object") {
          return option;
        } else {
          return this.genDataItemsPerPageOption(option);
        }
      });
    },
  },
  methods: {
    updateOptions(obj) {
      this.$emit("update:options", Object.assign({}, this.options, obj));
    },

    onFirstPage() {
      this.updateOptions({
        page: 1,
      });
    },

    onPreviousPage() {
      this.updateOptions({
        page: this.options.page - 1,
      });
    },

    onNextPage() {
      this.updateOptions({
        page: this.options.page + 1,
      });
    },

    onLastPage() {
      this.updateOptions({
        page: this.pagination.pageCount,
      });
    },

    onChangePage(page) {
      this.updateOptions({ page });
    },

    onChangeItemsPerPage(itemsPerPage) {
      this.updateOptions({
        itemsPerPage,
        page: 1,
      });
    },

    genDataItemsPerPageOption(option) {
      return {
        text:
          option === -1
            ? this.$vuetify.lang.t(this.itemsPerPageAllText)
            : String(option),
        value: option,
      };
    },

    genItemsPerPageSelect() {
      let value = this.options.itemsPerPage;
      const computedIPPO = this.computedDataItemsPerPageOptions;
      if (computedIPPO.length <= 1) return null;
      if (!computedIPPO.find((ippo) => ippo.value === value)) {
        value = computedIPPO[0];
      }
      return this.$createElement(
        "div",
        {
          staticClass: "v-data-footer__select",
        },
        [
          this.$createElement(VSelect, {
            attrs: {
              "aria-label": this.$vuetify.lang.t(this.itemsPerPageText),
            },
            props: {
              disabled: this.disableItemsPerPage,
              items: computedIPPO,
              label: this.$vuetify.lang.t("$vuetify.dataFooter.show"),
              value,
              hideDetails: true,
              auto: true,
              dense: true,
              outlined: true,
              minWidth: "75px",
              menuProps: { offsetY: true, top: true },
            },
            on: {
              input: this.onChangeItemsPerPage,
            },
            style: {
              "margin-bottom": 0,
              "margin-left": 0,
              "margin-top": 0,
              minWidth: "86px",
            },
          }),
        ]
      );
    },

    genPaginationInfo() {
      let children = ["–"];
      const itemsLength = this.pagination.itemsLength;
      let pageStart = this.pagination.pageStart;
      let pageStop = this.pagination.pageStop;

      if (this.pagination.itemsLength && this.pagination.itemsPerPage) {
        pageStart = this.pagination.pageStart + 1;
        pageStop =
          itemsLength < this.pagination.pageStop || this.pagination.pageStop < 0
            ? itemsLength
            : this.pagination.pageStop;
        children = this.$scopedSlots["page-text"]
          ? [
              this.$scopedSlots["page-text"]({
                pageStart,
                pageStop,
                itemsLength,
              }),
            ]
          : [
              this.$vuetify.lang.t(
                this.pageText,
                pageStart,
                pageStop,
                itemsLength
              ),
            ];
      } else if (this.$scopedSlots["page-text"]) {
        children = [
          this.$scopedSlots["page-text"]({
            pageStart,
            pageStop,
            itemsLength,
          }),
        ];
      }

      return this.$createElement(
        "div",
        {
          class: "v-data-footer__pagination",
        },
        children
      );
    },

    genIcon(click, disabled, label, icon) {
      return this.$createElement(
        VBtn,
        {
          props: {
            disabled: disabled || this.disablePagination,
            icon: true,
            text: true, // dark: this.dark, // TODO: add mixin
            // light: this.light // TODO: add mixin
          },
          on: {
            click,
          },
          attrs: {
            "aria-label": label, // TODO: Localization
          },
        },
        [this.$createElement(VIcon, icon)]
      );
    },

    genPageSelect() {
      const { page, pageCount } = this.pagination;
      let value = page;
      const items = Array(pageCount)
        .fill(0)
        .map((value, index) => index + 1);
      if (!items.find((item) => item === value)) {
        value = items[0];
      }

      return this.$createElement(
        "div",
        {
          staticClass: "v-data-footer__select",
        },
        [
          this.$createElement(VSelect, {
            staticClass: "v-data-footer__page-select",
            props: {
              items,
              label: this.$vuetify.lang.t("$vuetify.dataFooter.page"),
              value,
              hideDetails: true,
              auto: true,
              dense: true,
              outlined: true,
              menuProps: { offsetY: true, top: true },
            },
            on: {
              input: this.onChangePage,
            },
            style: {
              minWidth: "86px",
            },
          }),
          this.$createElement(
            "span",
            {
              style: {
                paddingLeft: "0.5rem",
                whiteSpace: "nowrap",
              },
            },
            [`/ ${pageCount}`]
          ),
        ]
      );
    },

    genPagination() {
      const before = [];
      const after = [];
      before.push(
        this.genIcon(
          this.onPreviousPage,
          this.options.page === 1,
          this.$vuetify.lang.t("$vuetify.dataFooter.prevPage"),
          this.$vuetify.rtl ? this.nextIcon : this.prevIcon
        )
      );
      after.push(
        this.genIcon(
          this.onNextPage,
          this.disableNextPageIcon,
          this.$vuetify.lang.t("$vuetify.dataFooter.nextPage"),
          this.$vuetify.rtl ? this.prevIcon : this.nextIcon
        )
      );

      if (this.showFirstLastPage) {
        before.unshift(
          this.genIcon(
            this.onFirstPage,
            this.options.page === 1,
            this.$vuetify.lang.t("$vuetify.dataFooter.firstPage"),
            this.$vuetify.rtl ? this.lastIcon : this.firstIcon
          )
        );
        after.push(
          this.genIcon(
            this.onLastPage,
            this.options.page >= this.pagination.pageCount ||
              this.options.itemsPerPage === -1,
            this.$vuetify.lang.t("$vuetify.dataFooter.lastPage"),
            this.$vuetify.rtl ? this.firstIcon : this.lastIcon
          )
        );
      }

      return [
        this.$createElement(
          "div",
          {
            staticClass: "v-data-footer__icons-before",
          },
          before
        ),
        this.showCurrentPage &&
          this.$createElement("span", [this.options.page.toString()]),
        this.genPageSelect(),
        this.$createElement(
          "div",
          {
            staticClass: "v-data-footer__icons-after",
          },
          after
        ),
      ];
    },
  },

  render() {
    return this.$createElement(
      "div",
      {
        staticClass: "v-data-footer",
      },
      [
        this.$createElement(
          "div",
          {
            staticClass: "v-data-footer__wrapper",
          },
          [
            getSlot(this, "prepend"),
            this.genItemsPerPageSelect(),
            this.genPaginationInfo(),
            this.genPagination(),
          ]
        ),
      ]
    );
  },
});
