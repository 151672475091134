<template>
  <v-select
    dense
    filled
    flat
    class="akn-select"
    :color="isDarkMode ? 'grey lighten-5' : 'primary'"
    :item-color="isDarkMode ? 'grey lighten-5' : 'primary'"
    :menu-props="{ offsetY: true }"
    rounded
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
  />
</template>

<script>
import { VSelect } from "vuetify/lib/components";
import { mapGetters } from "vuex";

export default {
  mixins: [VSelect],
  name: "AknSelect",
  computed: {
    ...mapGetters(["isDarkMode"]),
  },
};
</script>

<style lang="scss" scoped>
.akn-select::v-deep {
  .v-input__slot {
    padding: 0 8px 0 16px !important;
  }
  &.v-select--chips {
    .v-chip--select {
      margin: 0 !important;
    }
    .v-select__selections {
      gap: 4px;
      margin-top: 4px;
    }
  }
}
</style>
