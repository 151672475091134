<template>
  <v-form ref="form" v-model="form.valid" lazy-validation>
    <v-progress-linear
      :active="saving"
      :indeterminate="saving"
      absolute
      top
      color="primary"
    />
    <akn-text-field
      v-model="user_.name"
      :label="$t('userPage.name')"
      :rules="form.name.rules"
      :error-messages="form.name.errors"
    />
    <akn-text-field
      v-model="user_.username"
      :label="$t('userPage.username')"
      :rules="form.username.rules"
      :error-messages="form.username.errors"
    />
    <akn-text-field
      :type="form.password.show ? 'text' : 'password'"
      v-model="user_.password"
      :label="$t('password')"
      :rules="form.password.rules"
      :error-messages="form.password.errors"
      :append-icon="form.password.show ? 'mdi-eye' : 'mdi-eye-off'"
      autocomplete="new-password"
      @click:append="form.password.show = !form.password.show"
    />
    <akn-select
      v-model="user_.role"
      :label="$t('userPage.role')"
      :items="roles"
      :disabled="!$auth.check('rolleri-duzenleyebilir')"
      item-text="description"
      item-value="id"
    />
    <perfect-scrollbar>
      <v-data-table
        v-if="$auth.check('rolleri-duzenleyebilir')"
        v-model="selectedPermissions"
        :headers="permissionsTable.headers"
        :items="permissionsTable.items"
        :server-items-length="permissionsTable.items.length"
        fixed-header
        hide-default-footer
        show-select
      >
        <template v-slot:[`item.description`]="{ item }">
          <div class="d-flex flex-column">
            <span>{{ item.description }}</span>
            <small v-if="item.isFromRole" class="text-11 text--disabled">
              ({{ $t("permission_from_the_role") }})
            </small>
          </div>
        </template>
      </v-data-table>
    </perfect-scrollbar>
  </v-form>
</template>

<script>
import i18n from "@/lang";
import { index as permissionsIndex } from "@/api/permissions.api";
import { index as rolesIndex } from "@/api/roles.api";
import { store, update } from "@/api/users.api";
import _ from "lodash";

import AknSelect from "@/components/AknSelect.vue";
import AknTextField from "@/components/AknTextField.vue";

export default {
  name: "UserForm",
  components: { AknSelect, AknTextField },
  props: {
    user: {
      type: Object,
      default: () => ({
        name: "",
        username: "",
        password: "",
        role: "",
      }),
    },
  },
  data() {
    return {
      emptyUser: {
        name: "",
        username: "",
        password: "",
        role: "",
      },
      form: {
        name: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 3) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("userPage.name"),
                    y: 3,
                  });
                } else if (v.length > 255) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("userPage.name"),
                    y: 255,
                  });
                }
                return true;
              }
              return i18n.t("x_is_required", { x: i18n.t("userPage.name") });
            },
          ],
        },
        username: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 5) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("userPage.username"),
                    y: 5,
                  });
                } else if (v.length > 100) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("userPage.username"),
                    y: 100,
                  });
                }
                return true;
              }
              return i18n.t("x_is_required", {
                x: i18n.t("userPage.username"),
              });
            },
          ],
        },
        password: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 8) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("password"),
                    y: 8,
                  });
                } else if (v.length > 100) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("password"),
                    y: 100,
                  });
                }
              }
              return true;
            },
          ],
          show: false,
        },
        role: {
          errors: [],
          rules: [() => true],
        },
        valid: false,
      },
      pending: {
        actions: [],
      },
      permissions: [],
      permissionsTable: {
        headers: [
          {
            text: i18n.t("permission"),
            value: "description",
            sortable: false,
          },
        ],
        items: [],
      },
      roles: [],
      selectedPermissions: [],
      saving: false,
    };
  },
  computed: {
    user_: {
      get() {
        return this.user;
      },
      set(value) {
        this.$emit("update:user", value);
      },
    },
  },
  watch: {
    "user.name": function () {
      this.form.name.errors = [];
    },
    "user.username": function () {
      this.form.username.errors = [];
    },
    "user.password": function () {
      this.form.password.errors = [];
    },
    "user.role": function (newValue, oldValue) {
      if (
        !_.isEqual(newValue, oldValue) &&
        this.$auth.check("rolleri-duzenleyebilir")
      ) {
        this.setupPermissions();
      }
      this.form.role.errors = [];
    },
    "user.private_permissions": {
      handler(newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          this.setupPermissions();
        }
      },
      deep: true,
    },
  },
  methods: {
    callPendingActions() {
      for (
        let action = this.pending.actions.pop();
        action;
        action = this.pending.actions.pop()
      ) {
        action.callback(action.param);
      }
    },
    fetchPermissions() {
      permissionsIndex({ perPage: -1 }).then(
        ({ data }) => {
          this.permissions = data;
          this.setupPermissions();
        },
        () => {
          this.$toast.error(this.$t("error_while_retrieving_permission_list"));
        }
      );
    },
    fetchRoles() {
      rolesIndex({ perPage: -1 }).then(
        ({ data }) => {
          this.roles.splice(0, this.roles.length, ...data);
          this.setupPermissions();
        },
        () => {
          this.$toast.error(this.$t("error_while_retrieving_role_list"));
          this.pending.actions.push({
            callback: this.fetchRoles,
            param: undefined,
          });
        }
      );
    },
    reset() {
      this.user_ = Object.assign({}, this.emptyUser);
      this.$refs.form.resetValidation();
    },
    setupPermissions() {
      const role = this.roles.find(
        (role) => role.id === (this.user?.role?.id ?? this.user?.role)
      );
      if (role) {
        const hasRoleUpdatePermission = this.$auth.check(
          "rolleri-duzenleyebilir"
        );
        this.permissionsTable.items = this.permissions.map((permission) => {
          const isFromRole =
            role.permissions.findIndex(
              (rolePermission) => rolePermission.id === permission.id
            ) > -1;
          const isSelectable = hasRoleUpdatePermission && !isFromRole;
          return {
            ...permission,
            isFromRole,
            isSelectable,
          };
        });
        this.selectedPermissions = [
          ...role.permissions,
          ...(this.user?.private_permissions ?? []),
        ];
      } else {
        this.permissionsTable.items = this.permissions.map((permission) => {
          return { ...permission, isSelectable: true };
        });
        this.selectedPermissions = [];
      }
    },
    async submit() {
      await this.$refs.form.validate();
      if (this.form.valid) {
        this.saving = true;
        const permissions = this.permissionsTable.items.flatMap((item) => {
          if (
            item.isSelectable &&
            this.selectedPermissions.findIndex(
              (selectedPermission) => selectedPermission.id === item.id
            ) > -1
          ) {
            return item.id;
          }
          return [];
        });
        if (this.user.id) {
          this.user_.password_confirmation = this.user_.password;
        }
        (this.user.id
          ? update(this.user.id, { ...this.user_, permissions })
          : store({ ...this.user, permissions })
        )
          .then(
            ({ data }) => {
              if (this.user.id) {
                this.$emit("updated", data);
                this.$toast.success(
                  this.$t("userPage.user_successfully_updated")
                );
              } else {
                this.$emit("stored", data);
                this.$toast.success(
                  this.$t("userPage.user_successfully_saved")
                );
              }
              this.reset();
            },
            (error) => {
              const { data, status } = error.response ?? {};
              if (status === 401) {
                this.$toast.error(data?.detail);
              } else if (status === 422) {
                for (const [key, value] of Object.entries(data?.errors ?? [])) {
                  if (this.form[key]) {
                    this.form[key].errors = value;
                  }
                }
                if (data.message) {
                  this.$toast.error(data.message);
                }
              }
            }
          )
          .finally(() => {
            this.saving = false;
          });
      }
    },
  },
  beforeMount() {
    this.fetchPermissions();
    this.fetchRoles();
  },
};
</script>
<style scoped>
/deep/.ps {
  height: 200px;
}
</style>
