<template>
  <v-form ref="form" v-model="form.valid" lazy-validation>
    <v-progress-linear
      :active="saving"
      :indeterminate="saving"
      absolute
      top
      color="primary"
    />
    <akn-text-field
      v-model="checkpoint_.name"
      :label="$t('checkpoint_name')"
      :rules="form.name.rules"
      :error-messages="form.name.errors"
    />
    <akn-textarea
      v-model="checkpoint_.description"
      :label="$t('description')"
      :rules="form.description.rules"
      :error-messages="form.description.errors"
    />
  </v-form>
</template>

<script>
import i18n from "@/lang";
import { store, update } from "@/api/checkpoints.api";

import AknTextarea from "@/components/AknTextarea.vue";
import AknTextField from "@/components/AknTextField.vue";

export default {
  name: "CheckpointForm",
  components: { AknTextarea, AknTextField },
  props: {
    checkpoint: {
      type: Object,
      default: () => ({
        name: "",
        description: "",
      }),
    },
  },
  data() {
    return {
      emptyCheckpoint: {
        name: "",
        description: "",
      },
      form: {
        name: {
          errors: [],
          rules: [
            (v) =>
              !!v || i18n.t("x_is_required", { x: i18n.t("checkpoint_name") }),
            (v) =>
              v.length > 2 ||
              i18n.t("x_must_be_least_y_characters", {
                x: i18n.t("checkpoint_name"),
                y: 3,
              }),
            (v) =>
              v.length < 256 ||
              i18n.t("x_must_be_maximum_y_characters", {
                x: i18n.t("checkpoint_name"),
                y: 255,
              }),
          ],
        },
        description: {
          errors: [],
          rules: [
            (v) => {
              if (v) {
                if (v.length < 5) {
                  return i18n.t("x_must_be_least_y_characters", {
                    x: i18n.t("description"),
                    y: 5,
                  });
                } else if (v.length > 500) {
                  return i18n.t("x_must_be_maximum_y_characters", {
                    x: i18n.t("description"),
                    y: 500,
                  });
                }
              }
              return true;
            },
          ],
        },
        valid: false,
      },
      saving: false,
    };
  },
  computed: {
    checkpoint_: {
      get() {
        return this.checkpoint;
      },
      set(value) {
        this.$emit("update:checkpoint", value);
      },
    },
  },
  watch: {
    "checkpoint.name": function () {
      this.form.name.errors = [];
    },
    "checkpoint.description": function () {
      this.form.description.errors = [];
    },
  },
  methods: {
    reset() {
      this.checkpoint_ = Object.assign({}, this.emptyCheckpoint);
      this.$refs.form.resetValidation();
    },
    async submit() {
      await this.$refs.form.validate();
      if (this.form.valid) {
        this.saving = true;
        (this.checkpoint.id
          ? update(this.checkpoint.id, this.checkpoint)
          : store(this.checkpoint)
        )
          .then(
            ({ data }) => {
              if (this.checkpoint.id) {
                this.$emit("updated", data);
                this.$toast.success(this.$t("checkpoint_successfully_updated"));
              } else {
                this.$emit("stored", data);
                this.$toast.success(this.$t("checkpoint_successfully_saved"));
              }
              this.reset();
            },
            (error) => {
              const { data, status } = error.response ?? {};
              if (status === 401) {
                this.$toast.error(data?.detail);
              } else if (status === 422) {
                for (const [key, value] of Object.entries(data?.errors ?? [])) {
                  if (this.form[key]) {
                    this.form[key].errors = value;
                  }
                }
                if (data.message) {
                  this.$toast.error(data.message);
                }
              }
            }
          )
          .finally(() => {
            this.saving = false;
          });
      }
    },
  },
};
</script>
