import { axios } from "@/plugins/axios";

export async function index(query) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/web/people", { params: query })
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function store(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/web/people", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function update(id, payload) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/web/people/${id}?_method=PUT`, payload, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function show(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/web/people/${id}`)
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function destroy(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/web/people/${id}`)
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}
