<template>
  <div :class="layoutClass" :style="layoutStyle">
    <left-navigation-drawer />
    <app-bar />
    <page-content />
    <right-navigation-drawer />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import AppBar from "./AppBar.vue";
import LeftNavigationDrawer from "./LeftNavigationDrawer.vue";
import PageContent from "./PageContent.vue";
import RightNavigationDrawer from "./RightNavigationDrawer.vue";

export default {
  name: "AppLayout",
  components: {
    AppBar,
    LeftNavigationDrawer,
    PageContent,
    RightNavigationDrawer,
  },
  computed: {
    ...mapGetters(["getTheme", "isDarkMode"]),
    layoutClass() {
      return {
        "d-flex": true,
        "flex-grow-1": true,
        "sidebar-close": !this.getTheme.leftNavigationDrawer,
      };
    },
    layoutStyle() {
      return {
        background: this.$vuetify.theme.themes[this.theme].background,
        color: this.$vuetify.theme.themes[this.theme].color,
        "max-height": "100vh",
        "max-width": "100vw",
      };
    },
    theme() {
      return this.isDarkMode ? "dark" : "light";
    },
  },
  methods: {
    ...mapMutations(["pushNotification"]),
  },
  beforeMount() {
    this.$notificationConnection.connect(
      localStorage.getItem("auth_token_default")
    );
    this.$notificationConnection
      .private("notifications")
      .listen(".notification.created", (e) => {
        this.pushNotification(e.notification);
        this.$toast.warning(
          `${e.notification?.title} ${e.notification?.content}`
        );
      });
  },
  beforeDestroy() {
    this.$notificationConnection.disconnect();
  },
};
</script>
