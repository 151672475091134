import { axios } from "@/plugins/axios";

export async function unreadIndex(query) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/web/notifications/unread", { params: query })
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}

export async function unreadCount(query) {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/web/notifications/unread/count", { params: query })
      .then(
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
      .catch((error) => reject(error));
  });
}
