<template>
  <v-menu v-model="showFilterMenu" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dense icon small v-bind="attrs" v-on="on">
        <v-icon :color="filterIconColor" size="16">mdi-filter-outline</v-icon>
      </v-btn>
    </template>
    <v-card outlined style="height: 124px; width: 256px">
      <v-list class="column-filter-list">
        <v-list-item class="column-filter-list-item mt-1">
          <div class="d-flex flex-column">
            <div class="d-flex flex-nowrap" style="gap: 8px">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                :label="$t('search')"
                :color="isDarkMode ? 'grey lighten-5' : 'primary'"
                hide-details
                dense
                outlined
                class="column-filter-input align-center"
              />
              <v-select
                v-model="searchType"
                class="column-filter-input"
                dense
                hide-details
                :color="isDarkMode ? 'grey lighten-5' : 'primary'"
                :item-color="isDarkMode ? 'grey lighten-5' : 'primary'"
                :items="searchTypes"
                :label="$t('search_type')"
                outlined
                :menu-props="{ offsetY: true }"
              />
            </div>
            <v-switch
              v-model="caseSensitive"
              :label="$t('case_sensitive')"
              style="width: fit-content"
              inset
            />
          </div>
        </v-list-item>
        <v-list-item class="column-filter-list-item justify-end">
          <div class="d-flex" style="gap: 0.5rem">
            <v-btn
              color="success"
              class="column-filter-button"
              depressed
              :disabled="doFilterDisabled"
              @click="doFilter"
            >
              {{ $t("search") }}
            </v-btn>
            <v-btn
              color="info"
              class="column-filter-button"
              depressed
              :disabled="clearFilterDisabled"
              @click="clearFilter"
            >
              {{ $t("clear") }}
            </v-btn>
            <v-btn
              color="secondary"
              class="column-filter-button"
              depressed
              @click="showFilterMenu = !showFilterMenu"
            >
              {{ $t("close") }}
            </v-btn>
          </div>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

const { i18n } = Vue;

export default {
  name: "BaseColumnFilter",
  props: {
    columnName: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      caseSensitive: false,
      search: "",
      searchType: "text-including",
      searchTypes: [
        { text: i18n.t("matching"), value: "text-matching" },
        { text: i18n.t("including"), value: "text-including" },
        { text: i18n.t("starting_with"), value: "text-starting_with" },
        { text: i18n.t("ending_with"), value: "text-ending_with" },
      ],
      showFilterMenu: false,
    };
  },
  computed: {
    ...mapGetters(["isDarkMode"]),
    clearFilterDisabled() {
      return !this.filtered;
    },
    doFilterDisabled() {
      if (this.filtered) {
        return (
          (this.filtered.value === this.search || !this.search) &&
          this.filtered.type.value === this.searchType &&
          this.filtered.type.options.caseSensitive === this.caseSensitive
        );
      }
      return !this.search;
    },
    filtered() {
      const { filters = [] } = this.options;
      return filters.find((filter) => filter.field === this.columnName);
    },
    filterIconColor() {
      return this.filtered ? "green" : undefined;
    },
  },
  methods: {
    doFilter() {
      if (this.filtered) {
        Object.assign(this.filtered, {
          field: this.columnName,
          value: this.search,
          type: {
            value: this.searchType,
            options: {
              caseSensitive: this.caseSensitive,
            },
          },
        });
      } else {
        const { filters = [] } = this.options;
        this.$emit("update:options", {
          ...this.options,
          filters: [
            ...filters,
            {
              field: this.columnName,
              value: this.search,
              type: {
                value: this.searchType,
                options: {
                  caseSensitive: this.caseSensitive,
                },
              },
            },
          ],
        });
      }
    },
    clearFilter() {
      const { filters = [] } = this.options;

      const index = filters.findIndex(
        (filter) => filter.field === this.columnName
      );
      if (index > -1) {
        filters.splice(index, 1);
      }

      this.search = "";
      this.searchType = "text-including";
      this.caseSensitive = false;
    },
    parseFromOptions() {
      if (this.filtered) {
        this.search = this.filtered.value;
        this.searchType = this.filtered.type?.value ?? "text-matching";
        this.caseSensitive =
          this.filtered.type?.options?.caseSensitive ?? false;
      }
    },
  },
  beforeMount() {
    this.parseFromOptions();
  },
};
</script>

<style lang="scss" scoped>
.column-filter-list ::v-deep {
  margin-bottom: -50%;
  margin-right: -51%;
  padding: 12px 1px;
  scale: 0.66;
  transform-origin: 0 0;

  .column-filter-list-item {
    .column-filter-input {
      width: 100%;
    }
  }
}
</style>
