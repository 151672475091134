<template>
  <v-dialog
    v-model="show_"
    content-class="category-form-dialog"
    max-width="500px"
    persistent
  >
    <v-card class="dialog-card" outlined>
      <v-toolbar>
        <span class="text-20">{{ dialogTitle }}</span>
      </v-toolbar>
      <v-card-text class="card-text pa-4">
        <category-form
          ref="categoryForm"
          :category.sync="category_"
          @stored="handleStored"
          @updated="handleUpdated"
        />
      </v-card-text>
      <v-toolbar>
        <v-spacer />
        <div class="d-flex" style="gap: 0.5rem">
          <v-btn small depressed color="secondary" @click="show_ = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small depressed color="success" @click="save">
            <v-icon class="mr-1" small>mdi-floppy</v-icon>
            {{ $t("save") }}
          </v-btn>
        </div>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import CategoryForm from "./CategoryForm.vue";

export default {
  name: "CategoryFormDialog",
  components: { CategoryForm },
  props: {
    category: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    category_: {
      get() {
        return this.category;
      },
      set(value) {
        this.$emit("update:category", value);
      },
    },
    dialogTitle() {
      return this.category.id
        ? this.$t("edit_category")
        : this.$t("add_category");
    },
    show_: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show(newValue) {
      !newValue && this.$refs.categoryForm.reset();
    },
  },
  methods: {
    handleStored(data) {
      this.$emit("stored", data);
      this.show_ = false;
    },
    handleUpdated(data) {
      this.$emit("updated", data);
      this.show_ = false;
    },
    save() {
      this.$refs.categoryForm.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .category-form-dialog {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .dialog-card {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
      .card-text {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
        padding-top: 20px;
      }
    }
  }
}
</style>
