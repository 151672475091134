<template>
  <v-main app>
    <div class="d-flex flex-column flex-grow-1 h-full w-full akn-content">
      <transition name="fade" mode="out-in">
        <router-view v-if="$auth.ready()" />
      </transition>
    </div>
  </v-main>
</template>

<script>
export default {
  name: "PageContent",
};
</script>

<style lang="scss">
.v-main__wrap {
  display: flex;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.akn-content {
  padding: 32px !important;
  margin-top: 16px !important;
  overflow-y: auto;
}
</style>
