<template>
  <div class="container">
    <h6>{{ exception.title }}</h6>
    <span>{{ exception.detail }}</span>
  </div>
</template>

<script>
export default {
  name: "serverException",
  props: {
    exception: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
