import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

import AppLayout from "@/layout/Index.vue";
import CheckpointsPage from "@/views/pages/checkpoints/CheckpointsPage.vue";
import DevicesPage from "@/views/pages/devices/DevicesPage.vue";
import UsersPage from "@/views/pages/users/UsersPage.vue";
import PeoplePage from "@/views/pages/people/PeoplePage.vue";
import PersonFormPage from "@/views/pages/people/PersonFormPage.vue";
import PersonDetailPage from "@/views/pages/people/PersonDetailPage.vue";
import HistoriesPage from "@/views/pages/histories/HistoriesPage.vue";
import HistoriesLivePage from "@/views/pages/histories/HistoriesLivePage.vue";
import RolesPage from "@/views/pages/roles/RolesPage.vue";
import SettingsPage from "@/views/pages/settings/SettingsPage.vue";
import QueriesPage from "@/views/pages/queries/QueriesPage.vue";
import LoginPage from "@/views/pages/LoginPage.vue";
import PageForbidden from "@/views/pages/errors/PageForbidden.vue";
import NotFound from "@/views/pages/errors/NotFound.vue";
import CategoriesPage from "@/views/pages/categories/CategoriesPage.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "base",
      meta: {
        auth: true,
      },
      redirect: "/histories-live",
      component: AppLayout,
      children: [
        {
          path: "checkpoints",
          name: "checkpoints",
          component: CheckpointsPage,
          meta: {
            auth: ["kontrol-noktalarini-gorebilir"],
          },
        },
        {
          path: "devices",
          name: "devices",
          component: DevicesPage,
          meta: {
            auth: ["cihazlari-gorebilir"],
          },
        },
        {
          path: "users",
          name: "users",
          component: UsersPage,
          meta: {
            auth: ["kullanicilari-gorebilir"],
          },
        },
        {
          path: "people",
          name: "people",
          component: PeoplePage,
          meta: {
            auth: ["sahislari-gorebilir"],
          },
        },
        {
          path: "person/create",
          name: "person-create",
          component: PersonFormPage,
          meta: {
            auth: ["sahislari-duzenleyebilir"],
          },
        },
        {
          path: "person/:id/edit",
          name: "person-edit",
          component: PersonFormPage,
          meta: {
            auth: ["sahislari-duzenleyebilir"],
          },
        },
        {
          path: "person/:id/detail",
          name: "person-detail",
          component: PersonDetailPage,
          meta: {
            auth: ["sahislari-gorebilir"],
          },
        },
        {
          path: "histories",
          name: "histories",
          component: HistoriesPage,
          meta: {
            auth: ["gecis-kayitlarini-gorebilir"],
          },
        },
        {
          path: "histories-live",
          name: "histories-live",
          component: HistoriesLivePage,
          meta: {
            auth: ["canli-gecisleri-gorebilir"],
          },
        },
        {
          path: "roles",
          name: "roles",
          component: RolesPage,
          meta: {
            auth: ["rolleri-gorebilir"],
          },
        },
        {
          path: "settings",
          name: "settings",
          component: SettingsPage,
          meta: {
            auth: ["ayarlari-gorebilir"],
          },
        },
        {
          path: "queries",
          name: "queries",
          component: QueriesPage,
          meta: {
            auth: ["sahis-sorgularini-gorebilir"],
          },
        },
        {
          path: "categories",
          name: "categories",
          component: CategoriesPage,
          meta: {
            auth: ["takip-listesi-kategorilerini-gorebilir"],
          },
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: LoginPage,
    },
    {
      path: "/forbidden",
      name: "forbidden",
      component: PageForbidden,
    },
    {
      path: "*",
      name: "not-found",
      component: NotFound,
    },
  ],
});

Vue.router = router;

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch("changeThemeLoadingState", true);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  store.dispatch("changeThemeLoadingState", false);
});

export default router;
