<template>
  <base-data-table
    ref="dataTable"
    :headers="dataTable.headers"
    :items="dataTable.items"
    :fixed-header="true"
    class="d-flex flex-column"
    style="max-height: 100%"
    :loading="dataTable.loading"
    @loadData="handleLoadData"
  >
    <template v-slot:toolbar-left>
      <v-toolbar-title
        class="d-flex flex-nowrap align-items-center"
        style="gap: 0.5rem"
      >
        <v-icon>mdi-account-multiple</v-icon>
        <span>{{ $t("users") }}</span>
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-right>
      <v-btn
        :color="isDarkMode ? 'grey lighten-5' : 'info'"
        depressed
        outlined
        small
        @click="showFormDialog(emptyEntity)"
        :disabled="!$auth.check('kullanicilari-duzenleyebilir')"
      >
        <v-icon small class="mr-1">mdi-account-multiple</v-icon>
        {{ $t("create_user_button") }}
      </v-btn>
      <user-form-dialog
        v-if="$auth.check('kullanicilari-duzenleyebilir')"
        :user.sync="formDialogObject"
        :show.sync="formDialog"
        @stored="handleSaved"
        @updated="handleSaved"
      />
      <user-delete-dialog
        v-if="$auth.check('kullanicilari-duzenleyebilir')"
        :user="deleteDialogObject"
        :show.sync="deleteDialog"
        @deleted="handleDeleted"
      />
    </template>
    <template v-slot:[`item.roles`]="{ item }">
      <span v-for="role in item.roles" :key="role.id">
        {{ role.description }}
      </span>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ $d(new Date(item.created_at * 1000), "full", "tr") }}
    </template>
    <template v-slot:[`item.updated_at`]="{ item }">
      {{ $d(new Date(item.updated_at * 1000), "full", "tr") }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn-toggle active-class="no-active">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="showFormDialog(item)"
              :disabled="!$auth.check('kullanicilari-duzenleyebilir')"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-0"
              small
              style="min-width: 28px"
              v-bind="attrs"
              v-on="on"
              @click="showDeleteDialog(item)"
              :disabled="!$auth.check('kullanicilari-duzenleyebilir')"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("remove_user") }}</span>
        </v-tooltip>
      </v-btn-toggle>
    </template>
  </base-data-table>
</template>

<script>
import Vue from "vue";
import { index } from "@/api/users.api";
import { mapGetters } from "vuex";

import BaseDataTable from "@/components/base/DataTable/DataTable.vue";
import UserDeleteDialog from "./UserDeleteDialog.vue";
import UserFormDialog from "./UserFormDialog.vue";

const { i18n } = Vue;

export default {
  name: "UsersPage",
  components: { BaseDataTable, UserDeleteDialog, UserFormDialog },
  data: () => ({
    dataTable: {
      headers: [
        {
          text: i18n.t("userPage.name"),
          value: "name",
          align: "start",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("userPage.username"),
          value: "username",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("created_at"),
          value: "created_at",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("updated_at"),
          value: "updated_at",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("userPage.role"),
          value: "roles",
          sortable: false,
          filterable: false,
        },
        {
          text: "#",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ],
      items: [],
      loading: false,
    },
    deleteDialog: false,
    formDialog: false,
    emptyEntity: {
      name: "",
      username: "",
      password: "",
      role: "",
    },
    deleteDialogObject: {
      name: "",
      username: "",
      password: "",
      role: "",
    },
    formDialogObject: {
      name: "",
      username: "",
      password: "",
      role: "",
    },
  }),
  computed: {
    ...mapGetters(["isDarkMode"]),
  },
  methods: {
    handleDeleted() {
      this.$refs.dataTable.refresh();
    },
    handleLoadData(filter, dataLoaded, dataLoadFailed) {
      this.dataTable.loading = true;
      index(filter)
        .then(
          ({ data, meta }) => {
            if (data && meta) {
              this.dataTable.items.splice(
                0,
                this.dataTable.items.length,
                ...data
              );
              dataLoaded(meta);
            }
          },
          () => dataLoadFailed()
        )
        .finally(() => {
          this.dataTable.loading = false;
        });
    },
    handleSaved() {
      this.$refs.dataTable.refresh();
    },

    showDeleteDialog(entity) {
      this.deleteDialog = true;
      this.$nextTick(() => {
        this.deleteDialogObject = Object.assign({}, entity);
      });
    },

    showFormDialog(entity) {
      this.formDialog = true;
      this.$nextTick(() => {
        this.formDialogObject = Object.assign(
          {},
          {
            ...entity,
            role: entity.roles?.length ? entity.roles[0].id : undefined,
          }
        );
      });
    },
  },
};
</script>
