<template>
  <base-item-group no-action sub-group :item="item" />
</template>

<script>
import BaseItemGroup from "./ItemGroup.vue";

export default {
  name: "BaseItemSubGroup",
  components: { BaseItemGroup },
  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-group--sub-group {
    .v-list-group__header {
      padding-left: 20px !important;
    }
  }
}
</style>
