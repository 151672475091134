<template>
  <v-dialog
    v-model="show_"
    content-class="history-detail-dialog-content"
    persistent
  >
    <v-card class="d-flex flex-column" outlined width="52em">
      <div class="d-flex">
        <v-tabs v-model="tab" show-arrows>
          <v-tab class="text-16" style="text-transform: unset !important">
            {{ $t("person_info") }}
          </v-tab>
          <v-tab class="text-16" style="text-transform: unset !important">
            {{ $t("histories") }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-btn icon @click="show_ = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-tabs-items
        v-model="tab"
        class="d-flex flex-grow-1 overflow-hidden"
        style="background-color: unset; place-content: center"
      >
        <v-tab-item
          class="flex-grow-1 overflow-auto pa-4"
          style="max-width: 100%; width: 44rem"
        >
          <person-detail :person="history.person" />
        </v-tab-item>
        <v-tab-item class="flex-grow-1 overflow-hidden">
          <history-person-timeline
            class="pa-4"
            :person-id="history?.person?.id ?? ''"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import HistoryPersonTimeline from "./HistoryPersonTimeline.vue";
import PersonDetail from "@/views/pages/people/PersonDetail.vue";

export default {
  name: "HistoryDetailDialog",
  components: { HistoryPersonTimeline, PersonDetail },
  props: {
    history: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tab: 0,
  }),
  computed: {
    show_: {
      get() {
        return this.show;
      },
      set(value) {
        this.tab = 0;
        this.$emit("update:show", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .history-detail-dialog-content {
    display: flex;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    width: fit-content !important;

    .v-window__container {
      max-width: 100%;
    }
  }
}
</style>
