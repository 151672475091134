<template>
  <v-container class="flex-grow-1 pa-0">
    <v-row>
      <v-col sm="12" md="12" lg="10" xl="8">
        <v-card
          class="d-flex flex-column elevation-0 pa-4"
          style="gap: 1rem; max-height: 100%"
        >
          <v-card-title class="pb-0 pt-1">
            {{ cardTitle }}
          </v-card-title>
          <hr class="horizontal rounded-hr" />
          <person-form
            ref="personForm"
            class="d-flex flex-column flex-grow-1"
            :person.sync="person"
            :saving.sync="saving"
            @stored="handleStored"
            @updated="handleUpdated"
          />
          <div class="d-flex justify-end px-3" style="gap: 0.5rem">
            <v-btn
              small
              depressed
              color="secondary"
              :disabled="saving"
              @click="goToPeoplePage"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              small
              depressed
              color="success"
              :disabled="saving"
              :loading="saving"
              @click="save"
            >
              <v-icon class="mr-1" small>mdi-floppy</v-icon>
              {{ $t("save") }}
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { show } from "@/api/people.api";
import { validate as uuidValidate } from "uuid";

import PersonForm from "./PersonForm.vue";

export default {
  name: "PersonFormPage",
  components: { PersonForm },
  props: {
    //
  },
  data: () => ({
    person: {
      name: "",
      middle_name: "",
      surname: "",
      gender: "",
      marital_status: "",
      birthday: "",
      date_of_death: "",
      is_wanted: false,
      cause_of_wanted: "",
      name_of_father: "",
      name_of_mother: "",
      national_number: "",
      passport_number: "",
      photo: null,
      categories: [],
    },
    saving: false,
  }),
  computed: {
    cardTitle() {
      return this.$route.name === "person-edit"
        ? this.$t("edit_person")
        : this.$t("add_person");
    },
    personId() {
      return this.$route.params.id;
    },
  },
  watch: {
    personId() {
      this.$refs.personForm.reset();
      this.fetchPerson();
    },
  },
  methods: {
    async fetchPerson() {
      if (this.personId) {
        if (!uuidValidate(this.personId)) {
          this.$toast.error(this.$t("person_not_found"));
          return this.$router.replace({ name: "people" });
        }
        await show(this.personId).then(
          ({ data }) => {
            this.person = data;
            return data;
          },
          (error) => {
            if (error?.response?.status === 404) {
              this.$toast.error(this.$t("person_not_found"));
            } else {
              this.$toast.error(this.$t("error_while_retrieving_person_info"));
            }
            return this.$router.replace({ name: "people" });
          }
        );
      }
    },
    goToPeoplePage() {
      this.$router.replace({ name: "people" });
    },
    handleStored() {
      this.goToPeoplePage();
    },
    handleUpdated() {
      this.goToPeoplePage();
    },
    save() {
      this.$refs.personForm.submit();
    },
  },
  beforeMount() {
    this.fetchPerson();
  },
};
</script>
