<template>
  <v-menu v-model="showFilterMenu" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dense icon small v-bind="attrs" v-on="on">
        <v-icon :color="filterIconColor" size="16">mdi-filter-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="person-column-filter">
      <v-list class="filter-list">
        <v-list-item
          v-for="filter in current.filters"
          :key="filter.id"
          class="list-item mt-1"
        >
          <div class="d-flex flex-column">
            <div class="d-flex flex-nowrap align-center mb-6 w-full">
              <v-label>{{ $t(filter.field) }}</v-label>
              <v-divider class="ml-3" />
            </div>
            <div class="d-flex flex-nowrap" style="gap: 8px">
              <v-text-field
                v-model="filter.value"
                prepend-inner-icon="mdi-magnify"
                :label="$t(`search_by_${filter.field}`)"
                :color="isDarkMode ? 'grey lighten-5' : 'primary'"
                hide-details
                dense
                outlined
                class="item-input align-center"
              />
              <v-select
                v-model="filter.type.value"
                class="item-input"
                dense
                hide-details
                :color="isDarkMode ? 'grey lighten-5' : 'primary'"
                :item-color="isDarkMode ? 'grey lighten-5' : 'primary'"
                :items="filterTypes"
                :label="$t('search_type')"
                outlined
                :menu-props="{ offsetY: true }"
              />
            </div>
            <v-select
              v-model="filter.sort"
              class="mt-4"
              dense
              hide-details
              :color="isDarkMode ? 'grey lighten-5' : 'primary'"
              :item-color="isDarkMode ? 'grey lighten-5' : 'primary'"
              :items="sortTypes"
              :label="$t('sort_by')"
              outlined
              :menu-props="{ offsetY: true }"
              @input="(value) => handleSort(filter.field, value)"
            />
            <v-switch
              v-model="filter.type.options.caseSensitive"
              :label="$t('case_sensitive')"
              style="width: fit-content"
              inset
            />
          </div>
        </v-list-item>
        <v-list-item class="list-item justify-end">
          <div class="d-flex" style="gap: 0.5rem">
            <v-btn
              color="success"
              :disabled="filterButtonDisabled"
              @click="doFilter"
            >
              {{ $t("search") }}
            </v-btn>
            <v-btn
              color="info"
              :disabled="clearButtonDisabled"
              @click="clearFilter"
            >
              {{ $t("clear") }}
            </v-btn>
            <v-btn color="secondary" @click="showFilterMenu = !showFilterMenu">
              {{ $t("close") }}
            </v-btn>
          </div>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { mapGetters } from "vuex";

const { i18n } = Vue;

export default {
  name: "PersonColumnFilter",
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showFilterMenu: false,
      filterTypes: [
        { text: i18n.t("matching"), value: "text-matching" },
        { text: i18n.t("including"), value: "text-including" },
        { text: i18n.t("starting_with"), value: "text-starting_with" },
        { text: i18n.t("ending_with"), value: "text-ending_with" },
      ],
      sortTypes: [
        { text: i18n.t("no_sort"), value: null },
        { text: i18n.t("ascending_sort"), value: "asc" },
        { text: i18n.t("descending_sort"), value: "desc" },
      ],
      current: {},
      empty: {
        filters: [
          {
            field: "name",
            value: "",
            type: {
              value: "text-including",
              options: {
                caseSensitive: false,
              },
            },
            sort: null,
          },
          {
            field: "surname",
            value: "",
            type: {
              value: "text-including",
              options: {
                caseSensitive: false,
              },
            },
            sort: null,
          },
          {
            field: "national_number",
            value: "",
            type: {
              value: "text-including",
              options: {
                caseSensitive: false,
              },
            },
            sort: null,
          },
          {
            field: "passport_number",
            value: "",
            type: {
              value: "text-including",
              options: {
                caseSensitive: false,
              },
            },
            sort: null,
          },
        ],
      },
      previous: {},
    };
  },
  computed: {
    ...mapGetters(["isDarkMode"]),
    clearButtonDisabled() {
      return !this.filtered;
    },
    filtered() {
      return !_.isEqual(this.empty.filters, this.previous.filters);
    },
    filterButtonDisabled() {
      return (
        this.current.filters?.every((filter) => !filter.value) ||
        _.isEqual(this.current.filters, this.previous.filters)
      );
    },
    filterIconColor() {
      return this.filtered ? "green" : undefined;
    },
  },
  watch: {
    options() {
      this.parseFromOptions();
    },
  },
  methods: {
    doFilter() {
      const options = _.cloneDeep(this.options);
      (this.current.filters ?? []).forEach((filter) => {
        if (filter.value) {
          const find = (options.filters ?? []).find(
            (filter2) => filter2.field === filter.field
          );
          if (find) {
            Object.assign(find, filter);
          } else {
            Object.assign(options, {
              ...options,
              filters: [...(options.filters ?? []), filter],
            });
          }
        } else {
          options.filters = (options.filters ?? []).filter(
            (filter2) => filter2.field !== filter.field
          );
        }
      });
      this.previous = _.cloneDeep(this.current);
      this.$emit("update:options", _.cloneDeep(options));
    },
    clearFilter() {
      const options = _.cloneDeep(this.options);
      this.current.filters?.forEach((filter) => {
        options.filters = (options.filters ?? []).filter(
          (filter2) => filter2.field !== filter.field
        );
        const index = (options.sortBy ?? []).findIndex(
          (field) => field === filter.field
        );
        if (index > -1) {
          options.sortBy.splice(index, 1);
          options.sortDesc.splice(index, 1);
        }
      });
      this.$emit("update:options", _.cloneDeep(options));
      this.current = _.cloneDeep(this.empty);
      this.previous = _.cloneDeep(this.empty);
    },
    handleSort(field, direction) {
      const options = _.cloneDeep(this.options);
      if (!options.multiSort) {
        options.sortBy = (options.sortBy ?? []).flatMap((field2, index) => {
          if (field2 === field) {
            return [field2];
          }
          options.sortDesc.splice(index, 1);
          return [];
        });
        this.current.filters = this.current.filters.map((filter) => {
          return {
            ...filter,
            sort: filter.field === field ? direction : null,
          };
        });
      }
      let index = (options.sortBy ?? []).findIndex(
        (field2) => field2 === field
      );
      if (direction) {
        index = index > -1 ? index : (options.sortBy ?? []).push(field);
        (options.sortDesc ?? [])[index] = direction !== "asc";
      } else if (index > -1) {
        options.sortBy.splice(index, 1);
        options.sortDesc.splice(index, 1);
      }
      this.previous = _.cloneDeep(this.current);
      this.$emit("update:options", _.cloneDeep(options));
    },
    parseFromOptions() {
      (this.current.filters ?? []).forEach((filter) => {
        const find = (this.options?.filters ?? []).find(
          (filter2) => filter2.field === filter.field
        );
        if (find) {
          Object.assign(filter, find);
        }

        const index = (this.options?.sortBy ?? []).findIndex(
          (field) => field === filter.field
        );
        if (index > -1) {
          filter.sort = (this.options?.sortDesc ?? [])[index] ? "desc" : "asc";
        } else {
          filter.sort = null;
        }
      });
      this.previous = _.cloneDeep(this.current);
    },
  },
  beforeMount() {
    this.current = _.cloneDeep(this.empty);
    this.previous = _.cloneDeep(this.empty);
    this.parseFromOptions();
  },
};
</script>

<style lang="scss" scoped>
.person-column-filter ::v-deep {
  display: flex;
  flex-direction: column;
  height: 595px;
  width: 256px;

  .filter-list {
    margin-bottom: -50%;
    margin-right: -51%;
    padding: 12px 1px;
    scale: 0.66;
    transform-origin: 0 0;

    .list-item {
      .item-input:first-child {
        width: 66%;
      }
      .item-input:last-child {
        width: 33%;
      }
    }
  }
}
</style>
