<template>
  <v-dialog v-model="show_" max-width="34rem">
    <v-card outlined>
      <v-card-text class="d-flex flex-column pa-4">
        <v-alert
          class="mb-0 text-14"
          dense
          icon="mdi-help-circle-outline"
          type="error"
        >
          <span
            v-html="
              $t('are_you_sure_you_want_to_delete_this_user_x', {
                x: `${user.name} | ${user.username}`,
              })
            "
          />
        </v-alert>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn small depressed color="secondary" @click="show_ = false">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn small depressed color="error" @click="deleteConfirm">
          <v-icon class="mr-1" small>mdi-delete</v-icon>
          {{ $t("yes") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { destroy } from "@/api/users.api";
import ServerException from "@/components/toast/serverException";

export default {
  name: "UserDeleteDialog",
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show_: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    deleteConfirm() {
      destroy(this.user.id).then(
        () => {
          this.$toast.success(this.$t("userPage.user_successfully_deleted"));
          this.$emit("deleted", this.user);
          this.show_ = false;
        },
        (error) => {
          if (error.response?.data) {
            this.$toast.error({
              component: ServerException,
              props: {
                exception: error.response.data,
              },
            });
          } else {
            this.$toast.error(this.$t("userPage.error_while_deleting_user"));
          }
        }
      );
    },
  },
};
</script>
